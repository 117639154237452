import React from 'react';
import moment from 'moment';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { dismissAlert } from '../../actions/alert.js';

//import useStyles from './styles';

const MyEIBAlert = ({ myalert }) => {
	const dispatch = useDispatch();
	let mytime;
	
	const handleDismissAlert = ( myal ) => {
		console.log( "Dismiss alert");
		//console.log( JSON.stringify( myal ) );
		dispatch( dismissAlert( myal ));
	}
	
	if( myalert.time === undefined ) {
		console.log( "No time set" );
		mytime = moment().format("HH:mm:ss");
	} else {
		mytime = myalert.time;
	}

	return (
		<Alert 
			severity={ myalert.severity }
			action={
				<IconButton
					aria-label="close"
					color="inherit"
					size="small"
					onClick={() => {
						handleDismissAlert( myalert );
					}}
					>
					<CloseIcon fontSize="inherit" />
				</IconButton>
			}
			sx={{ mb: 2 }} >
			<AlertTitle>{ myalert.title }</AlertTitle>
			{ mytime }: { myalert.message }
		</Alert>
	)
}


export default MyEIBAlert;
