import React from "react";
import { useSelector } from 'react-redux';
import { Box, Typography, Chip, Grid, Card, CardMedia, CardContent, CircularProgress } from '@mui/material';
import Filter1Icon from '@mui/icons-material/Filter1';

import './element.css';
import useStyles from './styles';

const Number = ({ knxid }) => {
	//console.log("Number: " + JSON.stringify(element));
	const classes = useStyles();
	
	var element = useSelector((state) => state.elements.find(( elem ) => {
		//console.log ("useSelector: " + elem.knxid );
		return elem.knxid === knxid; }));
	if ( !element ){
		return <CircularProgress />
	}

	var myImage = "images/numbers.png";
	if ( element.image !== undefined && element.image !== "" ) {
		myImage = element.image;
	}

	const mapAndReplaceValue = ( mymap, blockmap ) => {
		var bReplaced = false;

		if ( blockmap !== true && element.mapping !== undefined && element.mapping.length > 0 ) {
			for ( let i = 0; i < element.mapping.length; i++ ) {
				let map = element.mapping[i];
	
				if( map.key === mymap ) { 
					mymap = map.value; 
					bReplaced = true;
				}
			}
		}
		
		if ( element.format !== undefined && element.format !== "" && bReplaced === false ) {
			mymap = element.format.replace( "%s", mymap );
		}

		return mymap;
	}


	var myvalues = [];
	var counter = 0;

	var myValue = mapAndReplaceValue( Math.round(element.value).toString() );
	myvalues.push( myValue );

	if( element.value2 !== undefined && element.value2 !== "" ) {
		myValue = mapAndReplaceValue( Math.round(element.value2).toString(), true );
		myvalues.push( myValue );
	}

	const createChip = ( val, element ) => {
		counter++;
		//console.log( `Create Chip: Counter: ${counter} val: ${val} `);
		return (
		<Grid key={ element.knxid + "_" + counter } item sx={{mt:-2}}>
			<Chip  	label={ val } 
					variant="outlined" 
					color="primary" 
					/>
		</Grid>
		)
	}

	const setIcon = () => {
		if( myvalues.length === 1 ) {
			return ( <div className={classes.overlay4}><Filter1Icon /></div> )
		}
		return "";
	}

	return (
		<Box>
			<Card className={classes.card} sx={{ display: { xs: 'none', sm: 'block' } }}>
				<CardMedia component="img" height="60" image={ myImage } />
				<div className={classes.overlay}>
					<Typography variant="h6">{ element.label }</Typography>
				</div>
				<div className={classes.overlay3}><Filter1Icon /></div>
				<CardContent sx={{ ml: -2, mr: -2, mt: 0, mb: -2}}>
					<Box >
						<Grid container padding={1} spacing={1} alignItems="center" justifyContent="center">
							{ myvalues.map(( val ) => (
								createChip( val, element )
							))}
						</Grid>
					</Box>
				</CardContent >
			</Card > 

			<Card className={classes.card} sx={{ display: { xs: 'block', sm: 'none' }}}>
				<Grid container>
					<Grid item xs={5}>
						<CardMedia component="img" height="60" image={ myImage } />
						<div className={classes.overlay}>
							<Typography variant="h6">{ element.label }</Typography>
						</div>
						{ setIcon() }
					</Grid>
					<Grid item xs={7}>	
						<CardContent sx={{ ml: -3, mr: -2, mt: 0.5, mb: -2}}>
							<Box >
								<Grid container padding={1} spacing={1} alignItems="center" justifyContent="center">
									{ myvalues.map(( val ) => (
										createChip( val, element )
									))}
								</Grid>
							</Box>
						</CardContent >
					</Grid>
				</Grid>
			</Card > 
		</Box>
	);
}


export default Number;