import { createTheme } from '@mui/material/styles';
//import { indigo, blue, green, purple } from '@mui/material/colors';

 const myEIBTheme = createTheme({
  palette: {
    
    primary: {
      main: '#212121',
      //main: indigo[500],
    },
    secondary: {
      main: '#bdbdbd',
    },
    neutral: {
      main: '#ffffff',
      contrastText: '#000',
    },
    
  },
});

export default myEIBTheme