import React, { useState, useEffect }  from 'react';
import { useDispatch } from 'react-redux';
import { createOrUpdateAlert} from '../../actions/alert';
import { changePIN, changePINUser } from '../../actions/alarm';
import Numpad from './Numpad';
import * as MESSAGE from '../../constants/userMessage';


const AlarmChangePIN = () => {
	const mysteps = [ "Alte PIN eingeben", "Neue PIN eingeben", "Neue PIN bestätigen"]
	// eslint-disable-next-line
	const [user, setUser] = useState( JSON.parse(localStorage.getItem('profile')));
	const [step, setStep] = useState( mysteps[0] );
	const [oldPIN, setOldPIN] = useState();
	const [newPIN, setNewPIN] = useState();
	
	const dispatch = useDispatch();
	//var alarm = useSelector((state) => state.alarm );
	
	const isLoggedInUser = () => {
		//console.log( "isLoggedInUser: " + user )
		if( user !== null && user.result.name !== undefined && user.result.name !== "" ) {
			return true;
		}
		return false;
	}

	// Skip the first step if a user logged in
	if( step === mysteps[0] && isLoggedInUser() === true ) {
		setOldPIN( "" );
		setStep(  mysteps[1] );
	}

	const handleEntry = ( myPIN ) => {
		console.log( "handleEntry in upper" );
		//console.log ( `Last step: ${ step } lastPIN: ${ myPIN } `);

		if( step === mysteps[0] ) {
			setOldPIN( myPIN );
			setStep(  mysteps[1] );
		} else if ( step === mysteps[1] ) {
			setNewPIN( myPIN );
			setStep(  mysteps[2] );
		} else if ( step === mysteps[2] ) {
			if( myPIN !== newPIN ) {
				console.log ( `Different PINS 1. Eingabe: ${ newPIN } 2. Eingabe: ${ myPIN }`);

				dispatch(createOrUpdateAlert( MESSAGE.ALARM_ERROR_CHANGEPIN_NEWDIFFERENT ));
				resetPIN();
			} else {
				// send the changePIN message
				console.log ( `Same PINS - ready to change`);
				if( isLoggedInUser() === true )	{
					dispatch( changePINUser( newPIN ) );
				} else {
					dispatch( changePIN( oldPIN, newPIN ) );					
				}
				resetPIN();
			}
		} else {
			//show generic error
			console.log ( `Something went wrong`);
			dispatch(createOrUpdateAlert( MESSAGE.ALARM_ERROR_CHANGEPIN_GENERAL ));
			resetPIN();
		}
		console.log ( `New step: ${ step }`);
	}


	const resetPIN = () => {
		setStep( mysteps[0] );
		setOldPIN( "" );
		setNewPIN( "" );
	}

	useEffect(() => {
		console.log ("useEffect - AlarmChangePIN");
		//console.log ( `Last step: ${ step } lastPIN: ${ alarm.lastPIN } `)
	}, [step]);
	

	return (
		<Numpad title={step} handleEntry={ handleEntry }/>
	)
}

export default AlarmChangePIN;