import * as React from 'react';
import { TextField, Grid } from '@mui/material';

export default function EventOneTime({ timerData, setTimerData }) {
	//console.log( "EventOneTime - mytimerData: " + JSON.stringify( timerData, null, 2 ));

	return (
		<Grid container rowSpacing={1} >
			<Grid item xs={12}>
				<TextField
					name="datetime-local" variant="outlined"
					label="Nächster Termin"
					type="datetime-local"
					value={timerData.frequency.value}
					sx={{width: '75%'}}
					//onChange={(e) => setTimerData({ ...timerData.frequency, value: e.target.value })}
					onChange={(e) => setTimerData({ ...timerData, frequency: { 
							...timerData.frequency, value: e.target.value }})}
					InputLabelProps={{ shrink: true, }}
				/>
			</Grid>
		</Grid>
	);
}
