import React, { useState }  from 'react';
import AlarmConfigTable from './AlarmConfigTable';

const AlarmConfig = ({ writeSnack }) => {
	const [currentAlarmElementId, setCurrentAlarmElementId] = useState(null);

	return (
		<AlarmConfigTable currentAlarmElementId={ currentAlarmElementId } 
						setCurrentAlarmElementId={ setCurrentAlarmElementId } 
						writeSnack={ writeSnack } />
	)
}

export default AlarmConfig;