import { BREADCRUMB_FETCH } from '../constants/actionTypes';

const breadcrumbs = function ( breadcrumbs = [], action ) {
	switch ( action.type ) {
		case BREADCRUMB_FETCH:
			return action.payload;
		default:
			return breadcrumbs;
	}
};

export default breadcrumbs;
