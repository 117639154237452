import * as React from 'react';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, CircularProgress } from '@mui/material';

import * as CONST from '../../../constants/general';

import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import RollerShadesClosedOutlinedIcon from '@mui/icons-material/RollerShadesClosedOutlined';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';

/*
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
*/

export default function ActionRoomList( { currentAnyAction, setCurrentAnyAction, mycontrols, 
											control, setControl, area, areaLabel, room, roomLabel, setValuesChangeSub } ) {

	//const [selectedIndex, setSelectedIndex] = React.useState();

	const handleListItemClick = (event, index, label) => {
		//console.log( "handleListItemClick: " + index );
		//console.log( `handleListItemClick: areaLabel: ${areaLabel} roomLabel: ${roomLabel} ` );

		setControl(index);
		setCurrentAnyAction({ ...currentAnyAction, 
			areaLabel: areaLabel, areaId: area,
			roomLabel: roomLabel, roomId: room,
			knxid: index, controlLabel: label });

		//setValuesChangeSub( room, false );		
	};

	//console.log( "ActionRoomList mycontrols: " + JSON.stringify( mycontrols ) );

	if( !mycontrols ) {
		return <CircularProgress />
	}

	const createListItem = ( value, index ) => {
		//console.log( `createListItem: value: ${value} index: ${index} control: ${control} `)

		return (
			<ListItemButton
				key={ value.knxid }
				selected={ control === index }
				onClick={(event) => handleListItemClick( event, index, value.label )}
				>
					<ListItemIcon>
						{
							value.type === "Light" && (
								<LightbulbOutlinedIcon />
						)}
						{
							value.type === "Dimmer" && (
								<LightbulbOutlinedIcon />
						)}
						{
							value.type === "Rollershutter" && (
								<RollerShadesClosedOutlinedIcon />
						)}
						{
							value.type === "Plug" && (
								<ElectricalServicesIcon />
						)}
						{
							value.type === "Scene" && (
								<MiscellaneousServicesOutlinedIcon />
						)}
					</ListItemIcon>
					<ListItemText primaryTypographyProps={{fontSize: '14px'}} primary={value.label} />
				</ListItemButton>
		)
	}

	return (
		<Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
			<List component="nav" >
				{ mycontrols.map(( myval, index ) => {
					//console.log( "currentAnyAction.object: " + currentAnyAction.object + " map items: " + myval.type );

					if( myval.type === currentAnyAction.object ||
							( currentAnyAction.object === CONST.LIGHT && myval.type === CONST.DIMMER ) ) {
						if( currentAnyAction.subType === CONST.ACTION_DIM ) {
							if( myval.type === CONST.DIMMER ) {
								return ( createListItem( myval, myval.knxid ))
							}
						} else {
							return ( createListItem( myval, myval.knxid ))
						}

					} else {
						//console.log( `skipping sub: value: ${ myval.knxid } index: ${ index }` );
						
					}
					return "";
				})}
			</List>
		</Box>
	);
}
