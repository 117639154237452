import { combineReducers } from 'redux';
import posts from './posts';

import alarm from './alarm';
import alarmelements from './alarmElements';
import alerts from './alerts';
import auth from './auth';
import breadcrumbs from './breadcrumbs';
import elements from './elements';
import menues from './menues';
//import telegrams from './telegrams';
import timerActionSelection from './timerActionSelection';
import timerEvents from './timerEvents';
import timerPushoverCfg from './timerPushoverCfg';
import timerActionSceneItems from './timerActionSceneItems';
import timerWorkflowNames from './timerWorkflowNames';
import heatings from './heatings';
import userConfigs from './userConfig';


export const reducers = combineReducers(
	{ 	posts, 
		alarm, 
		alarmelements, 
		alerts, 
		auth, 
		breadcrumbs, 
		elements, 
		menues,
		timerActionSceneItems,
		timerActionSelection, 
		timerEvents,
		timerPushoverCfg,
		timerWorkflowNames,
		heatings,
		userConfigs });
  