import { TIMER_FETCH_ALL, TIMER_CREATE, TIMER_UPDATE, TIMER_DELETE } from '../constants/actionTypes';

const timerEvents = function ( timerEvents = [], action ) {
	switch ( action.type ) {
		case TIMER_DELETE:
			return timerEvents.filter((timerEvent) => timerEvent._id !== action.payload);
		case TIMER_UPDATE:
			return timerEvents.map((timerEvent) => timerEvent._id === action.payload._id ? action.payload : timerEvent);
		case TIMER_FETCH_ALL:
			return action.payload;
		case TIMER_CREATE:
			return [ ...timerEvents, action.payload ];
		default:
			return timerEvents;
	}
};

export default timerEvents;
