import React from "react";
import { Box, Typography, Chip, Grid, Card, CardMedia, CardContent } from '@mui/material';

import './element.css';
import useStyles from './styles';
import { switchScene } from '../../../actions/knx';

const Scene = ({ element, writeSnack }) => {
	//console.log("Scene: " + JSON.stringify(element));
	const classes = useStyles();
	
	var myImage = "images/scene.png";
	if ( element.image !== undefined && element.image !== "" ) {
		myImage = element.image;
	}

	var imageWidth = 5;
	var workWidth = 7;
	let mywide = element.controlWidth; 
	//console.log("mywide: " + mywide);

	if( mywide !== undefined && !isNaN(mywide) ) {
		if( mywide > 11 ) { mywide = 11 };
		if( mywide < 2 ) { mywide = 2 };
		imageWidth = 12 - mywide;
		workWidth = mywide;
	}
	
	//console.log("imageWidth: " + imageWidth + " workWidth: " + workWidth );

	const switchSceneInner = async ( e, element, sceneVariant ) => {
		let myret = await switchScene( e, element, sceneVariant )
		//console.log("Value fro myRet: " + myret);
		if( myret !== undefined ) {
			writeSnack( myret );
		}
	}

	const createSceneChip = ( sceneVariant, element ) => {
		//console.log("SceneVariant: " + JSON.stringify(sceneVariant.value));
		//console.log("element: " + JSON.stringify(element.value));
		
		let myvar;
		//Do == only as it could be String or Number with same value 
		// eslint-disable-next-line
		if( sceneVariant.value == element.value ) {
			//console.log("hit");
			myvar = "";
		} else {
			myvar = "outlined";
		}


		return ( 
			<Grid item key={sceneVariant.id} sx={{mt:-1}} >
				<Chip label={ sceneVariant.label } color="primary"  variant={ myvar } size="small"
						onClick={(e) => switchSceneInner( e, element, sceneVariant )}/>
			</Grid> )
	}

	return (
		<Box>
			<Card className={classes.card} sx={{ display: { xs: 'none', sm: 'block' } }}>
				<CardMedia className="img" height="60" image={myImage} />
				<div className={classes.overlay}>
					<Typography variant="h6">{ element.label }</Typography>
				</div>
				<CardContent sx={{ ml: -2, mr: -2, mt: -0.5, mb: -2}}>
					<Box >
						<Grid container padding={1} rowSpacing={2} columnSpacing={0.5} alignItems="center" justifyContent="center">
							{ element.scenes.map(( sceneVariant ) => (
								createSceneChip( sceneVariant, element )
							))} 
						</Grid>
					</Box>
				</CardContent>
			</Card>

			<Card className={classes.card} sx={{ display: { xs: 'block', sm: 'none' } }}>
				<Grid container>
					<Grid item xs={imageWidth}>
						<CardMedia className="img" image={myImage} />
						<div className={classes.overlay}>
							<Typography variant="h6">{ element.label }</Typography>
						</div>
					</Grid>
					<Grid item xs={workWidth}>
						<Box >
							<Grid sx={{mt: 2}} container padding={1} spacing={0.5} alignItems="center" justifyContent="center">
								{ element.scenes.map(( sceneVariant ) => (
									createSceneChip( sceneVariant, element )
								))} 
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Card>
		</Box>
	);
}


export default Scene;


/*

return (
		<Paper elevation={3}>
			<img src={ myImage } alt=""	className="img"	/>
			<Box >
				<div className={classes.overlay}>
					<Typography className={classes.title} variant="h6" component="h2">{element.label} </Typography>
				</div >
			</Box>
			<Box >
				<Grid container padding={1} spacing={2} alignItems="center" justifyContent="center">
					{ element.scenes.map(( sceneVariant ) => (
						createSceneChip( sceneVariant )
					))} 
				</Grid>
			</Box>
		</Paper>
	);

*/