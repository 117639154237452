import * as React from 'react';
import { FormControlLabel, Checkbox, Grid, TextField, Box } from '@mui/material';

export default function EventInterval({ timerData, setTimerData }) {
	//console.log( "EventOneTime - timerData: " + JSON.stringify( timerData, null, 2 ));
	
	const handleCheckbox = ( event ) => {
		let myval = 0;
		if( event.target.checked ) { myval = 1; } 

		switch( event.target.name ) {
			case "repeats":
				setTimerData({ ...timerData, repeats: myval })
				break;
			
			default:
				break;
		}

		
	}

	const isActive = ({ timer }, key ) => {

		switch (key) {
			case "repeats":
				if( timerData.repeats === 1 )  { return true; } 
				else { return false; }
			default:
				break;
		}
		return false;
	}

	const handleFields = ( event ) => {
		console.log( "handle setTime: " + event.target.name );
		//console.log( "handle value: " + event.target.value );

		let val;
		let mynewval;
		let oldval;
		let oldpartval;

		switch (event.target.name) {
			case "Intervall":
				//console.log ("Event: " + event.target.value)
				
				let hms = event.target.value;
				let [hours, minutes, seconds] = hms.split(':');
				val = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
				
				//console.log(val);
				//val = event.target.value;
				if( val < 0 ) {
					val = 0;
				}
				setTimerData({ ...timerData, frequency: { 
					...timerData.frequency, value: val }});
				break;
			case "Intervall_H":
				//console.log ("Event Intervall_H: " + event.target.value)
				mynewval = event.target.value;
				
				oldpartval = getIntervalSplit(timerData.frequency.value, "h")
				oldval = timerData.frequency.value - oldpartval * 3600;

				//console.log ("Event mynewval: " + mynewval)
				//console.log ("Event timerval: " + timerData.frequency.value)
				//console.log ("Event oldval: " + oldval)
				//console.log ("Event oldpartval: " + oldpartval)

				val = (+oldval) + ((+mynewval) * 3600)
				setTimerData({ ...timerData, frequency: { 
					...timerData.frequency, value: val }});
				break;
			case "Intervall_M":
				//console.log ("Event Intervall_M: " + event.target.value)
				mynewval = event.target.value;
				
				oldpartval = getIntervalSplit(timerData.frequency.value, "m")
				oldval = timerData.frequency.value - oldpartval * 60;
				
				val = (+oldval) + ((+mynewval) * 60)
				setTimerData({ ...timerData, frequency: { 
					...timerData.frequency, value: val }});
				break;
			case "Intervall_S":
				//console.log ("Event Intervall_S: " + event.target.value)
				mynewval = event.target.value;
				
				oldpartval = getIntervalSplit(timerData.frequency.value, "s")
				oldval = timerData.frequency.value - oldpartval;
				
				val = (+oldval) + (+mynewval)

				//console.log ("Event mynewval: " + mynewval)
				//console.log ("Event timerval: " + timerData.frequency.value)
				//console.log ("Event oldval: " + oldval)
				//console.log ("Event oldpartval: " + oldpartval)
				//console.log ("Event val: " + val)

				setTimerData({ ...timerData, frequency: { 
					...timerData.frequency, value: val }});
				break;
			case "intervalFactor":
				val = event.target.value;
				if( val < 1 ) {
					val = 1;
				}
				setTimerData({ ...timerData, frequencyFactor: val });
				break;
			default:
				break;
		}
	}

	const getInterval = ( secs ) => {
		let out;
		out = new Date(secs * 1000).toISOString().substr(11, 8);
		
		//console.log("getInterval: " + out )
		return out;
	}

	const getIntervalSplit = ( val, type ) => {
		let out;
		switch (type) {
			case "h":
				out = Math.floor( val / 3600 );
				break;
			case "m":
				out = val % 3600
				out = Math.floor( out / 60 );
				break;

			case "s":
				out = val % 60
				break;
		
			default:
				break;
		}
		//console.log (`getIntervalSplit: ${val} type: ${type} out: ${out}` );
		return out;
	}

	return (
		<Grid container rowSpacing={1} >
			<Grid item xs={12}>
				<Box>
					<TextField
						name="Intervall_H" variant="outlined" label="hh" type="number" min="0" max="99"
						value={getIntervalSplit(timerData.frequency.value, "h")}
						sx={{width: '25%'}}
						onChange={(e) => handleFields( e )}
					/>
					<TextField
						name="Intervall_M" variant="outlined" label="mm" type="number" min="0" max="59"
						value={getIntervalSplit(timerData.frequency.value, "m")}
						sx={{width: '25%'}}
						onChange={(e) => handleFields( e )}
					/>
					<TextField
						name="Intervall_S" variant="outlined" label="ss" type="number" min="0" max="59"
						value={getIntervalSplit(timerData.frequency.value, "s")}
						sx={{width: '25%'}}
						onChange={(e) => handleFields( e )}
					/>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<TextField
					name="Intervall" variant="outlined" label="Intervall" type="time"  
					inputProps={{ "step": 1, }}
					value={getInterval( timerData.frequency.value )}
					sx={{width: '50%'}}
					onChange={(e) => handleFields( e )} 
					InputLabelProps={{ shrink: true, }}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					name="Intervall" variant="outlined" label="Intervall (s)" type="number"
					value={timerData.frequency.value}
					sx={{width: '50%'}}
					onChange={(e) => handleFields( e )}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					name="intervalFactor" variant="outlined" label="Faktor" type="number"
					value={timerData.frequencyFactor}
					sx={{width: '50%'}}
					onChange={(event) => handleFields( event )}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel label="Wiederholen" labelPlacement="end" 
					control={<Checkbox key="repeats" name="repeats" color="primary"
								checked={isActive({timerData }, "repeats")} inputProps={{'aria-labelledby': "repeats",}}
								onClick={(event) => handleCheckbox( event )} />} />
			</Grid>
		</Grid>
	);
}

/*

		*/