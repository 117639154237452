import { makeStyles } from '@mui/styles';


export default makeStyles(() => ({
	root: {
	  
	},
	paper: {
	  
	},
	form: {
	  display: 'flex',
	  flexWrap: 'wrap',
	  justifyContent: 'center',
	}
  }));