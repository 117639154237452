import * as React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { CircularProgress, Grid, TextField, Typography, FormControl, InputLabel, Select,
	OutlinedInput, MenuItem, Checkbox, ListItemText, Box } from '@mui/material';

import { getDescLine1, getDescLine2 } from '../../../models/anyActionClient';
import * as MAP from '../../../constants/texts';
import * as CONST from '../../../constants/general';

export default function ActionFinaliseTab( { currentAnyAction, setCurrentAnyAction, radioConf, setRadioConf } ) {


	const pushoverCfg = useSelector((state) => state.timerPushoverCfg );
	const sceneCfg = useSelector((state) => state.timerActionSceneItems );
	const workflows = useSelector((state) => state.timerWorkflowNames );

	if( !pushoverCfg ) {
		return <CircularProgress />
	} 
	
	const formatDateForDatefield = (inDate) => {
		if( inDate === undefined || inDate === "" ){
			return "";
		}
		
		let mom = new moment(inDate)
		return mom.format("YYYY-MM-DDTHH:mm");

	}
	
	const getSceneLabels = ( knxid ) => {
		console.log ("getSceneLabels knxid: " + knxid )
		let myret = [];
		let myscene = sceneCfg[knxid];

		if( myscene.knxid === knxid ){
			//console.log ("getSceneLabels found knxid: " + knxid )
			for( let j = 0; j < myscene.scenes.length; j++ ) {
				myret.push( myscene.scenes[j].label );
			}
		}
		//console.log ("getSceneLabels return: " + JSON.stringify(myret) );
		return myret;
	}

	const getSceneLabelForValue = ( knxid, value ) => {
		//console.log( `getSceneLabelForValue knxid: ${knxid} value: ${value}`)

		let myscene = sceneCfg[knxid];
		for( let j = 0; j < myscene.scenes.length; j++ ) {
			if( myscene.scenes[j].value === value ) {
				return myscene.scenes[j].label;
			}
		}
		return "";
	}

	const getSceneValueForLabel = ( knxid, label ) => {
		//console.log( `getSceneValueForLabel knxid: ${knxid} value: ${label}`)
		let myscene = sceneCfg[knxid];
		for( let j = 0; j < myscene.scenes.length; j++ ) {
			if( myscene.scenes[j].label === label ) {
				return myscene.scenes[j].value;
			}
		}
		return "";
	}

	const handleChangeRecipients = (e) => {
		//console.log("handleChangeRecipients");
		setCurrentAnyAction({ ...currentAnyAction, recipients: e.target.value });
	};

	const handleChangeDevices = (e) => {
		//console.log("handleChangeDevices");
		setCurrentAnyAction({ ...currentAnyAction, devices: e.target.value });
	};

	const handleChangeSounds = (e) => {
		//console.log("handleChangeSounds");
		setCurrentAnyAction({ ...currentAnyAction, sound: e.target.value });
	};

	const handleChangeSeverity = (e) => {
		//console.log("handleChangeSeverity");
		let myval = e.target.value;
		myval = MAP.valueMap(myval, MAP.txtSEVERITY );
		setCurrentAnyAction({ ...currentAnyAction, severity: myval });
	};

	const handleChangeScene = (e) => {
		//console.log("handleChangeScene");
		let mylabel = e.target.value;
		let myval = getSceneValueForLabel( currentAnyAction.knxid, mylabel );
		//console.log("handleChangeScene getSceneValueForLabel: " + myval );
		setCurrentAnyAction({ ...currentAnyAction, value: myval });
	};

	const handleChangeWorkflow = (e) => {
		//console.log("handleChangeWorkflow");
		setCurrentAnyAction({ ...currentAnyAction, title: e.target.value });
	};

	const handleValue = (e, min, max ) => {
		//console.log("handleChangeSounds");
		let myval = e.target.value;

		if( !myval || isNaN(myval) ){
			myval = min;
		}

		if( myval < min ) { myval = min; }
		if( myval > max ) { myval = max; }

		setCurrentAnyAction({ ...currentAnyAction, value: myval })
	}


	const createSingleChoice = ( label, curvalues, allvalues, handleChange ) => {
		return (
			<Box>
				<FormControl sx={{ width: '100%', maxWidth: '400px', fontSize: '14px'}}>
					<InputLabel id="label">{label}</InputLabel>
					<Select
					labelId={label}
					id={label}
					value={curvalues}
					label={label}
					onChange={handleChange}
					>
					{allvalues.map((val) => (
						<MenuItem key={val} value={val}>{val}</MenuItem>
					))}
					</Select>
				</FormControl>
			</Box> 
		);
		}
		

	const createMultiChoice = ( label, curvalues, allvalues, handleChange ) => {
		return (
			<Box>
				<FormControl sx={{ width: '100%', maxWidth: '400px', fontSize: '14px'}}>
					<InputLabel id="label">{label}</InputLabel>
					<Select
						labelId={label}
						id={label}
						multiple
						value={curvalues}
						onChange={handleChange}
						input={<OutlinedInput label={label} />}
						renderValue={(selected) => selected.join(', ')}
						
					>
					{allvalues.map((val) => (
						<MenuItem key={val} value={val}>
							<Checkbox checked={curvalues.indexOf(val) > -1} />
							<ListItemText primary={val} />
						</MenuItem>
					))}
					</Select>
				</FormControl>
			</Box>
		  );
	}

	const createInfoSummary = ( myAnyAction ) => {
		//console.log( "myAnyAction: " + JSON.stringify(myAnyAction));

		return (
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="body2" >{ getDescLine1(myAnyAction) }</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body2" >{ getDescLine2(myAnyAction) }</Typography>
				</Grid>
			</Grid>
		)
	}

	const createConfigFields = ( myAnyAction ) => {
		//console.log( "createConfigFields: " + JSON.stringify(myAnyAction));

		switch (myAnyAction.object ) {
			case CONST.BIND_PUSHOVER:
				let myrecipients = [];
				let mydevices = [];
				let mysounds = [];
				for( let i = 0; i < pushoverCfg.mapping.length; i++ ) {
					let mymap = pushoverCfg.mapping[i];
					myrecipients.push(mymap.name);
					for( let j = 0; j < mymap.devices.length; j++ ) {
						mydevices.push( mymap.devices[j].device );
					}
				}
				for( let i = 0; i < pushoverCfg.sound.length; i++ ) {
					mysounds.push( pushoverCfg.sound[i].name );
				}

				return (
					<Grid container rowSpacing={1}>
						<Grid item xs={12}>
							<TextField name="title" variant="outlined" label="Titel" 
								sx={{ width: '100%', maxWidth: '400px', fontSize: '14px'}} value={currentAnyAction.title} 
								onChange={(e) => setCurrentAnyAction({ ...currentAnyAction, title: e.target.value })}/>
						</Grid>
						<Grid item xs={12}>
							<TextField name="message" variant="outlined" label="Nachricht" 
								sx={{ width: '100%', maxWidth: '400px', fontSize: '14px'}} value={currentAnyAction.message} 
								onChange={(e) => setCurrentAnyAction({ ...currentAnyAction, message: e.target.value })}/>
						</Grid>
						<Grid item xs={12}>
							{
								createMultiChoice( "Empfänger", currentAnyAction.recipients, myrecipients, handleChangeRecipients )
							}
						</Grid>	
						<Grid item xs={12}>
							{
								createMultiChoice( "Geräte", currentAnyAction.devices, mydevices, handleChangeDevices )
							}
						</Grid>	
						<Grid item xs={12}>
							{
								createSingleChoice( "Sounds", currentAnyAction.sound, mysounds, handleChangeSounds )
							}
						</Grid>	
					</Grid>
				)
				
			case CONST.VARIABLE:
				return (
					<Grid container rowSpacing={1}>
						<Grid item xs={12}>
							<TextField name="variable" variant="outlined" label="Variable" 
								sx={{ width: '100%', maxWidth: '400px'}} value={currentAnyAction.variable} 
								onChange={(e) => setCurrentAnyAction({ ...currentAnyAction, variable: e.target.value })}/>
						</Grid>	
						<Grid item xs={12}>
							<TextField id="varExpires" label="Läuft ab" type="datetime-local" sx={{ width: '100%', maxWidth: '400px'}} InputLabelProps={{ shrink: true, }}
								value={formatDateForDatefield(currentAnyAction.varExpires)}
								onChange={(e) => setCurrentAnyAction({ ...currentAnyAction, varExpires: e.target.value })}/>
						</Grid>	
					</Grid>
				)
				
			case CONST.WORKFLOW:
				return (
					<Grid container rowSpacing={1}>
						<Grid item xs={12}>
							{
								createSingleChoice( "Titel", currentAnyAction.title, workflows, handleChangeWorkflow )
							}
						</Grid>

					</Grid>	
				)
			case CONST.ALERTAPP:
				return (
					<Grid container rowSpacing={1}>
						<Grid item xs={12}>
							<TextField name="message" variant="outlined" label="Nachricht" 
								sx={{ width: '100%', maxWidth: '400px'}} value={currentAnyAction.message} 
								onChange={(e) => setCurrentAnyAction({ ...currentAnyAction, message: e.target.value })}/>
						</Grid>	
						<Grid item xs={12}>
							{
								createSingleChoice( "Typ", MAP.keyMap(currentAnyAction.severity, MAP.txtSEVERITY), 
									[MAP.keyMap(CONST.ALERT_ERROR, MAP.txtSEVERITY) , MAP.keyMap(CONST.ALERT_WARNING, MAP.txtSEVERITY), 
									 MAP.keyMap(CONST.ALERT_INFO, MAP.txtSEVERITY), MAP.keyMap(CONST.ALERT_SUCCESS, MAP.txtSEVERITY)], 
									 handleChangeSeverity, false )
							}
						</Grid>	
					</Grid>
				)
			case CONST.LIGHT:
				if( currentAnyAction.subType === CONST.ACTION_DIM ) {	
					return (
						<Grid container rowSpacing={1}>
							<Grid item xs={12}>
								<TextField
									name="value" variant="outlined" label="Wert in %" type="number" min="0" max="100"
									value={currentAnyAction.value} sx={{width: '25%'}}
									onChange={(e) => handleValue( e, 0, 100 )}/>
							</Grid>
						</Grid>
					)
				}
				break;
			case CONST.ROLLERSHUTTER:
				if( currentAnyAction.subType === CONST.ACTION_DRIVE_ROLLER_TO_POS ) {
					return (
						<Grid container rowSpacing={1}>
							<Grid item xs={12}>
								<TextField
									name="value" variant="outlined" label="Wert in %" type="number" min="0" max="100"
									value={currentAnyAction.value} sx={{width: '25%'}}
									onChange={(e) => handleValue( e, 0, 100 )}/>
							</Grid>
						</Grid>
					)
				}
				break;
			case CONST.SCENE:
				return (
					<Grid container rowSpacing={1}>
						<Grid item xs={12}>
							{
								createSingleChoice( "Auswahl", getSceneLabelForValue( currentAnyAction.knxid, currentAnyAction.value ),
										 getSceneLabels( currentAnyAction.knxid ), handleChangeScene, false )
							}
						</Grid>
					</Grid>
				)
			default:
				break;
		}
	}


	return (
		<Grid minHeight={300} width={'100%'} container sx={{mt: 3}}>
			<Grid item xs={12} >
				<Typography variant="body2" >Zusammenfassung:</Typography>
				{ createInfoSummary( currentAnyAction ) }
			</Grid>
			<Grid item xs={12} >
				{ createConfigFields( currentAnyAction ) }
			</Grid>
		</Grid>

	);
}

