import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Box, Chip, Grid, CircularProgress } from '@mui/material';

import { getElementsForPage, getBreadcrumbsForPage } from '../../../actions/knx';

import './element.css';
//import useStyles from './styles';

const Breadcrum = () => {
	const dispatch = useDispatch();
	const breadcrumbs = useSelector((state) => state.breadcrumbs );

	//console.log("Breadcrum: " + JSON.stringify(breadcrumbs));
	//const classes = useStyles();

	const handleClick = ({ page }) => {
		dispatch( getBreadcrumbsForPage( page ) );
		dispatch( getElementsForPage( page ) );
	}
	
	const createBreadcrumb = ({ breadcrumb }) => {
		var page = breadcrumb.link;
		return (
			<Grid item key={ breadcrumb.link }>
				<Chip label={ breadcrumb.label } color="primary" 
						onClick={(e) => handleClick({ page })}/>
			</Grid> )
	}

	if ( !breadcrumbs || !breadcrumbs.length ){
		return <CircularProgress />
	}
	return (
		<Box >
			<Grid container padding={0} spacing={2} alignItems="left" justifyContent="left">
			{ breadcrumbs.map((breadcrumb) => (
					createBreadcrumb({ breadcrumb })
				))}
			</Grid>
		</Box>
	);
}

export default Breadcrum;