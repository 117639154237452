import React, { useState, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Box, Fab } from '@mui/material';
import { activateAlarmPIN, activateAlarmUser, deactivateAlarmPIN, deactivateAlarmUser, cancelAlarmPIN, cancelAlarmUser } from '../../actions/alarm';
import Numpad from './Numpad';
import AlarmActivateTable from './AlarmActivateTable';

import CampaignIcon from '@mui/icons-material/Campaign';
import { orange, green } from '@mui/material/colors';


const AlarmActivate = ({ writeSnack }) => {
	// eslint-disable-next-line
	const [user, setUser] = useState( JSON.parse(localStorage.getItem('profile')));
	const [PIN, setPIN] = useState();

	const dispatch = useDispatch();

	const alarm = useSelector((state) => state.alarm );
	const alarmElements = useSelector((state) => state.alarmelements );
	
	const isLoggedInUser = () => {
		//console.log( "isLoggedInUser: " + user )
		if( user !== null && user.result.name !== undefined && user.result.name !== "" ) {
			return true;
		}
		return false;
	}

	const handleEntry = ( myPIN ) => {
		console.log ( `AlarmActivate - PIN provided - handleEntry`);
		setPIN( myPIN )
		//dispatch( changePIN( oldPIN, newPIN ) );
	}

	const clickActivateUser = () => {
		dispatch(activateAlarmUser( writeSnack ));
	}

	const clickActivatePIN = () => {
		dispatch(activateAlarmPIN( PIN, writeSnack ));
		setTimeout(() => {
			setPIN('');
		}, alarm.activationStartupTime * 1000 )
	}

	const clickDeactivateUser = () => {
		dispatch(deactivateAlarmUser( writeSnack ));
	}
	
	const clickDeactivatePIN = () => {
		dispatch(deactivateAlarmPIN( PIN, writeSnack ));
		setPIN('');
	}

	const clickCancelUser = () => {
		dispatch(cancelAlarmUser( writeSnack ));
	}
	
	const clickCancelPIN = () => {
		dispatch(cancelAlarmPIN( PIN, writeSnack ));
	}


	const isBlocker = ( element ) => {
		switch (element.type) {
			case "Contact":
				if( element.status === 1 && element.active === true ) {
					//console.log( "Alarm obj: " + JSON.stringify( alarm ) );
					let myarr = alarm.alarmNoBlock;
					//console.log( "No Block Test: knxid: " + element.knxid + " myarr: " + JSON.stringify(myarr ));

					if( myarr !== undefined && myarr.includes( element.knxid ) ) {
						//console.log( "No Block found: " + element.knxid );
						return false;
					} 
					return true; 
				}
				return false;
			default:
				return true;
		}
	}

	const getAlarmButton = ( clickActivate, clickDeactivate, clickCancel ) => {
		if( alarm.alarmStatus === false ) {
			if( alarm.inActivation === true ) {
				return (
					<Fab variant="extended" color="primary" aria-label="add" onClick={ clickCancel } sx={{color: orange[500]}}>				
						<CampaignIcon sx={{ mr: 1, color: orange[500]}} />
						Aktivierung Abbrechen </Fab>
				)
			} else {
				const filteredAE = alarmElements.filter( element => isBlocker( element ) );
				if( filteredAE.length > 0 ) {
					return (
						<Grid container justifyContent="center" alignItems="center" spacing={3}>
							<Grid item>
							<Fab disabled variant="extended" color="primary" aria-label="add" onClick={ clickActivate } >				
								<CampaignIcon sx={{ mr: 1 }} />
								Alarm aktivieren </Fab>
							</Grid>
							<Grid item>
								<Typography>Überwachte Fenster oder andere Kontakte müssen erst geschlossen werden</Typography>
							</Grid>
						</Grid>
					)
				} else {
					return (
						<Fab variant="extended" color="primary" aria-label="add" onClick={ clickActivate } sx={{color: orange[500]}}>				
							<CampaignIcon sx={{ mr: 1, color: orange[500]}} />
							Alarm aktivieren </Fab>
					)
				}
			}
		} else {
			return (
				<Fab variant="extended" color="primary" aria-label="add" onClick={ clickDeactivate } sx={{color: green[500]}}>				
					<CampaignIcon sx={{ mr: 1, color: green[500]}} />
					Alarm deaktivieren </Fab>
			)
		}
	}

	useEffect(() => {
		console.log ("useEffect - AlarmActivate");
	}, [PIN]);
	
	const createActivatePane = ( clickActivate, clickDeactivate, clickCancel ) => {
		return (
			<Box sx={{ flexGrow: 1 }} >
				<Grid container justifyContent="center" alignItems="center" spacing={3}>
					<Grid item>
						{ getAlarmButton( clickActivate, clickDeactivate, clickCancel ) }
					</Grid>
					<Grid item xs={12}>
						<AlarmActivateTable writeSnack={writeSnack} />
					</Grid>
				</Grid>
			</Box>
		)
	}

	if( isLoggedInUser() ) {
		// activate with logged in user
		return (
			createActivatePane( clickActivateUser, clickDeactivateUser, clickCancelUser  )
		)
	} else if ( PIN !== undefined && PIN.length === 6 ) {
		return (
			createActivatePane( clickActivatePIN, clickDeactivatePIN, clickCancelPIN )
		)
	} else {
		// activate with pin first 
		return (
			<Numpad title={"PIN eingeben"} handleEntry={ handleEntry }/>
		)
	}
}

export default AlarmActivate;