import { ELEMENT_FETCH, ELEMENT_UPDATE, BREADCRUMB_FETCH, MENU_FETCH } from '../constants/actionTypes';
import * as api from '../api/index.js';
import { socket } from '../index';
import { SOCKET_LASTPAGE, ACTION_DRIVE_ROLLER_TO_POS, ROLLERSHUTTER } from '../constants/general';

import { annonymousLogin } from '../actions/auth';
import AnyActionClient from '../models/anyActionClient';

//Action Creators
export const getMenues = () => async (dispatch) => {
	try {
		console.log( "Response getMenu 1");
		const { data } = await api.fetchMenu();
		//console.log( "Response getMenu 2" + JSON.stringify(data, null, 2 ));

		dispatch ({ type: MENU_FETCH, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const getElementsForPage = ( page ) => async (dispatch) => {
	try {
		console.log( "Response getElementsForPage page: " + page);
		const { status, data } = await api.fetchElementsForPage( page );
		//console.log( "Response status: " + status );
		if( status === 210 ) {
			console.log( "Token expired!" );
			//token expired
			annonymousLogin();
		}
		//console.log( "Response getElementsForPage:" + JSON.stringify(data, null, 2 ));
				
		dispatch ({ type: ELEMENT_FETCH, payload: data });
		socket.emit( SOCKET_LASTPAGE, page );
	} catch ( error ) {
		console.log(error);
	}
}

export const updateElement = ( element ) => async (dispatch) => {
	try {
		//console.log( "updateElement: " + JSON.stringify(element));
		dispatch ({ type: ELEMENT_UPDATE, payload: element });
	} catch ( error ) {
		console.log(error);
	}
}

export const getBreadcrumbsForPage = ( page ) => async (dispatch) => {
	try {
		console.log( "Response getBreadcrumbsForPage page: " + page);
		const { data } = await api.fetchBreadcrumbsForPage( page );
		//console.log( "Response getBreadcrumbsForPage:" + JSON.stringify(data, null, 2 ));
				
		dispatch ({ type: BREADCRUMB_FETCH, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const prepareTelegram = async (telegram) => {
	console.log("prepareTelegram");
	try {
		//console.log("send Telegram 1: " + JSON.stringify(telegram));	
		const { status, data } = await api.sendTelegram( telegram );

		//console.log("prepareTelegram status: " + status);
		//console.log("prepareTelegram data: " + data);
		/*
		const { data } = await api.sendTelegram( telegram );
		console.log("prepareTelegram data: " + data);
		*/
		return;

	} catch ( error ) {
		if( error.response.request.status === 401 ||
			error.response.request.status === 409 ) {
			console.log("prepareTelegram - Error in type 401");
			console.log(error);
			return error.response.data.message;
		} else {
			console.log("prepareTelegram - Other Error");
			console.log(error);
			return  error.message;
		}
	}
} 

export const switchLight = async ( e, element ) => {
	console.log( "switchLight - Switch: " + element.knxid + " val: " + e.target.checked );
	var telegram = {};
	
	if( element.binding === undefined ) {
		telegram.type = 'KNX';
	} else {
		telegram.type = element.binding;
	}
	
	telegram.item = element.knxid;
	telegram.groupAddress = element.knxGrps.switch;
	telegram.datapoint = "DPT1";

	if ( e.target.checked ){
		telegram.value = 1;
	} else {
		telegram.value = 0;
	}

	//dispatch(sendTelegram( telegram ));
	//console.log("Switch light Telegram: " + JSON.stringify(telegram, null, 2) );
	return await prepareTelegram( telegram );
	
}

export const dimLight = async (e, newValue, element) => {
	console.log( "dimLight val: " + newValue );
	//console.log( "dimLight event: " + e );
	//console.log( "dimLight Dimm: " + element.knxid );

	var telegram = {};

	if( element.binding === undefined ) {
		telegram.type = 'KNX';
	} else {
		telegram.type = element.binding;
	}

	telegram.item = element.knxid;
	telegram.groupAddress = element.knxGrps.value;
	telegram.value = Math.round( newValue * 2.55);
	telegram.datapoint = "DPT5";

	//console.log( telegram );

	//dispatch(sendTelegram( telegram ));
	return await prepareTelegram( telegram );
};

export const temperatureUpOrDown = async ( e, element, changeval ) => {
	console.log( "temperatureUpOrDown: " + element.knxid  );
	var telegram = {};
	
	if( element.binding === undefined ) {
		telegram.type = 'KNX';
	} else {
		telegram.type = element.binding;
	}

	telegram.item = element.knxid;
	telegram.groupAddress = element.knxGrps.controlValue;
	telegram.datapoint = "DPT9.001";
	telegram.value = element.value2 + changeval;
	telegram.noStatusUpdate = true;
	//console.log("temperatureUp Telegram: " + JSON.stringify(telegram, null, 2) );
	return await prepareTelegram( telegram );
}

export const rollershutterUp = async ( e, element ) => {
	console.log( "rollershutterUp: " + element.knxid  );
	var telegram = {};
	
	if( element.binding === undefined ) {
		telegram.type = 'KNX';
	} else {
		telegram.type = element.binding;
	}

	telegram.item = element.knxid;
	telegram.groupAddress = element.knxGrps.move;
	telegram.datapoint = "DPT1";
	telegram.value = 0;
	//console.log("rollershutterUp Telegram: " + JSON.stringify(telegram, null, 2) );
	return await prepareTelegram( telegram );
}

export const rollershutterDown = async ( e, element ) => {
	console.log( "rollershutterUp: " + element.knxid  );
	var telegram = {};
	
	
	if( element.binding === undefined ) {
		telegram.type = 'KNX';
	} else {
		telegram.type = element.binding;
	}

	telegram.item = element.knxid;
	telegram.groupAddress = element.knxGrps.move;
	telegram.datapoint = "DPT1";
	telegram.value = 1;
	//console.log("rollershutterUp Telegram: " + JSON.stringify(telegram, null, 2) );
	return await prepareTelegram( telegram );
}

export const rollershutterStop = async ( e, element ) => {
	console.log( "rollershutterStop: " + element.knxid  );
	var telegram = {};
	
	if( element.binding === undefined ) {
		telegram.type = 'KNX';
	} else {
		telegram.type = element.binding;
	}

	telegram.item = element.knxid;
	telegram.groupAddress = element.knxGrps.stop;
	telegram.datapoint = "DPT1";
	telegram.value = 0;
	//console.log("rollershutterStop Telegram: " + JSON.stringify(telegram, null, 2) );
	return await prepareTelegram( telegram );
}

export const rollershutterToPos = async ( e, element, value ) => {
	console.log( "rollershutterToPos: " + element.knxid  );
	var myaa = new AnyActionClient( element.knxid, ACTION_DRIVE_ROLLER_TO_POS, value );
	myaa.object = ROLLERSHUTTER;

	try {
		//console.log("rollershutterToPos 1: " + JSON.stringify( myaa ));	
		const { status, data } = await api.sendAnyAction( myaa );

		//console.log("rollershutterToPos status: " + status);
		//console.log("rollershutterToPos data: " + data);
		return;

	} catch ( error ) {
		if( error.response.request.status === 401 ||
			error.response.request.status === 409 ) {
			console.log("rollershutterToPos - Error in type 401");
			console.log(error);
			return error.response.data.message;
		} else {
			console.log("rollershutterToPos - Other Error");
			console.log(error);
			return  error.message;
		}
	}
}


export const switchScene = async ( e, element, sceneVariant ) => {
	console.log( "switchScene" );
	//console.log( "switchScene sceneVariant: " + JSON.stringify(sceneVariant ));
	//console.log( "switchScene element: " + JSON.stringify(element, null, 2) );

	var telegram = {};
	telegram.type = sceneVariant.type;
	telegram.item = element.knxid;
	telegram.groupAddress = sceneVariant.knxGrp;
	telegram.value = sceneVariant.value;
	telegram.datapoint = "DPT5";

	//console.log( "Telegram: " + JSON.stringify( telegram ));
	return await prepareTelegram( telegram );
};