import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Button, Paper, Grid, Box, Divider  } from '@mui/material';
//import MuiAlert from '@mui/material/Alert';
import { ClassNames } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

import useStyles from './styles';
import moment from 'moment';

import EventTimerFormTabs from './EventTimerFormTabs';
import EventTimerGeneralForm from './EventTimerGeneralForm';
import EventActionSteps from './EventActionsSteps';
import ActionAssignedList from './ActionTabs/actionAssignedList';

import { createTimerEvent, updateTimerEvent, deleteTimerEvent } from '../../actions/timerEvents';

import AnyAction from '../../models/anyActionClient';

import * as CONST from '../../constants/general';

/*
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
*/

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`eventMain-tabpanel-${index}`}
			aria-labelledby={`eventMain-tab-${index}`}
		{...other}
		>
		{value === index && (
			<Box sx={{ p: 3 }}>
				{children}
			</Box>
		)}
		</div>
	);
}


function a11yProps( index ) {
	return {
		id: `eventMain-tab-${index}`,
		'aria-controls': `eventMain-tabpanel-${index}`,
	};
}


const EventTimerForm = ( { currentTimerId, setCurrentTimerId, writeSnack, setSnackOpen } ) => {
	const [ timerData, setTimerData ] = useState({ title: '', nextDateTime: '', periodStart: '', periodEnd: '', 
											frequency: { type: 'onetime', value: '', time: '', sunrise: '', sunset: '', weekdays:[], day: '' },
											frequencyFactor: 1, action: [], repeats: 0, creator: '', 
											fireOnRestart: 0, forceRecalcOnRestart: 0, active: 1 });

											//ftype: '', fvalue: '', ftime: '', fsunrise: '', fsunset: '', fweekdays: [], fday: '',

	const [currentAnyAction, setCurrentAnyAction] = React.useState( new AnyAction() );
	const [activeStep, setActiveStep] = React.useState(0);
	const [selectedIndex, setSelectedIndex] = React.useState();

	const timer = useSelector((state) => {
		if( currentTimerId ){
			return state.timerEvents.find((t) => t._id === currentTimerId);
		} else {
			return null;
		}
	} );
	const classes = useStyles();
	var user = JSON.parse(localStorage.getItem('profile'));

	const dispatch = useDispatch();

	useEffect(() => {
		if( timer ) setTimerData( timer );
	}, [timer]);

	const isLoggedIn = () => {
		user = JSON.parse(localStorage.getItem('profile'));

		if (user !== null && user.result.name !== undefined && user.result.name !== "" ) {
			return true;
		}
		return false;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		
		setSnackOpen( false );
		let myret; 

		if( isLoggedIn() === false ) {
			writeSnack( "Bitte anmelden um Timer zu erstellen" );
			return;
		}

		//Cleanup object
		switch ( timerData.frequency.type) {
			case CONST.TIM_ONETIME:
				myret = checkBasicForm( ["value"] );
				clearKeep ( ["value"] )
				break;
			case CONST.TIM_INTERVAL:
				myret = checkBasicForm( ["value"] );
				clearKeep ( ["value","frequencyFactor","repeats"] )
				break;
			case CONST.TIM_DAILY:
				myret = checkBasicForm( ["timeOrSun"] );
				clearKeep ( ["time","sunrise","sunset","frequencyFactor","repeats"] )
				break;
			case CONST.TIM_WEEKDAYS:
				myret = checkBasicForm( ["weekday", "timeOrSun"] );
				clearKeep ( ["time","sunrise","sunset","weekdays","repeats"] )
				break;
			case CONST.TIM_DAYINMONTH:
				myret = checkBasicForm( ["value", "timeOrSun"] );
				clearKeep ( ["value","time","sunrise","sunset","frequencyFactor","repeats"] )
				break;
			case CONST.TIM_WEEKDAYINMONTH:
				myret = checkBasicForm( ["value", "weekday", "timeOrSun"] );
				clearKeep ( ["value","time","sunrise","sunset","weekdays","repeats"] )
				break;
			case CONST.TIM_YEARLY:
				myret = checkBasicForm( ["day", "timeOrSun"] );
				let mymom = moment( timerData.frequency.day, "DD.MM.");
				if( mymom.isValid() === false ) {
					myret.push("Ungültiges Datumsformat");
				}
				clearKeep ( ["day","time","sunrise","sunset","repeats"] )
				break;
			default:
				break;
		}

		if( myret.length > 0 ) {
			writeSnack( myret[0] );
		} else {
			//console.log( "currentTimerId: " + currentTimerId);

			if( currentTimerId ){
				dispatch( updateTimerEvent( currentTimerId, timerData, writeSnack ));	
			} else {
				if( user.result.name !== undefined ) {
					timerData.creator = user.result.name;
				}
				dispatch( createTimerEvent( timerData, writeSnack ));
			}
			clear();
		}
	}

	const checkBasicForm = ( mandatories ) => {
		let myret = [];

		if( timerData.title === "" ) {
			myret.push("Bitte Titel angeben");
		}
		/*
		if( timerData.creator === "" ) {
			myret.push("Bitte Ersteller angeben");
		}*/
		if( mandatories.includes( "value") === true ) { 
			if( timerData.frequency.value === "" ) {
				myret.push("Bitte Wert angeben");}}
		if( mandatories.includes( "timeOrSun") === true ) {
			if( timerData.frequency.time === "" && timerData.frequency.sunrise === "" && timerData.frequency.sunset === "" ) {
				myret.push("Bitte Zeit oder Sonnenstandsfunktion wählen");}}
		if( mandatories.includes( "weekday") === true ) {
			if( timerData.frequency.weekdays.length === 0 ) {
				myret.push("Bitte Wochentag auswählen");}}
		if( mandatories.includes( "day") === true ) {
			if( timerData.frequency.day === "" ) {
				myret.push("Bitte Datum angeben");}}

		return myret;
	}


	const clearKeep = ( keys ) => {
		if( keys.includes( "value") === false ) { 
			timerData.frequency.value = '' }
		if( keys.includes( "time") === false ) {
			timerData.frequency.time = ''}
		if( keys.includes( "sunrise") === false ) {
			timerData.frequency.sunrise = ''}
		if( keys.includes( "sunset") === false ) {
			timerData.frequency.sunset = ''}
		if( keys.includes( "weekdays") === false ) {
			timerData.frequency.weekdays = []}
		if( keys.includes( "day") === false ) {
			timerData.frequency.day = ''}
		if( keys.includes( "frequencyFactor") === false ) {
			timerData.frequencyFactor = 1}
		if( keys.includes( "repeats") === false ) {
			timerData.repeats = ''}
	}

	const clear = () => {
		setCurrentTimerId(null);
		setTimerData({ title: '', nextDateTime: '', periodStart: '', periodEnd: '', 
		frequency: { type: 'onetime', value: '', time: '', sunrise: '', sunset: '', weekdays:[], day: '' },
		frequencyFactor: 1, action: [], repeats: 0, creator: '', 
		fireOnRestart: 0, forceRecalcOnRestart: 0, active: 1 });
	}

	const deleteTimer = () => {
		console.log( "deleteTimer: " );
		if( isLoggedIn() === false ) {
			writeSnack( "Bitte anmelden um Timer zu löschen" );
			return;
		}

		dispatch( deleteTimerEvent( currentTimerId, writeSnack ));	
	}

	const [mainTabValue, setMainTabValue] = React.useState(0);

	const handleMainTabChange = ( event, newValue) => {
		setMainTabValue(newValue);
	};

	return (
		
		<Paper className={ ClassNames.paper } style={{padding: 8}}>
			<form autoComplete='off' noValidate className='classes.form' onSubmit={handleSubmit}>
				<Box >
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={mainTabValue} onChange={handleMainTabChange} aria-label="basic tabs example">
							<Tab label="Allgemein" {...a11yProps(0)} />
							<Tab label="Timer" {...a11yProps(1)} />
							<Tab label="Aktionen" {...a11yProps(2)} />
						</Tabs>
					</Box>
					<TabPanel value={mainTabValue} index={0}>
						<EventTimerGeneralForm currentTimerId={currentTimerId} setCurrentTimerId={setCurrentTimerId} timerData={timerData} setTimerData={setTimerData} />
					</TabPanel>
					<TabPanel value={mainTabValue} index={1}>
						<EventTimerFormTabs timerData={timerData} setTimerData={setTimerData} />
					</TabPanel>
					<TabPanel value={mainTabValue} index={2}>
						<Grid container>
							<Grid item xs={12} sm={8} md={8} lg={8} >
								<EventActionSteps timerData={timerData} setTimerData={setTimerData} 
									currentAnyAction={currentAnyAction} setCurrentAnyAction={setCurrentAnyAction}
									selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}
									activeStep={activeStep} setActiveStep={setActiveStep}
									writeSnack={writeSnack} />
							</Grid>
							<Grid item xs={12} sm={4} md={4} lg={4} >
								<ActionAssignedList timerData={timerData} setTimerData={setTimerData} 
									currentAnyAction={currentAnyAction} setCurrentAnyAction={setCurrentAnyAction}
									selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}
									activeStep={activeStep} setActiveStep={setActiveStep}
									writeSnack={writeSnack} />
							</Grid>
						</Grid>
					</TabPanel>
					<Divider />
					<Grid container sx={{mt:1.5}}>
						<Grid item  xs={12} >
							<Grid container columnSpacing={1} rowSpacing={1}>
								<Grid item xs={12} sm={4}>
									<Button className={classes.buttonSubmit} sx={{mt:-0.4}} variant="contained" startIcon={<SaveIcon />} color="primary" size="large" type="submit" fullWidth>
										{currentTimerId ? 'Überschreiben' : 'Neu Speichern'}</Button>
								</Grid>
								<Grid item xs={6} sm={4}>
									<Button sx={{ml:'1', mr:'1'}} variant="outlined" color="primary" startIcon={<DeleteIcon />} onClick={deleteTimer} fullWidth>
										Löschen</Button>
								</Grid>
								<Grid item xs={6} sm={4}>
									<Button variant="outlined" color="primary" startIcon={<ClearIcon />} onClick={clear} fullWidth>
										Leeren</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</form>	
		</Paper>
		
	);
}

export default EventTimerForm;
