import { makeStyles } from '@mui/styles';

export default makeStyles({

	border: {
		border: 'solid',
	  },
	  fullHeightCard: {
		height: '100%',
	  },
	  card: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		borderRadius: '20px',
		height: '100%',
		position: 'relative', 
	  },
	  overlay: {
		position: 'absolute',
		top: '9px',
		left: '25px',
		color: 'white',
	  },
	  overlay3: {
		position: 'absolute',
		top: '20px',
		right: '15px',
		color: 'white',
	  },
	  overlay4: {
		position: 'absolute',
		top: '20px',
		right: '15px',
		color: 'primary',
	  },
	  overlay2: {
		padding: '4px 16px',
	  }

})