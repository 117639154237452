import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

//import InboxIcon from '@mui/icons-material/MoveToInbox';
//import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import RoofingIcon from '@mui/icons-material/Roofing';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';
import EmojiNatureOutlinedIcon from '@mui/icons-material/EmojiNatureOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import BoyOutlinedIcon from '@mui/icons-material/BoyOutlined';
import ComputerIcon from '@mui/icons-material/Computer';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import SensorDoorOutlinedIcon from '@mui/icons-material/SensorDoorOutlined';
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';
import SoupKitchenOutlinedIcon from '@mui/icons-material/SoupKitchenOutlined';
import CheckroomOutlinedIcon from '@mui/icons-material/CheckroomOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import RollerShadesClosedOutlinedIcon from '@mui/icons-material/RollerShadesClosedOutlined';


import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';

import IconButton from '@mui/material/IconButton';

import { getElementsForPage, getBreadcrumbsForPage } from '../../actions/knx';

const anchor='myMenueState';

const MyMenu = () => {
	const dispatch = useDispatch();
	const menues = useSelector((state) => state.menues );

	/* --- States and Store --- */
	const [state, setState] = React.useState({
		myMenueState: false
	});

	const closeDrawer = () => {
		setState({ ...state, [anchor]: false });
	}

	const toggleDrawer = (myanchor, open) => (event) => {

		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
			) {
			return;
		}
		setState({ ...state, [myanchor]: open });
	};

	//toDo: for innermenu1 - there is a max of 8 sub menues - could not sort out to do that fully dynamically
	const [subMenuOpen, setSubMenuOpen] = React.useState({
		0: false,
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
		6: false,
		7: false
	});

	/* --- Action handlers */
	
	const handleMenuClick = ({ index, menu }) => {
		setSubMenuOpen({ [index]: !subMenuOpen[index] }); 
	};

	const handleFlatMenuClick = ({ index, menu }) => {
		closeDrawer();
		dispatch( getBreadcrumbsForPage( menu.page ) );
		dispatch( getElementsForPage( menu.page ) );
	}
		
	const handleSubMenuClick = ({ submenu }) => {
		closeDrawer();
		dispatch( getBreadcrumbsForPage( submenu.page ) );
		dispatch( getElementsForPage( submenu.page ) );
	}
	
	/* --- UI creators */
	const getMenuIcon = ({ menu }) => {
		if( menu !== undefined ) {
			switch ( menu.icon ) {
				case "Home": return ( <HomeIcon /> );
				case "Dach": return ( <RoofingIcon /> );
				case "1OG": return ( <HotelOutlinedIcon /> );

				case "Kind": return ( <BoyOutlinedIcon /> );
				case "Bad": return ( <BathtubOutlinedIcon /> );
				case "Gang": return ( <MeetingRoomOutlinedIcon /> );
				case "Schlafzimmer": return ( <HotelOutlinedIcon /> );
				case "Buero": return ( <ComputerIcon /> );

				case "EG": 	return ( <ChairOutlinedIcon /> );
				
				case "Kueche": return ( <SoupKitchenOutlinedIcon /> );
				case "Schuhschrank": return ( <CheckroomOutlinedIcon /> );	
				case "WC": return ( <WcOutlinedIcon /> );
				case "Torhaus": return ( <SensorDoorOutlinedIcon /> );
				case "Wohnzimmer": return ( <ChairOutlinedIcon /> );

				case "Keller": return ( <DownloadForOfflineOutlinedIcon /> );	
				case "Licht": return ( <LightbulbOutlinedIcon /> );
				case "Fenster": return ( <WindowOutlinedIcon /> );

				case "Garten": return ( <EmojiNatureOutlinedIcon /> );	
				case "Werstatt": return ( <HandymanOutlinedIcon /> );	
								
				case "Zentral": return ( <LanguageOutlinedIcon /> );					
				case "Wetter": return ( <WbSunnyOutlinedIcon /> );					
				case "Funktionen": return ( <EngineeringOutlinedIcon /> );	
				case "Alarm": return ( <CampaignOutlinedIcon /> );	
				case "Timer": return ( <TimerOutlinedIcon /> );	
				case "Steckdose": return ( <ElectricalServicesIcon /> );	
				case "Heizung": return ( <DeviceThermostatIcon /> );
				case "Rollo": return ( <RollerShadesClosedOutlinedIcon /> );

				default: break;
			}
		} 
		return ( <StarBorder /> );
	}

	const createMenu = ({ menu, index }) => {
		
		if( menu.submenues.length === 0 ) {
			return createFlatMenu({ menu, index });
		} else {
			return createMenueWithSubmenu({ menu, index });
		}
	}

	const createFlatMenu = ({ menu, index }) => {
		return (
			
				<ListItemButton onClick={(e) => handleFlatMenuClick({ index, menu })}>
					<ListItemIcon>
						{ getMenuIcon({ menu }) }
					</ListItemIcon>
					<ListItemText primary={menu.label} />
				</ListItemButton>
		)
	}

	const createMenueWithSubmenu = ({ menu, index }) => {
		return (
				<Stack>
					<ListItemButton onClick={(e) => handleMenuClick({ index, menu })}>
						<ListItemIcon>
							{ getMenuIcon({ menu }) }
						</ListItemIcon>
						<ListItemText primary={menu.label} />
						{ subMenuOpen[index] ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={subMenuOpen[index]} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							{ menu.submenues.map((submenu) => (
								createSubmenuItem({ submenu })
							))}
						</List>
					</Collapse>
				</Stack>
		)
	}

	const createSubmenuItem = ({ submenu }) => {
		let menu = submenu;
		return (
			<ListItemButton key={submenu.id} sx={{ pl: 4 }} 
				onClick={(e) => handleSubMenuClick({ submenu })}>
				<ListItemIcon>
					{ getMenuIcon({ menu }) }
				</ListItemIcon>
				<ListItemText primary={submenu.label} />
			</ListItemButton>
		)
	}

	const list = () => (
		<Box
			sx={{ width: 300 }}
			role="presentation"
			onKeyDown={toggleDrawer(anchor, false)} >
			<List
				sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper' }}
				component="nav">
				{ menues.map((menu, index) => (
					<ListItem key={menu.id} disablePadding>
						{ createMenu({ menu, index }) }
					</ListItem>
				))}	
			</List>
			<Divider />
		</Box>
	);

	/* --- The Main part */

	return (
		<div>
			<React.Fragment key={anchor}>
				<IconButton
					onClick={toggleDrawer( anchor, true )}
					size="large"
					edge="start"
					color="inherit"
					aria-label="open drawer"
					sx={{ mr: 2 }} >
					<MenuIcon />
				</IconButton>
				<SwipeableDrawer 
					open={state[anchor]}
					onClose={toggleDrawer(anchor, false)}
					onOpen={toggleDrawer(anchor, true)} >
					{list()}
				</SwipeableDrawer>
			</React.Fragment>
		</div>
	);
}

export default MyMenu;

