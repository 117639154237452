import * as api from '../api/index.js';
import * as ACTION_TYPES from '../constants/actionTypes.js';


export const changePIN = ( oldPIN, newPIN ) => async (dispatch) => {
	console.log( "alarm - actioner - changePIN ");
	try {
		let pinData = {};
		pinData.oldPIN = oldPIN;
		pinData.newPIN = newPIN;
		const { data } = await api.changePIN( pinData );
		//console.log( "alarm - actioner - changePIN " + JSON.stringify( data ));
		dispatch({ type: ACTION_TYPES.ALERT_CREATE_UPDATE, payload: data })

	} catch ( error ) {
		console.log(error);
	}
}

export const changePINUser = ( newPIN ) => async (dispatch) => {
	console.log( "alarm - actioner - changePIN ");
	try {
		let pinData = {};
		pinData.oldPIN = "";
		pinData.newPIN = newPIN;
		const { data } = await api.changePINUser( pinData );
		//console.log( "alarm - actioner - changePIN " + JSON.stringify( data ));
		dispatch({ type: ACTION_TYPES.ALERT_CREATE_UPDATE, payload: data })

	} catch ( error ) {
		console.log(error);
	}
}

export const activateAlarmPIN = ( myPIN, snack ) => async (dispatch) => {
	console.log( "alarm - actioner - activateAlarmPIN ");
	try {
		let pinData = {};
		pinData.PIN = myPIN;
		const { status, data } = await api.activateAlarmPIN( pinData );
		//console.log( "alarm - actioner - activateAlarmPIN " + JSON.stringify( data ));

		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
			snack( data.message, "warning" );
		} else {
			//Alarm sequence started
			snack( "Alarm wird scharf geschaltet", "success" );
		}
	} catch ( error ) {
		if( error.response.request.status === 401 ||
			error.response.request.status === 409 ) {
			snack( error.response.data.message, "warning" );
		} else {
			snack( error.message, "warning" );
		}
		console.log(error);
	}
}


export const activateAlarmUser = ( snack ) => async (dispatch) => {
	console.log( "alarm - actioner - activateAlarmUser ");
	try {
		const { status, data } = await api.activateAlarmUser();
		//console.log( "alarm - actioner - activateAlarmUser " + JSON.stringify( data ));
		
		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
			snack( data.message, "warning" );
		} else {
			//Alarm sequence started
			snack( "Alarm wird scharf geschaltet", "success" );
		}
	} catch ( error ) {
		if( error.response.request.status === 401 ||
			error.response.request.status === 409 ) {
			snack( error.response.data.message, "warning" );
		} else {
			snack( error.message, "warning" );
		}
		console.log(error);
	}
}

export const deactivateAlarmPIN = ( myPIN, snack ) => async (dispatch) => {
	console.log( "alarm - actioner - deactivateAlarmPIN ");
	try {
		let pinData = {};
		pinData.PIN = myPIN;
		const { status, data } = await api.deactivateAlarmPIN( pinData );
		//console.log( "alarm - actioner - deactivateAlarmPIN " + JSON.stringify( data ));

		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
			snack( data.message, "warning" );
		} else {
			//Alarm sequence started
			snack( "Alarm wird ausgeschaltet", "success" );
		}
	} catch ( error ) {
		if( error.response.request.status === 401 ||
			error.response.request.status === 409 ) {
			snack( error.response.data.message, "warning" );
		} else {
			snack( error.message, "warning" );
		}
		console.log(error);
	}
}

export const deactivateAlarmUser = ( snack ) => async (dispatch) => {
	console.log( "alarm - actioner - deactivateAlarmUser ");
	try {
		const { status, data } = await api.deactivateAlarmUser();
		//console.log( "alarm - actioner - deactivateAlarmUser " + JSON.stringify( data ));

		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
			snack( data.message, "warning" );
		} else {
			//Alarm sequence started
			snack( "Alarm wird ausgeschaltet", "success" );
		}
	} catch ( error ) {
		if( error.response.request.status === 401 ||
			error.response.request.status === 409 ) {
			snack( error.response.data.message, "warning" );
		} else {
			snack( error.message, "warning" );
		}
		console.log(error);
	}
}

export const cancelAlarmPIN = ( myPIN, snack ) => async (dispatch) => {
	console.log( "alarm - actioner - cancelAlarmPIN ");
	try {
		let pinData = {};
		pinData.PIN = myPIN;
		const { status, data } = await api.cancelAlarmPIN( pinData );
		//console.log( "alarm - actioner - cancelAlarmPIN " + JSON.stringify( data ));

		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
			snack( data.message, "warning" );
		} else {
			//Alarm sequence started
			snack( "Alarm Aktivierung abgebrochen", "success" );
		}
	} catch ( error ) {
		if( error.response.request.status === 401 ||
			error.response.request.status === 409 ) {
			snack( error.response.data.message, "warning" );
		} else {
			snack( error.message, "warning" );
		}
		console.log(error);
	}
}


export const cancelAlarmUser = ( snack ) => async (dispatch) => {
	console.log( "alarm - actioner - cancelAlarmUser ");
	try {
		const { status, data } = await api.cancelAlarmUser();
		//console.log( "alarm - actioner - cancelAlarmUser " + JSON.stringify( data ));

		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
			snack( data.message, "warning" );
		} else {
			//Alarm sequence started
			snack( "Alarm Aktivierung abgebrochen", "success" );
		}
	} catch ( error ) {
		if( error.response.request.status === 401 ||
			error.response.request.status === 409 ) {
			snack( error.response.data.message, "warning" );
		} else {
			snack( error.message, "warning" );
		}
		console.log(error);
	}
}


//Action Creators
export const getAlarmStatus = () => async (dispatch) => {
	try {
		const { data } = await api.fetchStatusAlarm();
		dispatch ({ type: ACTION_TYPES.ALARM_STATUS, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const setAlarmStatus = ( alarm ) => async (dispatch) => {
	try {
		//console.log( "setAlarmStatus " + JSON.stringify(alarm)) ;
		dispatch ({ type: ACTION_TYPES.ALARM_STATUS, payload: alarm });
	} catch ( error ) {
		console.log(error);
	}
}



//Action Creators
export const getAlarmElements = () => async (dispatch) => {
	try {
		const { data } = await api.fetchAlarmElements();
		dispatch ({ type: ACTION_TYPES.ALARM_FETCH_ALL, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const updateAlarmElementStatus = ( ae ) => async (dispatch) => {
	try {
		console.log( "updateAlarmElementStatus: " + JSON.stringify(ae));
		dispatch ({ type: ACTION_TYPES.ALARM_UPDATE, payload: ae });
	} catch ( error ) {
		console.log(error);
	}
}


export const updateAlarmElement = (id, updatedAlarmElement, snack ) => async (dispatch) => {
	try {
		const { status, data } = await api.updateAlarmElement( id, updatedAlarmElement );

		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
			snack( data.message, "warning" );
		} else {
			dispatch ({ type: ACTION_TYPES.ALARM_UPDATE, payload: data }); 
			snack( "Überwachung erfolgreich geändert", "success" );
		}
	} catch (error) {
		if( error.response.request.status === 401 ) {
			snack( error.response.data.message, "warning" );
		} else {
			snack( error.message, "warning" );
		}
		console.log(error);
	}
} 