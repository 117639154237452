import { ELEMENT_FETCH, ELEMENT_UPDATE } from '../constants/actionTypes';

const elements = function ( elements = [], action ) {
	switch ( action.type ) {
		case ELEMENT_FETCH:
			return action.payload;
		case ELEMENT_UPDATE:
			return elements.map(( element ) => {
				if( element.knxid === action.payload.knxid ) {
						//toDo: nur einmal das array durchgehen und dann über das Socket Update objekt driekt setzen
					return action.payload; } else { 
					return element; }
			})
		default:
			return elements;
	}
};

export default elements;
