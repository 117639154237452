
// Element MajorTypes
export const ELEMENT_CONTROL = "ELE_CONTROL";

// Element Typers

export const DIMMER = "Dimmer";
export const HEATING = "Heating";
export const LIGHT = "Light";
export const NUMBER = "Number";
export const PAGE = "Page";
export const PLUG = "Plug";
export const ROLLERSHUTTER = "Rollershutter";
export const SCENE = "Scene";
export const WINDOW = "Window";
export const DIVIDER = "Divider";

export const VARIABLE = 'Variable';
export const WORKFLOW = 'Workflow';
export const ALERTAPP = 'AlertApp';

export const TIMERPAGE = "TimerPage";
export const ALARMACTIVATEPAGE = "AlarmActivatePage";
export const ALARMDEACTIVATEPAGE = "AlarmDeactivatePage";
export const ALARMCONFIGUREPAGE = "AlarmConfigurePage";
export const ALARMCHANGEPINPAGE = "AlarmChangePINPage";
export const HEATING_PAGE = "HeatingPage";

// visoStyles
export const STYLE_DESKTOP = 1;
export const STYLE_MOBILE = 0;

export const STYLE_NOPERCENT = "noPercent";
export const STYLE_CONTROL = "control";

// Binding Types
export const BIND_KNX = "KNX";
export const BIND_SONOFF = "SONOFF";
export const BIND_POOLTEMP = "POOLTEMP";
export const BIND_PUSHOVER = "PUSHOVER";
export const BIND_THREEWAYSWITCH = "THREEWAYSWITCH";
export const BIND_EXTERNALSENSOR = "EXTERNALSENSOR";
export const BIND_WORKFLOW = "Workflow";
export const BIND_ROLLERPOS = "ROLLERPOS";

// Heating Types
export const HEATING_CURRENT = "current";
export const HEATING_TOBE = "toBe";
export const HEATING_TOBESTD = "toBeStd";
export const HEATING_TOBEAWAY = "toBeAway";

// External Sensor Types
export const ESENSOR_RAIN = "Rain";

// Alerts
export const ALERT_ERROR = "error";
export const ALERT_WARNING = "warning";
export const ALERT_INFO = "info";
export const ALERT_SUCCESS = "success";

// AnyAction
export const ACTION_SWITCH = "switch";
export const ACTION_SWITCH_ON = "switchOn";
export const ACTION_SWITCH_OFF = "switchOff";
export const ACTION_SET_TO_VALUE = "dim";
export const ACTION_DIM = "dim";
export const ACTION_ROLLERSHUTTER_UP = "rollershutterUp";
export const ACTION_ROLLERSHUTTER_DOWN = "rollershutterDown";
export const ACTION_ROLLERSHUTTER_STOP = "rollershutterStop";
export const ACTION_DRIVE_ROLLER_TO_POS = "driveRollerToPos";
export const ACTION_SWITCH_SCENE = "switchScene";
export const ACTION_SET_VARIABLE = "setVariable";
export const ACTION_PUSHOVER = "Pushover";
export const ACTION_ALERT_APP = "AlertApp";
export const ACTION_WAIT = "wait";
export const ACTION_WORKFLOW = "Workflow";


//Timer Tyes
export const TIM_ONETIME = "onetime";
export const TIM_INTERVAL = "interval";
export const TIM_DAILY = "daily";
export const TIM_WEEKDAYS = "weekdays";
export const TIM_DAYINMONTH = "dayInMonth";
export const TIM_WEEKDAYINMONTH = "weekdayInMonth";
export const TIM_YEARLY = "yearly";

export const TIM_TABINDEX = '{ "onetime": 0, "interval": 1, "daily": 2, "weekdays": 3, "dayInMonth": 4, "weekdayInMonth": 5, "yearly": 6}';
export const TIM_TABINDEXNAME = ["onetime", "interval", "daily", "weekdays", "dayInMonth", "weekdayInMonth", "yearly" ];


// Socket.io messages

export const SOCKET_ALARM_CONFIG = "AlarmConfig";
export const SOCKET_ALARM_ELEMENT = "AlarmElement";
export const SOCKET_ALERT = "Alert";
export const SOCKET_HEATING = "Heating";
export const SOCKET_CONNECTION = "connection";
export const SOCKET_DISCONNECT = "disconnect";
export const SOCKET_LASTPAGE = "Lastpage";
export const SOCKET_PROFILE = "Profile";
export const SOCKET_USERID_SOCKETID = "uid_sid";
export const SOCKET_STATUS = "Status";
export const SOCKET_TIMER_EVENT = "TimerEvent";
export const SOCKET_USER_CONFIG = "UserConfig";

// UserConfig
export const UC_TIMER_TABLE_ITEMS_PER_PAGE = "UC_TT_IPP";
export const UC_HEATING_TABLE_ITEMS_PER_PAGE = "UC_HT_IPP";
export const UC_ALARM_CONFIG_TABLE_ITEMS_PER_PAGE = "UC_ACT_IPP";
export const UC_ALARM_ACTIVATE_TABLE_ITEMS_PER_PAGE = "UC_AAT_IPP";



