import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import { Avatar, Box, Button } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import MyMenu from '../Menu/Menu';
import useStyles from './styles';

import { LOGOUT, USERCONFIG_FETCH } from '../../constants/actionTypes';
import * as CONST from '../../constants/general';
import decode from 'jwt-decode';
import { annonymousLogin, updateUserConfigInStore, createUserConfigInStore } from '../../actions/auth';
//import getBrowserFingerprint from 'get-browser-fingerprint';
import { socket } from '../../index';
import { updateElement } from '../../actions/knx';
import { updateAlert, createAlert } from '../../actions/alert';
import { updateAlarmElementStatus, setAlarmStatus } from '../../actions/alarm';
import { updateTimerEventInStore, createTimerEventInStore } from '../../actions/timerEvents';
import { getHeatingElements } from '../../actions/heating';

import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { red, orange } from '@mui/material/colors';
import jwt_decode from "jwt-decode";


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const MyAppBar = () => {
	const classes = useStyles();
	
	const [user, setUser] = useState( JSON.parse(localStorage.getItem('profile')));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	var elements = useSelector((state) => state.elements );
	var alarm = useSelector((state) => state.alarm );
	var alarmelements = useSelector((state) => state.alarmelements );
	var alerts = useSelector((state) => state.alerts );
	var heatings = useSelector((state) => state.heatings );
	var timerEvents = useSelector((state) => state.timerEvents );

	//var breadcrumbs = useSelector((state) => state.breadcrumbs );

	//console.log(user);

	let exist = socket.hasListeners( CONST.SOCKET_STATUS )
	if (exist) {
		socket.removeAllListeners( CONST.SOCKET_STATUS );
	} 
	
	socket.on( CONST.SOCKET_STATUS, update => {
		//console.log("Status update: " + JSON.stringify(update, null, 2) );
		updateState( update );
	});

	exist = socket.hasListeners( CONST.SOCKET_ALARM_ELEMENT )
	if (exist) {
		socket.removeAllListeners( CONST.SOCKET_ALARM_ELEMENT );
	} 

	socket.on( CONST.SOCKET_ALARM_ELEMENT, update => {
		//console.log("AlarmElement update: " + JSON.stringify(update, null, 2) );
		updateAEState( update );
	});

	exist = socket.hasListeners( CONST.SOCKET_HEATING )
	if (exist) {
		socket.removeAllListeners( CONST.SOCKET_HEATING );
	} 

	socket.on( CONST.SOCKET_HEATING, update => {
		//console.log("Heating update: " + JSON.stringify(update, null, 2) );
		updateHeatingState( update );
	});

	exist = socket.hasListeners( CONST.SOCKET_ALARM_CONFIG )
	if (exist) {
		socket.removeAllListeners( CONST.SOCKET_ALARM_CONFIG );
	} 

	socket.on( CONST.SOCKET_ALARM_CONFIG, update => {
		//console.log("AlarmConfig update: " + JSON.stringify(update, null, 2) );
		dispatch( setAlarmStatus( update ) );
	});

	exist = socket.hasListeners( CONST.SOCKET_ALERT )
	if (exist) {
		socket.removeAllListeners( CONST.SOCKET_ALERT );
	} 
	socket.on( CONST.SOCKET_ALERT, newalert => {
		let alert = alerts.find( o => o.title === newalert.title );
		if( alert !== undefined ) {
			dispatch(updateAlert( newalert ));
		} else {
			dispatch(createAlert( newalert ));
		}
	});

	exist = socket.hasListeners( CONST.SOCKET_TIMER_EVENT )
	if (exist) {
		socket.removeAllListeners( CONST.SOCKET_TIMER_EVENT );
	} 
	socket.on( CONST.SOCKET_TIMER_EVENT, newte => {
		let myte = timerEvents.find( o => o.title === newte.title );
		if( myte !== undefined ) {
			//console.log( "NewTimer: " + newte.nextDateTime );
			dispatch(updateTimerEventInStore( newte ));
		} else {
			dispatch(createTimerEventInStore( newte ));
		}
	});

	exist = socket.hasListeners( CONST.SOCKET_USER_CONFIG )
	if (exist) {
		socket.removeAllListeners( CONST.SOCKET_USER_CONFIG );
	} 
	socket.on( CONST.SOCKET_USER_CONFIG, newuc => {
		let myuc = timerEvents.find( o => o.name === newuc.name );
		if( myuc !== undefined ) {
			//console.log( "NewTimer: " + newte.nextDateTime );
			dispatch(updateUserConfigInStore( newuc ));
		} else {
			dispatch(createUserConfigInStore( newuc ));
		}
	});

	const logout = () => {
		dispatch({ type: LOGOUT});
		dispatch({ type: USERCONFIG_FETCH, payload: [] });
		setUser(null);
		socket.emit( CONST.SOCKET_USERID_SOCKETID, null );
		annonymousLogin();
		navigate('/');
	}

	useEffect(() => {
		//console.log ("App Bar - use effect fired");
		var token = null;
		if( user !== null ) {
			token = user.token;

			if ( token )
			{
				const decodedToken = decode( token );

				if(decodedToken.exp * 1000 < new Date().getTime()){
					logout();
				}
			}
		}

		//JWT
		setUser(JSON.parse(localStorage.getItem('profile')));

		if( isLoggedInUser === false ) {
			annonymousLogin();
		}

		/*
		if( user === null || user.result.email === undefined || user.result.email === "" ){
			annonymousLogin();
		}*/

		socket.emit( CONST.SOCKET_PROFILE, user);

	}, [location]);

	useEffect(() => {
		/* global google */
		google.accounts.id.initialize({
			client_id: "611355122459-10c8arqu3fdkee8kn37iso5hlge3ierm.apps.googleusercontent.com",
			callback: handleCallbackResponse
		})

		google.accounts.id.renderButton(
			document.getElementById("googleSignInDiv"),
			{ theme: "outline", size: "large" }
		);

	}, []);

	function handleCallbackResponse( response ) {
		console.log("Encoded JWT ID token: " + response.credential );
		var userObject = jwt_decode( response.credential );
		console.log( JSON.stringify( userObject, null , 2 ));
	}

	const updateState = ( update ) => {
		//console.log("elements updateState: " + JSON.stringify(update, null, 2) );
		//console.log("elements updateState: " + JSON.stringify(elements, null, 2) );

		let element = elements.find( o => o.knxid === update.knxid );
		//console.log("elements updateState before new val: " + JSON.stringify(element, null, 2) );

		element.value = update.value;
		if( update.value2 !== undefined ) {
			element.value2 = update.value2;
		}
		//console.log("elements updateState after: " + JSON.stringify(element, null, 2) );

		dispatch(updateElement( element ));
	}

	const updateAEState = ( update ) => {
		console.log("elements updateAEState: " + JSON.stringify(update, null, 2) );
		//console.log("alarmelements updateState: " + JSON.stringify(alarmelements, null, 2) );

		let ae = alarmelements.find( o => o.knxid === update.knxid );
		//console.log("elements updateAEState before new val: " + JSON.stringify(ae, null, 2) );

		if( ae !== undefined ) {
			ae.status = update.value;
			//console.log("elements updateAEState after: " + JSON.stringify(ae, null, 2) );
			dispatch(updateAlarmElementStatus( ae ));
		} else {
			console.log("elements updateAEState not found: ");
		}

	}
	
	const updateHeatingState = ( update ) => {
		//console.log("elements updateHeatingState: " + JSON.stringify(update, null, 2) );
		let check = false;
		let heating = heatings.find( o => o.knxid === update.knxid );
		//console.log("elements updateHeatingState before new val: " + JSON.stringify(heating, null, 2) );

		if( heating !== undefined && update !== undefined ) {
			if( update.curval !== undefined && update.curval !== null ) {
				if( heating.value !== update.curval ) {
					heating.value = update.curval;
					check = true;
				}
			}

			switch (update.type) {
				case CONST.HEATING_TOBE:
					if( heating.value2 !== update.value ) {
						heating.value2 = update.value;
						check = true;
					}
					break;
				case CONST.HEATING_TOBESTD:
					if( heating.toBeStd !== update.value ) {
						heating.toBeStd = update.value;
						check = true;
					}
					break;
				case CONST.HEATING_TOBEAWAY:
					if( heating.toBeAway !== update.value ) {
						heating.toBeAway = update.value;
						check = true;
					}
					break;
				default:
					console.log( "updateHeatingState - Unknown update type: " + update.type );
					break;
			}

			
			//console.log("elements updateAEState after: " + JSON.stringify(ae, null, 2) );
			if( check === true ) {
				//dispatch( updateHeating( heating ));
				dispatch( getHeatingElements() );
			} else {
				console.log( "updateHeatingState - no changes" );
			}
			
		} else {
			console.log("elements updateHeatingState not found: ");
		}

	}

	const isLoggedInUser = () => {
		//console.log( "isLoggedInUser: " + user )
		if( user !== null && user.result.name !== undefined && user.result.name !== "" ) {
			return true;
		}
		return false;
	}

	const getInitials = () => {
		let myname = user.result.name;
		//console.log( "getInitials for: " + user.result.name );
		return myname.slice(0, 1);
	}

	const getAlertIcon = () => {
		if ( alarm.fired === true ) {
			return (
				<NotificationsActiveOutlinedIcon sx={{ml: 1, color: red[500]}}/> )
		} else if ( alarm.alarmStatus === true ) {
			return (
				<NotificationsNoneOutlinedIcon sx={{ml: 1, color: orange[500]}}/> )
		} else {
			return (
				<NotificationsOffOutlinedIcon sx={{ml: 1}}/> )
		}
	}

	if( isLoggedInUser() === false && location.pathname !== '/auth' ) {
		navigate('/auth');
	}

    return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static">
				<Toolbar className={classes.toolbar1}>
					<Toolbar>
					<MyMenu />
					<div>
						<Typography className={classes.heading} component={Link} to="/"
							variant="h6"
							noWrap
							sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
						>
							Unser Haus
						</Typography>
					</div>
					</Toolbar>
					<Toolbar className={classes.toolbar2} >
						<Search sx={{mr: 1}}>
							<SearchIconWrapper>
								<SearchIcon />
							</SearchIconWrapper>
							<StyledInputBase
								placeholder="Search…"
								inputProps={{ 'aria-label': 'search' }}
							/>
						</Search>
						{ isLoggedInUser() ? (
							<div className={classes.profile}>
								<Avatar sx={{mr: 1}} alt={user.result.name} src={user.result.imageUrl}>{getInitials() }</Avatar>
								<Typography className={classes.heading} variant="h6" sx={{ mr: 1, mt: 0.5, flexGrow: 1, display: { xs: 'none', sm: 'block' } }} >{user.result.name} </Typography>
								<Button variant="contained" color="secondary" onClick={logout}>Abmelden</Button>
								</div>
						) : (
							<Button component={Link} to="/auth" variant="contained" color="primary">Anmelden</Button>
						)}
						{ getAlertIcon() }
					</Toolbar>
				</Toolbar>
			</AppBar>
		</Box>
    );
}


export default MyAppBar;