import React from 'react';
//import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider  } from '@mui/material/styles';
import myEIBTheme from './myEIBTheme.js';
import { getUserConfig } from './actions/auth';

import MyAppBar from './components/AppBar/AppBar';
import Elements from './components/Elements/Elements';
import Auth from './components/Login/Auth';
import AlertsPane from './components/AlertsPane/AlertsPane';
import Numpad from './components/Alarm/Numpad';

import { Snackbar, Alert } from '@mui/material';

//import useStyles from './styles';
//import Logger from './logger/logger';

const App = () => {

	const [snackOpen, setSnackOpen] = React.useState(false);
	const [snackText, setSnackText] = React.useState("");
	const [snackSeverity, setSnackSeverity] = React.useState("warning");

	const dispatch = useDispatch();
	
	dispatch( getUserConfig() );

	//const [loggerConf, setLoggerConf ] =  React.useState();

	/*
	if( !loggerConf ) {
		fetch("/json/logger.json")
			.then((res) => res.json())
			.then((data) => {
				setLoggerConf( data );
		});
		return <CircularProgress />
	}
	console.log( loggerConf );

	Logger.configure( loggerConf );
	//Logger.printConfig();
	const cl = Logger.getLogger( "elements" );
	*/
	
	const writeSnack = ( mymessage, severity ) => {
		setSnackText( mymessage );
		if( severity !== undefined ){
			setSnackSeverity( severity );
		} else {
			setSnackSeverity( "warning" );
		}
		setSnackOpen( true );
	}

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
	
		setSnackOpen(false);
	};
	
	return ( 
		<BrowserRouter>
			<ThemeProvider theme={myEIBTheme} >
				<div className="App" >
					<MyAppBar />
					<AlertsPane />
					<Routes>
						<Route path="/" element={<Elements writeSnack = { writeSnack } setSnackOpen = { setSnackOpen }/>} />
						<Route path="/auth" element={<Auth writeSnack = { writeSnack } />} />
						<Route path="/alarm" element={<Numpad />} />
					</Routes>
					<Snackbar open={ snackOpen} autoHideDuration={6000} onClose={ handleSnackClose }>
						<Alert onClose={handleSnackClose} severity={snackSeverity} sx={{ width: '100%' }}>
							{ snackText }
						</Alert>
					</Snackbar>
				</div>
			</ThemeProvider>
		</BrowserRouter>
	);
} 

export default App;

