import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import * as TEXTS from '../../constants/texts';

import EventOneTime from './EventTabs/eventOneTime';
import EventInterval from './EventTabs/eventInterval';
import EventDaily from './EventTabs/eventDaily';
import EventWeekdays from './EventTabs/eventWeekdays';
import EventMonthly from './EventTabs/eventMonthly';
import EventWeekdayInMonth from './EventTabs/eventWeekdayInMonth';
import EventYearly from './EventTabs/eventYearly';


import * as CONST from '../../constants/general';


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
		{value === index && (
			<Box sx={{ p: 3 }}>
				{children}
			</Box>
		)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

const getTabName = ( index ) => {
	//console.log( "EventTimerFromTabs - getTabName: " + index );
	let myret = "";
	
	if( index === 0 ) { myret = CONST.TIM_ONETIME };
	if( index === 1 ) { myret = CONST.TIM_INTERVAL };
	if( index === 2 ) { myret = CONST.TIM_DAILY };
	if( index === 3 ) { myret = CONST.TIM_WEEKDAYS };
	if( index === 4 ) { myret = CONST.TIM_DAYINMONTH };
	if( index === 5 ) { myret = CONST.TIM_WEEKDAYINMONTH };
	if( index === 6 ) { myret = CONST.TIM_YEARLY };

	//console.log( "EventTimerFromTabs - getTabName Return: " + myret);
	return myret;
}

const getTabIndex = ({ timerData }) => {
	
	//let myjson = JSON.parse(JSON.stringify(timerData));
	let myret;
	if( timerData === undefined ) {
		return 0;
	}


	switch ( timerData.frequency.type ) {
		case CONST.TIM_ONETIME:
			myret = 0;
			break;
		case CONST.TIM_INTERVAL:
			myret = 1;
			break;
		case CONST.TIM_DAILY:
			myret = 2;
			break;
		case CONST.TIM_WEEKDAYS:
			myret = 3;
			break;
		case CONST.TIM_DAYINMONTH:
			myret = 4;
			break;
		case CONST.TIM_WEEKDAYINMONTH:
			myret = 5;
			break;
		case CONST.TIM_YEARLY:
			myret = 6;
			break;
		default:
			myret = 0;
			break;
	}
	//console.log( "EventTimerFromTabs - getTabIndex Return: " + myret);
	return myret;
}

export default function EventTimerFormTabs({ timerData, setTimerData }) {
	//const [value, setValue] = React.useState( getTabIndex({ timerData }) );
	
	const handleChange = (event, newValue) => {
		//setValue(newValue);
		setTimerData({  ...timerData, frequency: { 
							...timerData.frequency, type: getTabName( newValue ) }})
	};

	const handleChangeChoice = (event, newValue) => {
		//setValue(newValue);
		setTimerData({  ...timerData, frequency: { 
							...timerData.frequency, type: getTabName( event.target.value ) }})
		
	};

	return (
		<Grid minHeight={200} container>
			<Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }} >
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={getTabIndex({ timerData })}
					onChange={handleChange}
					aria-label="Vertical tabs"
					sx={{ borderRight: 1, borderColor: 'divider' }}
				>
					<Tab label={ TEXTS.keyMap( "onetime", TEXTS.txtTIMERTYPES ) } {...a11yProps(0)} />
					<Tab label={ TEXTS.keyMap( "interval", TEXTS.txtTIMERTYPES ) } {...a11yProps(1)} />
					<Tab label={ TEXTS.keyMap( "daily", TEXTS.txtTIMERTYPES )  } {...a11yProps(2)} />
					<Tab label={ TEXTS.keyMap( "weekdays", TEXTS.txtTIMERTYPES )  } {...a11yProps(3)} />
					<Tab label={ TEXTS.keyMap( "dayInMonth", TEXTS.txtTIMERTYPES )  } {...a11yProps(4)} />
					<Tab label={ TEXTS.keyMap( "weekdayInMonth", TEXTS.txtTIMERTYPES )  } {...a11yProps(5)} />
					<Tab label={ TEXTS.keyMap( "yearly", TEXTS.txtTIMERTYPES )  } {...a11yProps(6)} />
				</Tabs>
			</Grid>
			<Grid item xs={11} sx={{ display: { xs: 'block', sm: 'none' } }} >
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label">Timer Typ</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={ getTabIndex({ timerData }) }
						label="Timer Typ"
						onChange={handleChangeChoice}
						>
						<MenuItem value={0}>{ TEXTS.keyMap( "onetime", TEXTS.txtTIMERTYPES ) }</MenuItem>
						<MenuItem value={1}>{ TEXTS.keyMap( "interval", TEXTS.txtTIMERTYPES ) }</MenuItem>
						<MenuItem value={2}>{ TEXTS.keyMap( "daily", TEXTS.txtTIMERTYPES ) }</MenuItem>
						<MenuItem value={3}>{ TEXTS.keyMap( "weekdays", TEXTS.txtTIMERTYPES ) }</MenuItem>
						<MenuItem value={4}>{ TEXTS.keyMap( "dayInMonth", TEXTS.txtTIMERTYPES ) }</MenuItem>
						<MenuItem value={5}>{ TEXTS.keyMap( "weekdayInMonth", TEXTS.txtTIMERTYPES ) }</MenuItem>
						<MenuItem value={6}>{ TEXTS.keyMap( "yearly", TEXTS.txtTIMERTYPES ) }</MenuItem>
					</Select>
				</FormControl>

			</Grid>
			<Grid item xs={12} sm={8}  >
				<TabPanel value={getTabIndex({ timerData })} index={0}>
					<EventOneTime timerData={timerData} setTimerData={setTimerData} />
				</TabPanel>
				<TabPanel value={getTabIndex({ timerData })} index={1}>
					<EventInterval timerData={timerData} setTimerData={setTimerData} />
				</TabPanel>
				<TabPanel value={getTabIndex({ timerData })} index={2}>
					<EventDaily timerData={timerData} setTimerData={setTimerData} />
				</TabPanel>
				<TabPanel value={getTabIndex({ timerData })} index={3}>
					<EventWeekdays timerData={timerData} setTimerData={setTimerData} />
				</TabPanel>
				<TabPanel value={getTabIndex({ timerData })} index={4}>
					<EventMonthly timerData={timerData} setTimerData={setTimerData} />
				</TabPanel>
				<TabPanel value={getTabIndex({ timerData })} index={5}>
					<EventWeekdayInMonth timerData={timerData} setTimerData={setTimerData} />
				</TabPanel>
				<TabPanel value={getTabIndex({ timerData })} index={6}>
					<EventYearly timerData={timerData} setTimerData={setTimerData} />
				</TabPanel>
				</Grid>
		</Grid>
		
	);
}
