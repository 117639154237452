import React from "react";
import { useSelector } from 'react-redux';
import { Box, Typography, Chip, Grid, Card, CardMedia, CardContent, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';

import './element.css';
import useStyles from './styles';
import { STYLE_DESKTOP, STYLE_MOBILE, STYLE_CONTROL } from '../../../constants/general.js';
import { temperatureUpOrDown } from '../../../actions/knx.js';

const Heating = ({ knxid, writeSnack }) => {
	//console.log("Heating: " + JSON.stringify(element));
	const classes = useStyles();
	
	var element = useSelector((state) => state.elements.find(( elem ) => {
		//console.log ("useSelector: " + elem.knxid );
		return elem.knxid === knxid; }));
	if ( !element ){
		return <CircularProgress />
	}

	var myImage = "images/heating.png";
	if ( element.image !== undefined && element.image !== "" ) {
		myImage = element.image;
	}

	const temperatureUp = async ( e, element ) => {
		let myret = await temperatureUpOrDown( e, element, 0.5 );
		//console.log("Value fro myRet: " + myret);
		if( myret !== undefined ) {
			writeSnack( myret );
		}
	}

	const temperatureDown = async ( e, element ) => {
		let myret = await temperatureUpOrDown( e, element, -0.5 );
		//console.log("Value fro myRet: " + myret);
		if( myret !== undefined ) {
			writeSnack( myret );
		}
	}
	
	const createControlElement = ( element, type ) => {
		if( type === STYLE_DESKTOP ) {
			return (
				<Grid container padding={0} spacing={0} alignItems="center" justifyContent="center">
					<Grid item sx={{mt:-1, ml:-0}}>
						<IconButton key={element.knxid + "_up"} 
									className={classes.rollerbutton} 
									size="large" 
									color="primary" 
									onClick={(e) => { temperatureUp( e, element )}} >
							<ArrowCircleUpOutlinedIcon />
						</IconButton>
					</Grid>
					<Grid item sx={{mt:-1,ml:0}}>
						<Typography key={element.knxid + "_setval"} variant="subtitle2" >
							{ Math.round(element.value2 * 10) / 10 + " °C"} 
						</Typography>
					</Grid>
					<Grid item sx={{mt:-1,ml:0}}>
						<IconButton key={element.knxid + "_down"} 
									className={classes.rollerbutton} 
									size="large" 
									color="primary" 
									onClick={(e) => { temperatureDown( e, element )}} >
							<ArrowCircleDownOutlinedIcon />
						</IconButton>
					</Grid>
					<Grid item sx={{mt:-1}}>
						&nbsp;&nbsp;
					</Grid>
					<Grid item sx={{mt:-1, ml:2}}>
						<Chip id={element.knxid} 
							label={Math.round(element.value * 100) / 100 + " °C"} 
							variant="outlined" 
							color="primary"
							size="small" />
					</Grid>
				</Grid>
			)
		} else {
			return (
				<Grid container padding={0} spacing={0} alignItems="center" justifyContent="center">
					<Grid item sx={{mt:-1, ml:0}}>
						<IconButton key={element.knxid + "_up"} 
									className={classes.rollerbutton} 
									size="large" 
									color="primary" 
									onClick={(e) => { temperatureUp( e, element )}} >
							<ArrowCircleUpOutlinedIcon />
						</IconButton>
					</Grid>
					<Grid item sx={{mt:-0.8,ml:-0.5}}>
						<Typography key={element.knxid + "_setval"} variant="subtitle2" >
							{ Math.round(element.value2 * 10) / 10 + " °C"} 
						</Typography>
					</Grid>
					<Grid item sx={{mt:-1,ml:-0.7}}>
						<IconButton key={element.knxid + "_down"} 
									className={classes.rollerbutton} 
									size="large" 
									color="primary" 
									onClick={(e) => { temperatureDown( e, element )}} >
							<ArrowCircleDownOutlinedIcon />
						</IconButton>
					</Grid>
					<Grid item sx={{mt:-1.0, ml:0}}>
						<Chip id={element.knxid} 
							label={Math.round(element.value * 100) / 100 + " °C"} 
							variant="outlined" 
							color="primary"
							size="small" />
					</Grid>
				</Grid>
			)
		}
	}

	const createChip = ( element, type ) => {
		return (
			<Grid item sx={{mt:-2}}>
				<Chip id={element.knxid} 
					label={Math.round(element.value * 100) / 100 + " °C"} 
					variant="outlined" 
					color="primary" />
			</Grid>
		)
	}


	const createControls = ( element, type ) => {
		if( element.visuStyle === STYLE_CONTROL ) {
			return (
				<Grid container padding={0} spacing={1} alignItems="center" justifyContent="center">
				{
					createControlElement( element, type )
				}
				</Grid>
			)
		} else {
			return (
				<Grid container padding={1} spacing={4} alignItems="center" justifyContent="center">
				{
					createChip( element, type )
				}
				</Grid>
			)
		}
	}

	return (
		<Box>
			<Card className={classes.card} sx={{ display: { xs: 'none', sm: 'block' } }}>
				<CardMedia component="img" height="60" image={ myImage } />
					<div className={classes.overlay}>
						<Typography variant="h6">{ element.label }</Typography>
					</div>
					<div className={classes.overlay3}><DeviceThermostatIcon /></div>
					<CardContent sx={{ ml: -2, mr: -2, mt: 0, mb: -2}}>
					<Box >
						{
							createControls( element, STYLE_DESKTOP )
						}
					
					</Box>
				</CardContent >
			</Card > 
			<Card className={classes.card} sx={{ display: { xs: 'block', sm: 'none' } }} >
				<Grid container>
					<Grid item xs={5}>
						<CardMedia component="img" height="60" image={ myImage } />
							<div className={classes.overlay}>
								<Typography variant="h6">{ element.label }</Typography>
							</div>
					</Grid>
					<Grid item xs={7}>		
						<CardContent sx={{ ml: -3, mr: -2, mt: 0.5, mb: -2}}>
							<Box >
								{
									createControls( element, STYLE_MOBILE )
								}
							</Box>
						</CardContent >
					</Grid>
				</Grid>
			</Card > 
		</Box>
	);
}


export default Heating;