import { TIMER_ACTION_SCENE_ITEMS } from '../constants/actionTypes';

const timerActionSceneItems = function ( timerActionSceneItems = [], action ) {
	switch ( action.type ) {
		case TIMER_ACTION_SCENE_ITEMS:
			return action.payload;
		default:
			return timerActionSceneItems;
	}
};

export default timerActionSceneItems;

