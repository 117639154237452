import { USERCONFIG_FETCH, USERCONFIG_UPDATE, USERCONFIG_CREATE } from '../constants/actionTypes';

const userConfigs = function ( userConfigs = [], action) {
	switch (action.type) {
		case USERCONFIG_FETCH:
			return action.payload;
		case USERCONFIG_UPDATE:
			return userConfigs.map(( uc ) => uc._id === action.payload._id ? action.payload : uc );
		case USERCONFIG_CREATE:
			return [ ...userConfigs, action.payload ];
		default:
			return userConfigs;
	}
};

export default userConfigs;