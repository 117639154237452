import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Container, Grid, Box } from '@mui/material';
import Breadcrumb from '../Elements/Element/Breadcrumb';
import AlarmChangePIN from './AlarmChangePIN';
import AlarmConfig from './AlarmConfig';
import AlarmActivate from './AlarmActivate';

import * as CONST from '../../constants/general.js';
import { getAlarmElements } from '../../actions/alarm';

const AlarmPages = ({ majortype, writeSnack }) => {

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch( getAlarmElements() );
	}, [dispatch]);	

	console.log( "AlarmPages majortype :" + JSON.stringify( majortype ))
	let myMT = majortype;

	const getSubPage = ( myMT ) => {
		console.log ( "getSubPage majortype: " + myMT )
		switch ( myMT ) {
			case CONST.ALARMACTIVATEPAGE:
				return ( <AlarmActivate writeSnack={writeSnack} /> )
			case CONST.ALARMDEACTIVATEPAGE:
				return (
					<Grid item xs={12} sx={{ mt:-3.5, mb:1.5 }}>
						huhu
					</Grid>
				)
			case CONST.ALARMCONFIGUREPAGE:
				return ( <AlarmConfig writeSnack={writeSnack} /> )	
			case CONST.ALARMCHANGEPINPAGE:	
				return ( <AlarmChangePIN writeSnack={writeSnack} />	)
			default:
				console.error ( "Unknow type - no page created: " )
				break;
		}
	}

	return (
		<Container sx={{marginY: 5}}>
			<Box>
				<Grid container>
					<Grid item xs={12} sx={{ mt:-3.5, mb:1.5 }}>
						<Breadcrumb/>
					</Grid>
				</Grid>
				{ getSubPage( myMT ) }
			</Box>
		</Container> 
	)
}

export default AlarmPages;