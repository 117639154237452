import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateUserConfig, getUserConfigValue } from '../../actions/auth';
import * as CONST from "../../constants/general";


const dense = true;

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) { return -1; }
	if (b[orderBy] > a[orderBy]) { return 1; }
	return 0;
}

function getComparator(order, orderBy) {
	let myval;
	if( order === 'desc' ) {
		myval = (a, b) => descendingComparator(a, b, orderBy);
	} else { 
		myval = (a, b) => -descendingComparator(a, b, orderBy);
	}
	return myval;
}

const headCells = [
  { id: 'area', aligncenter: false, disablePadding: true, label: 'Bereich', },
  { id: 'room', aligncenter: false, disablePadding: false, label: 'Raum', },
  { id: 'label', aligncenter: false, disablePadding: false, label: 'Name', },
  { id: 'type', aligncenter: false, disablePadding: false, label: 'Art', },
  { id: 'status', aligncenter: false, disablePadding: false, label: 'Status', },
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } =
		props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};


	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					Aktiv
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.aligncenter ? 'center' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
					alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),}),
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: '1 1 100%' }}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (
			<Typography
				sx={{ flex: '1 1 100%' }}
				variant="h6"
				id="tableTitle"
				component="div"
			>
				Offene Fenster oder andere Alarm Sensoren
			</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton>
					<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton>
					<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
  	numSelected: PropTypes.number.isRequired,
};

export default function AlarmActivateTable({ writeSnack }) {
	const userConfigs = useSelector((state) => state.userConfigs );
	let uc_rows = getUserConfigValue( userConfigs, CONST.UC_ALARM_ACTIVATE_TABLE_ITEMS_PER_PAGE, 10, true );
	
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('area');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState( uc_rows );

	const dispatch = useDispatch();

	const alarmElements = useSelector((state) => state.alarmelements );

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		let newrows = parseInt(event.target.value, 10);
		setRowsPerPage( newrows );
		setPage(0);
		dispatch( updateUserConfig( CONST.UC_ALARM_ACTIVATE_TABLE_ITEMS_PER_PAGE, newrows ));
	};

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - alarmElements.length) : 0;

	const maptype = ( type ) => {
		switch (type) {
			case "Contact":
				return "Fensterkontakt"
			default:
				return type;
		}
	}

	const mapstatus = ( element ) => {
		switch (element.type) {
			case "Contact":
				if( element.status === 1 ) {
					return "Offen";
				} else {
					return "Geschlossen";
				}
			default:
				return element.status;
		}
	}

	/*
	 * Filter and sort Alarm Elements 
	 */
	const isRelevantAE = ( element ) => {
		switch (element.type) {
			case "Contact":
				if( element.status === 1 ) {
					return true; }
				return false;
			default:
				return true;
		}
	}

	function alarmElementsSort( a, b ) {
		if ( a.active < b.active ){
		  return 1;
		}
		if ( a.active > b.active ){
		  return -1;
		}
		return 0;
	}

	const filteredAE = alarmElements.filter( element => isRelevantAE( element ) );
	filteredAE.sort( alarmElementsSort );
	

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar numSelected={0} />
				<TableContainer>
					<Table
						sx={{ minWidth: 600 }}
						aria-labelledby="tableTitle"
						size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							numSelected={0}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={ filteredAE.length }
						/>
						<TableBody>
							{
								
								filteredAE.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort(getComparator(order, orderBy))
									.map((alarmElement, index) => {
									
									let isActive = false;
									if( alarmElement.active === true ) { isActive = true }

									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											role="checkbox"
											aria-checked={isActive}
											tabIndex={-1}
											key={alarmElement.knxid}
											selected={isActive}
										>
											
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isActive}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell>
											<TableCell
												align="left"
												component="th"
												id={alarmElement.knxid}
												scope="row"
												padding="none"
											>
												{ alarmElement.area }
											</TableCell>
											<TableCell align="left">{ alarmElement.room }</TableCell>
											<TableCell align="left">{ alarmElement.label }</TableCell>
											<TableCell align="left">{ maptype( alarmElement.type ) }</TableCell>
											<TableCell align="left">{ mapstatus( alarmElement ) }</TableCell>
										</TableRow>
									);
								})
							}
							{emptyRows > 0 && (
								<TableRow
								style={{
									height: (dense ? 33 : 53) * emptyRows,
								}}
								>
								<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={ filteredAE.length }
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
}
