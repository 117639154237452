import { makeStyles } from '@mui/styles';

//export default makeStyles((theme) => ({

export default makeStyles(() => ({
  paper: {
    //marginTop: theme.spacing(8),
    marginTop: 64,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //padding: theme.spacing(2),
    padding: 16,
  },
  root: {
    '& .MuiTextField-root': {
      //margin: theme.spacing(1),
      margin: 8,
    },
  },
  avatar: {
    //margin: theme.spacing(1),
    margin: 8,
    //backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    //marginTop: theme.spacing(3),
    marginTop: 24,
  },
  submit: {
    marginTop: 24,
    marginBottom: 16,
    //margin: theme.spacing(3, 0, 2),
  },
  googleButton: {
    //marginBottom: theme.spacing(2),
    marginBottom: 16,
  },
}));