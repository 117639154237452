import { MENU_FETCH } from '../constants/actionTypes';

const menues = function ( menues = [], action ) {
	switch ( action.type ) {
		case MENU_FETCH:
			return action.payload;
		default:
			return menues;
	}
};

export default menues;
