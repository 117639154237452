import { keyMap, txtOBJECTTYPES, txtACTIONTYPES } from '../constants/texts';
import * as CONST from '../constants/general';

function AnyActionClient ( knxid_in, subtype_in, value_in ) {
	this.id = 'Runtime action';
	if( knxid_in !== undefined ) { this.knxid = knxid_in; } else { this.knxid = ''; }

	this.type = 'action';

	this.object = 'Light';
	this.areaId = '';
	this.areaLabel = '';
	this.roomId = '';
	this.roomLabel = '';
	this.controlLabel = '';

	if( subtype_in !== undefined ) { this.subType = subtype_in; } else { this.subType = 'switch'; }
		// switch, switchOn, switchOff, dim
		// rollershutterUp, rollershutterDown, rollershutterStop, driveRollerToPos
		// switchScene
		// setVariable, Pushover, AlertApp

	if( value_in !== undefined ) { this.value = value_in; } else { this.value = ''; }
	
	this.variable = '';			// Variable
	this.varExpires = '';		// Variable
	
	this.title = '';			// Pushover & AlertApp & Workflow
	this.recipients = [];		// Pushover
	this.devices = [];			// Pushover
	this.sound = '';			// Pushover

	this.message = '';			// Pushover & AlertApp
	this.severity = '';			// AlertApp

};


export const getDescLine1 = ( myAnyAction ) => {
	//console.log ("getDescLine1: ");
	let myret;

	switch (myAnyAction.object ) {
		case CONST.BIND_PUSHOVER:
		case CONST.VARIABLE:
		case CONST.WORKFLOW:
		case CONST.ALERTAPP:
			myret = `${keyMap( myAnyAction.object, txtOBJECTTYPES)} ${keyMap( myAnyAction.subType, txtACTIONTYPES)}`
			break;
		default:
			myret = `${keyMap( myAnyAction.object, txtOBJECTTYPES)} ${keyMap( myAnyAction.subType, txtACTIONTYPES)} von ${ myAnyAction.controlLabel }`
			break;
	}

	//console.log ("getDescLine1 return: " +  myret);
	return myret;
}

export const getDescLine2 = ( myAnyAction ) => {
	//console.log ("getDescLine2: ");
	let myret;

	switch (myAnyAction.object ) {
		case CONST.BIND_PUSHOVER:
			myret = `Titel: "${myAnyAction.title}" an: "${myAnyAction.recipients}"`
			break;
		case CONST.VARIABLE:
			myret = `Variable: "${myAnyAction.variable}" Wert: "${myAnyAction.value}"`
			break;
		case CONST.WORKFLOW:
			myret = `Workflow: "${myAnyAction.title}"`
			break;
		case CONST.ALERTAPP:
			myret = `Nachricht: "${myAnyAction.message}"`
			break;
		default:
			myret = `Im Raum: ${myAnyAction.areaLabel} - ${myAnyAction.roomLabel}`
			break;
	}

	//console.log ("getDescLine2 return: " +  myret);
	return myret;
}
	

export default AnyActionClient;	
