import { ALERT_CREATE, ALERT_DELETE, ALERT_UPDATE, ALERT_CREATE_UPDATE } from '../constants/actionTypes';


export const ALERT_ERROR = "error";
export const ALERT_WARNING = "warning";
export const ALERT_INFO = "info";
export const ALERT_SUCCESS = "success";


export const updateAlert = ( newalert ) => async (dispatch) => {
	console.log("updateAlert ");
	//console.log("updateAlert: " + JSON.stringify(newalert, null, 2) );

	try {
		//console.log("updateAlert alert before: " + JSON.stringify(alert, null, 2) );
		/*
		alert.message = newalert.message;
		alert.time = newalert.time;
		alert.severity = newalert.severity;
		*/
		//console.log("updateAlert alert after: " + JSON.stringify(alert, null, 2) );
		dispatch ({ type: ALERT_UPDATE, payload: newalert });
		//console.log("updateAlert alert dispatch: " );
	} catch ( error ) {
		console.log(error);
	}
}

export const createOrUpdateAlert = ( newalert ) => async (dispatch) => {
	console.log("createOrUpdateAlert ");
	//console.log("createOrUpdateAlert: " + JSON.stringify(newalert, null, 2) );

	try {
		//console.log("createOrUpdateAlert - Write new alert in store: " + JSON.stringify(newalert, null, 2) );
		dispatch ({ type: ALERT_CREATE_UPDATE, payload: newalert });
		//console.log("createOrUpdateAlert alert dispatch: " );
	} catch ( error ) {
		console.log(error);
	}
}

export const createAlert = ( newalert ) => async (dispatch) => {
	console.log("createAlert ");
	//console.log("createAlert: " + JSON.stringify(newalert, null, 2) );

	try {
		//console.log("updateAlert - Write new alert in store: " + JSON.stringify(newalert, null, 2) );
		dispatch ({ type: ALERT_CREATE, payload: newalert });
		//console.log("updateAlert alert dispatch: " );
	} catch ( error ) {
		console.log(error);
	}
}

export const dismissAlert = ( oldalert ) => async (dispatch) => {
	console.log("dismissAlert ");
	//console.log("dismissAlert: " + JSON.stringify(oldalert, null, 2) );

	try {
		console.log("dismissAlert - Write new alert in store: " + JSON.stringify(oldalert, null, 2) );
		dispatch ({ type: ALERT_DELETE, payload: oldalert.title });
		console.log("dismissAlert alert dispatch: " );
	} catch ( error ) {
		console.log(error);
	}
}

export const createOrUpdateAlertFomValues = ( titel, message, severity ) => {
	let myalert = createAlertObject( titel, message, severity );
	console.log("createOrUpdateAlertFomValues " );
	createOrUpdateAlert( myalert );
}

export const createAlertObject = ( titel, message, severity ) => {
	
	if( severity !== ALERT_ERROR && 
			severity !== ALERT_WARNING && 
			severity !== ALERT_INFO && 
			severity !== ALERT_SUCCESS ) {
		severity = ALERT_INFO;
	}

	let myalert = { "title": titel, 
					"message": message, 
					"severity": severity };

	return myalert;
}
