import { makeStyles } from '@mui/styles';

export default makeStyles({
  
  /*
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundBlendMode: 'darken',
  },
  overlay2: {
    position: 'absolute',
    right: '10px',
    color: 'primary',
  },
  grid: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
  },
  cardActions: {
    padding: '0 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  */
  border: {
    border: 'solid',
  },
  fullHeightCard: {
    height: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '20px',
    height: '100%',
    position: 'relative', 
  },
  overlay: {
    position: 'absolute',
    top: '15px',
    left: '15px',
    color: 'white',
  },
  overlay3: {
    position: 'absolute',
    top: '20px',
    right: '15px',
    color: 'white',
  },
  overlay4: {
    position: 'absolute',
    top: '20px',
    right: '15px',
    color: 'primary',
  },
  overlay2: {
    padding: '4px 16px',
  },
  img: {
    width: '100%',
    height: '3rem',
  },
  title: {
    padding: '0 16px',
    color: 'primary'
  },
  dimmerSwitch: {
    
  },
  dimmerSlider:{
    marginTop: '5px',
  },
  rollerbutton:{
    size: 'large',
  }


});
