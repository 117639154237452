export const ALARM_ERROR_CHANGEPIN_GENERAL = { "title": "Fehler", 
						"message": "Allgemeiner Fehler - bitte fangen Sie nochmal von vorne an.", 
						"severity": "error" };

export const ALARM_ERROR_CHANGEPIN_NEWDIFFERENT = { "title": "Fehler", 
						"message": "Neue PINs stimmen nicht überein!", 
						"severity": "error" };

export const ALARM_CHANGEPIN_SUCCESS = { "title": "Erfolgreich", 
						"message": "PIN wurde geändert.", 
						"severity": "success" };