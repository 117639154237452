import React from "react";
import { useSelector } from 'react-redux';
import { Box, Typography, Chip, Grid, Card, CardMedia, CardContent, CircularProgress } from '@mui/material';
import WindowIcon from '@mui/icons-material/Window';

import './element.css';
import useStyles from './styles';

const Window = ({ knxid }) => { 
	//console.log( "Window: " + knxid );
	const classes = useStyles();

	var element = useSelector((state) => state.elements.find(( elem ) => {
		//console.log ("useSelector: " + elem.knxid );
		return elem.knxid === knxid; }));
	if ( !element ){
		return <CircularProgress />
	}
	
	var myVal0 = "Geschlossen";
	var myVal1 = "Offen";

	if ( element.mapping !== undefined && element.mapping.length > 0 ) {
		for ( let i = 0; i < element.mapping.length; i++ ) {
			let map = element.mapping[i];

			if( map.key === "0" ) { myVal0 = map.value;	}
			if( map.key === "1" ) {	myVal1 = map.value;	}
		}
	}

	var myImage = "images/window_dark.png";
	if ( element.image !== undefined && element.image !== "" ) {
		myImage = element.image;
	}

	const createWindowState = ( element ) => {
		let chiplabel;
		let chipcolor;

		if( element.value === 1 ) {
			chiplabel = myVal1;
			chipcolor = "warning";
		} else {
			chiplabel = myVal0;
			chipcolor = "primary";
		}
		return ( <Chip id={element.knxid} 
					label={chiplabel} 
					variant="outlined" 
					color={chipcolor}
					
					fontFamily={'Arial'} />)
	}

	return (
		<Box>
			<Card className={classes.card} sx={{ display: { xs: 'none', sm: 'block' } }}>
				<CardMedia component="img" height="60" image={ myImage } />
					<div className={classes.overlay}>
						<Typography variant="h6">{ element.label }</Typography>
					</div>
					<div className={classes.overlay3}><WindowIcon /></div>
					<CardContent sx={{ ml: -2, mr: -2, mt: 0, mb: -2}}>
					<Box >
						<Grid container padding={1} spacing={4} alignItems="center" justifyContent="center" >
							<Grid item sx={{mt:-2}}>
								{ createWindowState( element ) }
							</Grid>
						</Grid>
					</Box>
				</CardContent >
			</Card > 
			<Card className={classes.card} sx={{ display: { xs: 'block', sm: 'none' } }} >
				<Grid container>
					<Grid item xs={5}>
						<CardMedia component="img" height="60" image={ myImage } sx={{ ml: 0, mr: 0, mt: 0, mb: 0}} />
							<div className={classes.overlay}>
								<Typography variant="h6">{ element.label }</Typography>
							</div>
							<div className={classes.overlay4}><WindowIcon /></div>
					</Grid>
					<Grid item xs={7}>
						<CardContent sx={{ ml: -3, mr: -2, mt: 0.75, mb: -2}}>
							<Box >
								<Grid container padding={0} spacing={4} alignItems="center" justifyContent="center" >
									<Grid item sx={{mt:-1}}>
										{ createWindowState( element ) }
									</Grid>
								</Grid>
							</Box>
						</CardContent >
					</Grid>
				</Grid>
			</Card > 
		</Box>
	);
}

export default Window;