
export const txtTIMERTYPES = [
	{ "key":"onetime","value": "Termin" },
	{ "key":"interval","value": "Timer" },
	{ "key":"daily","value": "Täglich" },
	{ "key":"weekdays","value": "Wochentage" },
	{ "key":"dayInMonth","value": "Monatlich" },
	{ "key":"weekdayInMonth","value": "Tag im Monat" },
	{ "key":"yearly","value": "Jährlich" }];

export const txtOBJECTTYPES = [
	{ "key":"Light","value": "Licht" },
	{ "key":"Rollershutter","value": "Rollo" },
	{ "key":"Plug","value": "Steckdose" },
	{ "key":"Scene","value": "Szene und Lüftung" },
	{ "key":"PUSHOVER","value": "Pushnachricht" },
	{ "key":"AlertApp","value": "Appnachricht" },
	{ "key":"Workflow","value": "Workflow" }];

export const txtACTIONTYPES = [
	{ "key":"switch","value": "schalten" },
	{ "key":"switchOn","value": "einschalten" },
	{ "key":"switchOff","value": "ausschalten" },
	{ "key":"dim","value": "dimmen" },
	{ "key":"rollershutterUp","value": "hochfahren" },
	{ "key":"rollershutterDown","value": "runterfahren" },
	{ "key":"rollershutterStop","value": "anhalten" },
	{ "key":"rollershutterDown","value": "runterfahren" },
	{ "key":"driveRollerToPos","value": "auf Position fahren" },
	{ "key":"switchScene","value": "auswählen" },
	{ "key":"Pushover","value": "senden" },
	{ "key":"AlertApp","value": "anzeigen" },
	{ "key":"Workflow","value": "starten"}];

export const txtSEVERITY = [
	{"key":"error","value":"Fehler"},
	{"key":"warning","value":"Warnung"},
	{"key":"info","value":"Info"},
	{"key":"success","value":"Erfolg"}];


export const keyMap = ( key, mymap ) => {
	for( let i = 0; i < mymap.length; i++ ) {
		if( mymap[i].key === key ) {
			return mymap[i].value;
		}
	}
	console.log ("keyMap - not found");
	return "#key - " + key;
}

export const valueMap = ( val, mymap ) => {
	for( let i = 0; i < mymap.length; i++ ) {
		if( mymap[i].value === val ) {
			return mymap[i].key;
		}
	}
	console.log ("valueMap - not found");
	return "#val - " + val;
}

