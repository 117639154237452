import { TIMER_FETCH_ALL, TIMER_UPDATE, TIMER_DELETE, TIMER_CREATE, TIMER_ACTION_SCENE_ITEMS,
	TIMER_ACTION_SELECTION, TIMER_ACTION_PUSHOVER, TIMER_ACTION_WORKFLOW } from '../constants/actionTypes';

//import { ActionTypes } from '@mui/base';
import * as api from '../api/index.js';

//Action Creators
export const getTimerEvents = () => async (dispatch) => {
	try {
		const { data } = await api.fetchTimerEvents();
		dispatch ({ type: TIMER_FETCH_ALL, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const createTimerEvent = (timerEvent, snack) => async (dispatch) => {
	try {
		//const { data } = await api.createTimerEvent( timerEvent );
		const { status, data } = await api.createTimerEvent( timerEvent );
		//await api.createTimerEvent( timerEvent );
		//dispatch ({ type: CREATE, payload: data }); //This is not multi user proof

		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
			snack( data.message, "warning" );
		} else {
			dispatch( getTimerEvents() );
			snack( "Timer erstellt", "success" );
		}
	} catch (error) {
		snack( error.message, "warning" );
		console.log(error);
		snack( error );
	}
} 

export const updateTimerEvent = (id, timerEvent, snack) => async (dispatch) => {
	try {
		const { status, data } = await api.updateTimerEvent( id, timerEvent );

		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
			snack( data.message, "warning" );
		} else {
			dispatch ({ type: TIMER_UPDATE, payload: data }); //This is not multi user proof
			snack( "Timer erfolgreich geändert", "success" );
		}
	} catch (error) {
		console.log(error);
		snack( error );
	}
} 

export const createTimerEventInStore = ( timerEvent) => async (dispatch) => {
	try {
		dispatch( { type: TIMER_CREATE, payload: timerEvent } );
	} catch (error) {
		console.log(error);
	}
} 

export const updateTimerEventInStore = ( timerEvent ) => async (dispatch) => {
	try {
		dispatch ({ type: TIMER_UPDATE, payload: timerEvent }); //This is not multi user proof
	} catch (error) {
		console.log(error);
	}
} 


export const deleteTimerEvent = (id, snack) => async (dispatch) => {
	try {
		const { status, data } = await api.deleteTimerEvent( id );

		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
			snack( data.message, "warning" );
		} else {
			dispatch ({ type: TIMER_DELETE, payload: id }); 
			snack( "Timer gelöscht", "success" );
		}
	} catch (error) {
		console.log(error);
		snack( error );
	}
} 

export const getTimerActionSelection = () => async (dispatch) => {
	try {
		const { data } = await api.fetchTimerActionSelection();
		dispatch ({ type: TIMER_ACTION_SELECTION, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const getTimerPushoverCfg = () => async (dispatch) => {
	console.log( "getTimerPushoverCfg" );
	try {
		const { data } = await api.fetchTimerPushoverCfg();
		dispatch ({ type: TIMER_ACTION_PUSHOVER, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const getTimerActionSceneItems = () => async (dispatch) => {
	try {
		const { data } = await api.fetchTimerActionSceneItems();
		dispatch ({ type: TIMER_ACTION_SCENE_ITEMS, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const getTimerActionWorkflowNames = () => async (dispatch) => {
	try {
		const { data } = await api.fetchTimerActionWorkflowNames();
		dispatch ({ type: TIMER_ACTION_WORKFLOW, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

