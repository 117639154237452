import { ALERT_CREATE, ALERT_UPDATE, ALERT_DELETE, ALERT_FETCH_ALL, ALERT_CREATE_UPDATE } from '../constants/actionTypes';

const alerts = function ( alerts = [], action ) {
	switch ( action.type ) {
		case ALERT_DELETE:
			return alerts.filter((alert) => alert.title !== action.payload);
		case ALERT_UPDATE:
			return alerts.map((alert) => alert.title === action.payload.title ? action.payload : alert );
		case ALERT_FETCH_ALL:
			return action.payload;
		case ALERT_CREATE:
			return [ ...alerts, action.payload ];
		case ALERT_CREATE_UPDATE: {
			let myalert = alerts.filter((alert) => alert.title === action.payload.title);
			//console.log("reducer: " + JSON.stringify (myalert));
			if( myalert !== undefined && myalert.length !== 0 ) {
				return alerts.map((alert) => alert.title === action.payload.title ? action.payload : alert );
			} else {
				return [ ...alerts, action.payload ];
			}
		}
		default:
			return alerts;
	}
};

export default alerts;