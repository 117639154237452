import { ALARM_FETCH_ALL, ALARM_UPDATE } from '../constants/actionTypes';

const alarmelements = function ( alarmelements = [], action ) {
	switch ( action.type ) {
		case ALARM_FETCH_ALL:
			return action.payload;
		case ALARM_UPDATE:
			return alarmelements.map((alarmelement) => alarmelement._id === action.payload._id ? action.payload : alarmelement);
		default:
			return alarmelements;
	}
};

export default alarmelements;