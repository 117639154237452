import React from "react";
import { useSelector } from 'react-redux';
import { Box, Typography, Switch, Slider, Grid, Card, CardMedia, CardContent, CircularProgress } from '@mui/material';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import './element.css';



import useStyles from './styles';
import { dimLight, switchLight } from '../../../actions/knx';

const Dimmer = ({ knxid, writeSnack }) => {
	//console.log("Dimmer: " + JSON.stringify(element));
	const classes = useStyles();

	var element = useSelector((state) => state.elements.find(( elem ) => {
		//console.log ("useSelector: " + elem.knxid );
		return elem.knxid === knxid; }));
	if ( !element ){
		return <CircularProgress />
	}

	//const [checked, setChecked] = React.useState( isOn );
	//const [dimmer, setDimmer] = React.useState( element.value );
	//const dispatch = useDispatch();
	
	var myImage = "images/light_dark.png";
	if ( element.image !== undefined && element.image !== "" ) {
		myImage = element.image;
	}
	
	const handleCheck = async ( event ) => {
		let myret = await switchLight( event, element );
		//console.log("Value fro myRet: " + myret);
		if( myret !== undefined ) {
			writeSnack( myret );
		}
		//switchLight( event, element );
	}

	const handleDimmer = async ( event, value ) => {
		let myret = await dimLight( event, value, element );
		//console.log("Value fro myRet: " + myret);
		if( myret !== undefined ) {
			writeSnack( myret );
		}

		//dimLight( event, value, element );
	}

	var isOn;
	if( element.value === 0 ) { isOn = false; } else { isOn = true;	}

	return (
		<Box>
			<Card className={classes.card} sx={{ display: { xs: 'none', sm: 'block' } }} >
				<CardMedia component="img" height="60" image={ myImage } />
				<div className={classes.overlay}>
					<Typography variant="h6">{ element.label }</Typography>
				</div>
				<div className={classes.overlay3}><LightbulbOutlinedIcon /></div>
				<CardContent sx={{ ml: -2, mr: -2, mt: 0, mb: -2}}>
					<Box sx={{ mr:2 }}>
						<Grid container spacing={2} alignItems="center" justifyContent="center">
							<Grid item sx={{mt:-0.85}}>
								<Switch key={element.knxid} className={classes.dimmerSwitch} 
									checked={ isOn } onChange={ handleCheck } />
							</Grid>
							<Grid item xs sx={{mt:-0.85}}>
								<Slider key={element.knxid + "_slider"} className={classes.dimmerSlider}
									value={Math.round( element.value /2.55)}
									valueLabelDisplay="auto" step={10} min={0} max={100} 
									// The slider uses a callback function therefore NO {} in the call and in the receiving function!
									onChange={ handleDimmer }
									/>
							</Grid>
						</Grid>
					</Box>
				</CardContent >
			</Card > 
			<Card className={classes.card} sx={{ display: { xs: 'block', sm: 'none' } }} >
				<Grid container>
					<Grid item xs={5}>
						<CardMedia component="img" height="60" image={ myImage } />
							<div className={classes.overlay}>
								<Typography variant="h6">{ element.label }</Typography>
							</div>
							<div className={classes.overlay3}><LightbulbOutlinedIcon /></div>
					</Grid>
					<Grid item xs={7}>
						<CardContent sx={{ ml: -2, mr: -2, mt: -0.2, mb: -2}}>
							<Box sx={{ mr:2 }}>
								<Grid container spacing={2} alignItems="center" justifyContent="center">
									<Grid item sx={{mt:-0.5}}>
										<Switch key={element.knxid} className={classes.dimmerSwitch} 
											checked={ isOn } onChange={ handleCheck } />
									</Grid>
									<Grid item xs sx={{mt:-3.6, maxHeight:45}}>
										<Slider key={element.knxid + "_slider"} className={classes.dimmerSlider}
											value={Math.round( element.value /2.55)}
											step={10} min={0} max={100} 
											// The slider uses a callback function therefore NO {} in the call and in the receiving function!
											onChange={ handleDimmer }
											/>
									</Grid>
								</Grid>
							</Box>
						</CardContent >
					</Grid>
				</Grid>
			</Card > 
		</Box>
	);
}

export default Dimmer;
