import { HEATING_FETCH_ALL, HEATING_UPDATE } from '../constants/actionTypes';

//import { ActionTypes } from '@mui/base';
import * as api from '../api/index.js';

//Action Creators
export const getHeatingElements = () => async (dispatch) => {
	try {
		const { data } = await api.fetchHeatings();
		dispatch ({ type: HEATING_FETCH_ALL, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const updateHeating = (id, heating ) => async (dispatch) => {
	console.log( "updateHeating: " + id );
	try {
		//console.log( "updateHeating: " + JSON.stringify( heating ));
		const { status, data } = await api.updateHeating( id, heating );

		if( status !== 200 ) {
			//console.log( "Data: " + JSON.stringify(data, null, 2 ));
		} else {
			//dispatch({ type: HEATING_UPDATE, payload: heating }); //This is not multi user proof
			dispatch( getHeatingElements() );
		}
	} catch (error) {
		console.log(error);
	}
}

export const setHeatingToBeStd = () => async (dispatch) => {
	console.log( "setHeatingToBeStd: " );
	try {
		await api.applyHeatingToBeStd();
		//const { data } = await api.fetchHeatings();
		//dispatch ({ type: HEATING_FETCH_ALL, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const setHeatingToBeAway = () => async (dispatch) => {
	console.log( "setHeatingToBeAway: " );
	try {
		await api.applyHeatingToBeAway();
		//const { data } = await api.fetchHeatings();
		//dispatch ({ type: HEATING_FETCH_ALL, payload: data });
	} catch ( error ) {
		console.log(error);
	}
}

export const updateHeatingInStore = ( heating ) => async (dispatch) => {
	try {
		//console.log( "updateElement: " + JSON.stringify(element));
		dispatch ({ type: HEATING_UPDATE, payload: heating });
	} catch ( error ) {
		console.log(error);
	}
}