import React, { useState, useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CircularProgress, Grid, Button } from '@mui/material';
import HeatingConfigTable from './HeatingConfigTable';

import { getHeatingElements, setHeatingToBeStd, setHeatingToBeAway } from '../../actions/heating';
import ChaletIcon from '@mui/icons-material/Chalet';
import HomeIcon from '@mui/icons-material/Home';


const HeatingConfig = ({ writeSnack }) => {
	const dispatch = useDispatch();

	const [currentHeatingElementId, setCurrentHeatingElementId] = useState(null);
	
	const heatings = useSelector(( state ) => state.heatings );

	useEffect(() => {
		//console.log( "UseEffect in table");
		dispatch( getHeatingElements() );
	}, [dispatch]);	
	
	

	if ( !heatings || heatings.length < 1  ){
		return <CircularProgress />
	}

	const handleToBeStd = () => {
		dispatch( setHeatingToBeStd() );
	}

	const handleToBeAway = () => {
		dispatch( setHeatingToBeAway() );
	}

	return (
		<Grid container justifyContent="center" spacing={2}>
			<Grid item xs={12}>
				<HeatingConfigTable currentHeatingElementId={ currentHeatingElementId } 
						setCurrentHeatingElementId={ setCurrentHeatingElementId } 
						writeSnack={ writeSnack } />
			</Grid>
			<Grid item sx={{ mt: -2 }} xs={6}>
				<Button onClick={ handleToBeStd }
				 		variant="contained" 
						startIcon={<HomeIcon />} 
						color="primary" 
						size="large"
						fullWidth>Normal anwenden</Button>
			</Grid>
			<Grid item sx={{ mt: -2 }} xs={6}>
				<Button onClick={ handleToBeAway }
						variant="contained" 
						startIcon={<ChaletIcon />} 
						color="primary" 
						size="large"
						fullWidth>Urlaub anwenden</Button>
			</Grid>
		</Grid>
	)
}

export default HeatingConfig;