import React from "react";
import { useDispatch } from 'react-redux';

import { Box, Typography, Grid, Card, CardMedia, CardContent } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

import { getElementsForPage, getBreadcrumbsForPage } from '../../../actions/knx';

import './element.css';
import useStyles from './styles';

const Page = ({element}) => {
	//console.log("Page: " + JSON.stringify(element));
	const classes = useStyles();
	const dispatch = useDispatch();

	var myImage = "images/page_dark.png";
	if ( element.image !== undefined && element.image !== "" ) {
		myImage = element.image;
	}

	const handleClick = ({ element }) => {
		dispatch( getBreadcrumbsForPage( element.page ) );
		dispatch( getElementsForPage( element.page ) );
	}
	
	return (
		<Card className={classes.card} onClick={(e) => handleClick({ element })} >
			<CardMedia component="img" height="60" image={ myImage } />
				<div className={classes.overlay}>
					<Typography variant="h6">{ element.label }</Typography>
				</div>
				<div className={classes.overlay3}><LinkIcon /></div>
			<CardContent sx={{ display: { xs: 'none', sm: 'block' }, ml: -2, mr: -2, mt: 0, mb: -2 }}>
				<Box >
					<Grid container padding={1} spacing={4} alignItems="center" justifyContent="center">
						<Grid item sx={{mt:-0.5}}>&nbsp;</Grid>
					</Grid>
				</Box>
			</CardContent >
		</Card > 
	);
}


export default Page;