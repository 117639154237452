import * as React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

// toDo: maybe better to get the selctor outside this class?
import { useSelector } from 'react-redux';
import moment from 'moment';

import LoopIcon from '@mui/icons-material/Loop';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import * as TEXTS from '../../constants/texts';
import * as CONST from '../../constants/general';
import { updateUserConfig, getUserConfigValue } from '../../actions/auth';

const dense = true;


function descendingComparator(a, b, orderBy) {

	// Frequency.type needs a specifc compare method as the standard handles level 1 comapres only
	if( orderBy === "frequency.type" ) {
		if (b.frequency.type < a.frequency.type) { return -1; }
		if (b.frequency.type > a.frequency.type) { return 1; }
		return 0;
	}

	if (b[orderBy] < a[orderBy]) { return -1; }
	if (b[orderBy] > a[orderBy]) { return 1; }
	return 0;
}

function getComparator(order, orderBy) {
	let myval;

	if( order === 'desc' ) {
		myval = (a, b) => descendingComparator(a, b, orderBy);
		
	} else { 
		myval = (a, b) => -descendingComparator(a, b, orderBy);
	}
	return myval;
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
/*
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}
*/

const headCells = [
  { id: 'repeats', aligncenter: true, disablePadding: true, label: '', },
  { id: 'title', aligncenter: false, disablePadding: true, label: 'Name', },
  { id: 'frequency.type', aligncenter: false, disablePadding: false, label: 'Typ', },
  { id: 'nextDateTime', aligncenter: false, disablePadding: false, label: 'Nächste Ausführung', },
  { id: 'creator', aligncenter: false, disablePadding: false, label: 'Ersteller', },
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } =
		props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	/*
	<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all desserts',
						}}
					/>*/

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					Aktiv
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.aligncenter ? 'center' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
					alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),}),
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: '1 1 100%' }}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (
			<Typography
				sx={{ flex: '1 1 100%' }}
				variant="h6"
				id="tableTitle"
				component="div"
			>
				Timer Einstellungen
			</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton>
					<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton>
					<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
  	numSelected: PropTypes.number.isRequired,
};

export default function TimerTable({ currentTimerId, setCurrentTimerId }) {
	const userConfigs = useSelector((state) => state.userConfigs );
	let uc_rows = getUserConfigValue( userConfigs, CONST.UC_TIMER_TABLE_ITEMS_PER_PAGE, 5, true );

	const dispatch = useDispatch();

	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('title');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState( uc_rows );

	const timerEvents = useSelector((state) => state.timerEvents );

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};


	const handleSelectAllClick = (event) => {
		//console.log("handleSelectAllClick");
		/*
		if (event.target.checked) {
			const newSelecteds = timerEvents.map((n) => n.title );
			setSelected( newSelecteds );
			return;
		}
		setSelected([]);*/
	};

	const handleClick = ({event, timer, setCurrentTimerId }) => {

		setCurrentTimerId( timer._id );
		/*
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
		*/
	};

	const setTimerActive = ({ event, timer }) => {
		console.log("Set Active: " + timer.title );
		//console.log("Set Active Event: " + event );
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		console.log( "handleChangeRowsPerPage" );
		let newrows = parseInt(event.target.value, 10);
		setRowsPerPage( newrows );
		setPage(0);
		console.log( "handleChangeRowsPerPage: " + newrows );
		dispatch( updateUserConfig( CONST.UC_TIMER_TABLE_ITEMS_PER_PAGE, newrows ));
	};

	//const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - timerEvents.length) : 0;

	const formatTime = ( mydate ) => {
		if( mydate !== null ) {
			let mom = moment(mydate);
			return mom.format("YYYY-MM-DD HH:mm:ss");
		} else {
			return "- keine -";
		}
	}

	const getLoopIcon = (timer) => {
		if( new Date( timer.nextDateTime ) > Date.now() ) {
			if( timer.repeats === 1 ) { 
				return <LoopIcon />;
			} else {
				return " ";
			}
		} else {
			return <ErrorOutlineIcon />;
		}
	}

	// Temp changes:
	// <EnhancedTableToolbar numSelected={selected.length} />
	// <EnhancedTableHead
	//		numSelected={selected.length}
	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar numSelected={0} />
				<TableContainer>
					<Table
						sx={{ minWidth: 600 }}
						aria-labelledby="tableTitle"
						size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							numSelected={0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={ timerEvents.length }
						/>
						<TableBody>
							{/* if you don't need to support IE11, you can replace the `stableSort` call with:
								rows.slice().sort(getComparator(order, orderBy)) */
								
								/*
								stableSort( timerEvents, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((timer, index) => {
								
								*/}
							{
								
								timerEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort(getComparator(order, orderBy))
									.map((timer, index) => {
									
									//console.log( "MyTimer: " + JSON.stringify ( timer ));
									let isActive = false;
									if( timer.active === 1 ) { isActive = true }

									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={ (event) => handleClick({ event, timer, setCurrentTimerId })}
											role="checkbox"
											aria-checked={isActive}
											tabIndex={-1}
											key={timer.title}
											selected={isActive}
										>
											
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isActive}
													inputProps={{
														'aria-labelledby': labelId,
													}}
													onClick={(event) => setTimerActive({event, timer})}
												/>
											</TableCell>
											<TableCell align="center">{ getLoopIcon(timer) } </TableCell> 
											<TableCell
												align="left"
												component="th"
												id={labelId}
												scope="row"
												padding="none"
											>
												{ timer.title }
											</TableCell>
											<TableCell align="left">{ TEXTS.keyMap( timer.frequency.type, TEXTS.txtTIMERTYPES ) }</TableCell>
											<TableCell align="left">{ formatTime( timer.nextDateTime ) }</TableCell>
											<TableCell align="left">{ timer.creator }</TableCell>
										</TableRow>
									);
								})
							}
							{emptyRows > 0 && (
								<TableRow
								style={{
									height: (dense ? 33 : 53) * emptyRows,
								}}
								>
								<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={ timerEvents.length }
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
}
