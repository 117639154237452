import React, { useEffect } from 'react';
import { Container, Grid, CircularProgress, Box, Divider, Chip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { getElementsForPage, getBreadcrumbsForPage, getMenues } from '../../actions/knx';
import { getAlarmStatus } from '../../actions/alarm';


import * as CONST from '../../constants/general.js';

import Breadcrumb from './Element/Breadcrumb';
import Dimmer from './Element/Dimmer';
import Heating from './Element/Heating';
import Light from './Element/Light';
import Number from './Element/Number';
import Page from './Element/Page';
import Plug from './Element/Plug';
import Rollershutter from './Element/Rollershutter';
import Scene from './Element/Scene';
import Window from './Element/Window';

import EventTimerPage from '../EventTimer/EventTimerPage.js';
import AlarmPages from '../Alarm/AlarmPages';
import HeatingConfig from '../Heating/HeatingConfig';


//import useStyles from './styles';

const Elements = ({ writeSnack, setSnackOpen }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		//console.log( "UseEffect in Elements");
		dispatch( getElementsForPage( "pHome" ) );
		dispatch( getBreadcrumbsForPage( "pHome" ) );
		dispatch( getMenues() );

		dispatch( getAlarmStatus() );

	}, [dispatch]);

	const elements = useSelector((state) => state.elements );
	//const classes = useStyles();
	if ( !elements || !elements.length ){
		return <CircularProgress />
	}

	const getKeyForElement = ( element ) => {
		if( element.type === 'Page' ) {
			return element.page;
		} else {
			return element.knxid;
		}
	}

	const buildControlPage = ( elements ) => {
		return (
			<Container sx={{marginY: 5}}>
				<Box>
					<Grid container>
						<Grid item xs={12} sx={{ mt:-3.5, mb:1.5 }}>
							<Breadcrumb />
						</Grid>
					</Grid>
					<Grid container spacing={2} >
						{ elements.map(( element ) => (
							createElementGridItem( element )
						))}
					</Grid>
				</Box>
			</Container>
		)
	}

	const buildHeatingPage = () => {
		return (
			<Container sx={{marginY: 5}}>
				<Box>
					<Grid container>
						<Grid item xs={12} sx={{ mt:-3.5, mb:1.5 }}>
							<Breadcrumb />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<HeatingConfig item xs={12} />
					</Grid>
				</Box>
			</Container>
		)
	}

	const buildTimerPage = () => {
		return (
			<Container sx={{marginY: 5}}>
				<Box>
					<Grid container>
						<Grid item xs={12} sx={{ mt:-3.5, mb:1.5 }}>
							<Breadcrumb />
						</Grid>
						<Grid item xs={12}>
							<EventTimerPage item xs={12}  writeSnack={ writeSnack } setSnackOpen={ setSnackOpen }/>
						</Grid>
					</Grid>
				</Box>
			</Container>
		)
	}

	const createElementGridItem = ( element ) => {
		if( element.type === CONST.DIVIDER ) {
			return (
				<Grid key={getKeyForElement(element)} item xs={12}>
					<Divider variant="middle" textAlign="left">
						<Chip size="small" color="secondary" label={ element.label } />
					</Divider>
				</Grid>
			);
		} else {
			return (
				<Grid key={getKeyForElement(element)} item xs={12} sm={4} lg={3}>
					{ createElement( element ) }
				</Grid>
			);
		}
	}

	const createElement = ( element ) => {
		switch( element.type ) {
		case CONST.DIMMER:
			return( <Dimmer knxid={ element.knxid } writeSnack={ writeSnack } />);
		case CONST.HEATING:
			return( <Heating knxid={ element.knxid } writeSnack={ writeSnack } />);
		case CONST.LIGHT:
			return( <Light knxid={ element.knxid } writeSnack={ writeSnack } />);
		case CONST.NUMBER:
			return( <Number knxid={ element.knxid } />);
		case CONST.PAGE:
			return( <Page element={ element } />);
		case CONST.PLUG:
			return( <Plug knxid={ element.knxid } writeSnack={ writeSnack } />);
		case CONST.ROLLERSHUTTER:
			return( <Rollershutter knxid={ element.knxid } writeSnack={ writeSnack } />);
		case CONST.SCENE:
			return( <Scene element={ element } writeSnack={ writeSnack } />);
		case CONST.WINDOW:
			return( <Window knxid={ element.knxid } />);
		default:
			return( <div className="error"> Unknown type { element.type } </div> );
		}
	}

	const getElementMajorType = ( elementtype ) => {
		let myresult;

		switch( elementtype ) {
		case CONST.DIMMER:
		case CONST.HEATING:
		case CONST.LIGHT:
		case CONST.NUMBER:
		case CONST.PAGE:
		case CONST.PLUG:
		case CONST.ROLLERSHUTTER:
		case CONST.SCENE:
		case CONST.WINDOW:
		case CONST.DIVIDER:
			myresult = CONST.ELEMENT_CONTROL;
			break;
		case CONST.TIMERPAGE:
		case CONST.ALARMACTIVATEPAGE:
		case CONST.ALARMDEACTIVATEPAGE:
		case CONST.ALARMCONFIGUREPAGE:
		case CONST.ALARMCHANGEPINPAGE:
		case CONST.HEATING_PAGE:
		default:
			myresult = elementtype;
		}
		return myresult;
	}

	// Validate the Type of the frist Element
	let majortype = getElementMajorType( elements[0].type );

	switch ( majortype ) {
		case CONST.ELEMENT_CONTROL:
			return buildControlPage( elements );
		case CONST.TIMERPAGE:
			return buildTimerPage();
		case CONST.ALARMACTIVATEPAGE:
		case CONST.ALARMDEACTIVATEPAGE:
		case CONST.ALARMCONFIGUREPAGE:
		case CONST.ALARMCHANGEPINPAGE:	
			return (
				<AlarmPages majortype={majortype} writeSnack={ writeSnack } />
			)
		case CONST.HEATING_PAGE:
			return buildHeatingPage();
		default:
			console.error ( "Unknown first Element incollection - no page created: " + elements[0].type )
			break;
	}

}

export default Elements;
