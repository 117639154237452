import * as React from 'react';
import { useSelector } from 'react-redux';
import { Radio, RadioGroup, FormControlLabel, FormControl, CircularProgress, Grid, Typography } from '@mui/material';
import ActionRoomList from './actionRoomList';
import * as CONST from '../../../constants/general';


export default function ActionRoomsTab( { currentAnyAction, setCurrentAnyAction } ) {

	const actionselection = useSelector((state) => state.timerActionSelection );

	const hasValidElements = ( value ) => {
		let myret = false;
		if( value.rooms !== undefined ) {
			for( let i = 0; i < value.rooms.length; i++ ) {
				myret = hasValidElements( value.rooms[i] );
				if( myret === true ) { 
					return true;
				}
			}
		} else {
			if( value.controls !== undefined ) {
				for( let i = 0; i < value.controls.length; i++ ) {
					let myval = value.controls[i];
					if( myval.type === currentAnyAction.object ||
							( currentAnyAction.object === CONST.LIGHT && myval.type === CONST.DIMMER ) ) {
						if( currentAnyAction.subType === CONST.ACTION_DIM ) {
							if( myval.type === CONST.DIMMER ) {
								return true;
							} 
						} else {
							return true;
						}
					}
				}
			} else {
				return false;
			}
		}
		return false;
	}

	const getAreaForId = ( areaid ) => {
		for( let i = 0; i < actionselection.length; i++ ) {
			if( actionselection[i].id === areaid ) {
				return actionselection[i]
			}
		}
		return null;
	}

	const getRoomsForAreaId = ( areaid ) => {
		for( let i = 0; i < actionselection.length; i++ ) {
			if( actionselection[i].id === areaid ) {
				return actionselection[i].rooms;
			}
		}
		return null;
	}

	const getRoomForAreaRoomId = ( areaid, roomid ) => {
		let myarea = getAreaForId( areaid );

		for( let i = 0; i < myarea.rooms.length; i++ ) {
			if(  myarea.rooms[i].id === roomid ) {
				return  myarea.rooms[i]
			}
		}
		return null;
	}

	const getFirstValidArea = () => {
		for( let i = 0; i < actionselection.length; i ++ ) {
			if( hasValidElements( actionselection[i] ) === true ) {
				return actionselection[i];
			}
		}
	}

	const getFirstValidRoom = ( areaid ) => {
		let myarea = getAreaForId( areaid );

		for( let i = 0; i < myarea.rooms.length; i ++ ) {
			if( hasValidElements( myarea.rooms[i] ) === true ) {
				return myarea.rooms[i];
			}
		}
	}

	const getAreaForRoomId = ( roomid ) => {
		//console.log( "getAreaForRoomId - roomid: " + roomid );
		let myarea;
		for( let i = 0; i < actionselection.length; i ++ ){
			myarea = actionselection[i];

			for( let j = 0; j < myarea.rooms.length; j++ ){
				let myroom = myarea.rooms[j];
				if( myroom.id === roomid ) {
					return myarea;
				}
			}
		}
		return null;
	}

	/*
	const getFirstValidControl = ( areaid, roomid ) => {
		let myroom = getRoomForAreaRoomId( areaid, roomid );

		for( let i = 0; i < myroom.controls.length; i++ ) {
			let myval = myroom.controls[i];
			if( myval.type === currentAnyAction.object ||
				( currentAnyAction.object === CONST.LIGHT && myval.type === CONST.DIMMER ) ) {
				return myval;
			}
		}
	}*/

	let initArea;
	let initRoom;
	let initKnxid;

	if( !currentAnyAction.areaId ) {
		//console.log( "getFirstValidArea" );
		initArea = getFirstValidArea()
	} else {
		//console.log( "getAreaID from AnyAction : " + currentAnyAction.areaId );
		initArea = getAreaForId( currentAnyAction.areaId );
	}

	if( !currentAnyAction.roomId ) {
		//console.log( "getFirstValidRoom" );
		initRoom = getFirstValidRoom(initArea.id)
	} else {
		//console.log( "getRoomID from AnyAction : " + currentAnyAction.roomId );
		initRoom = getRoomForAreaRoomId( initArea.id, currentAnyAction.roomId);
	}

	if( !currentAnyAction.knxid ) {
		//initControl = getFirstValidControl(initArea.id, initRoom.id)
	} else {
		//console.log( "initKnxid from AnyAction : " + currentAnyAction.knxid );
		initKnxid = currentAnyAction.knxid;
	}

	const [area, setArea] = React.useState( initArea.id );
	const [areaLabel, setAreaLabel] = React.useState( initArea.label );

	const [room, setRoom] = React.useState( initRoom.id );
	const [roomLabel, setRoomLabel] = React.useState( initRoom.label );

	const [control, setControl] = React.useState( initKnxid );

	const [myvals, setMyvals] = React.useState();
	const [mycontrols, setMyControls] = React.useState();

	const getSubValues = ( value ) => {
		//console.log( "getSubValues: " + value );
		for( let i = 0; i < actionselection.length; i++ ) {
			if( actionselection[ i ].id === value ) {

				setAreaLabel( actionselection[i].label )
				setMyvals( actionselection[i].rooms );

				let myroomtmp;
				let myroomtmpid;
				let myroomtmplabel;

				if( currentAnyAction.roomId === undefined || currentAnyAction.roomId === '' ) {
					//console.log( "getSubValues: Normal" );
					myroomtmp = getFirstValidRoom( value ); 
					setRoom( myroomtmp.id );
					setRoomLabel( myroomtmp.label );
					myroomtmpid = myroomtmp.id;
					myroomtmplabel = myroomtmp.label;
				} else {
					//console.log( "getSubValues: AnyAytion" );
					setRoom( currentAnyAction.roomId );
					setRoomLabel( currentAnyAction.roomLabel );
					myroomtmpid = currentAnyAction.roomId;
					myroomtmplabel = currentAnyAction.roomLabel;
				}

				getControls( value, myroomtmpid );

				if( !(currentAnyAction.knxid) ) {					
				} else {
					setControl( currentAnyAction.knxid );	
				}
				//console.log( "getSubValues: areaLabel: " + actionselection[i].label + " roomLabel: " +  myroomtmplabel );
			}
		}
	}

	const getCurrentRoom = ( myarea, myroom ) => {
		let rooms = getRoomsForAreaId( myarea );
		for( let i = 0; i < rooms.length; i++ ) {
			if( rooms[i].id === myroom ) {
				return rooms[i];
			}
		}
		return null;
	}
	
	const getControls = ( myarea, myroom ) => {
		//console.log( `getControls myarea ${myarea} myroom ${myroom} ` )
		let mycur = getCurrentRoom( myarea, myroom );

		if( !mycur ) {
			mycur = getFirstValidRoom( myarea );
		}

		setRoomLabel( mycur.label )
		setMyControls( mycur.controls );
	}

	if( !myvals ) {
		//console.log( "Waiting for: myvals ");
		getSubValues( area );
		return <CircularProgress />
	}

	const handleChange = (event) => {
		let myarea = getAreaForId(event.target.value);

		setArea( myarea.id ); 
		setAreaLabel( myarea.label );
		setRoom( undefined ); 
		setRoomLabel( undefined ); 

		setCurrentAnyAction({ ...currentAnyAction, 
			areaId: myarea.id, areaLabel: myarea.label,
			roomId: '', roomLabel: '',
			knxid: '', controlLabel: ''}, getSubValues( event.target.value ) );
	};

	const setValuesChangeSub = ( inroomid, skipClearKNX ) => {
		//console.log( "handleChangeSub: " + currentAnyAction.areaLabel);
		let myarea;

		if( !currentAnyAction.areaId || currentAnyAction.areaId === '' ) {

			myarea = getAreaForRoomId( inroomid );
			//console.log( "handleChangeSub - myarea: " + myarea.id );

			setArea( myarea.id );
			setAreaLabel( myarea.label )

			//console.log( "handleChangeSub - in area" );
		} else {
			myarea = getAreaForId( currentAnyAction.areaId ); 
		}

		setRoom( inroomid ); 

		if( skipClearKNX === undefined || skipClearKNX !== true ) {
			//console.log( "handleChangeSub - clear knx" );
			setCurrentAnyAction({ ...currentAnyAction, 
				areaId: myarea.id, areaLabel: myarea.label,
				roomId: inroomid, roomLabel: getRoomForAreaRoomId(area, inroomid).label,
				knxid: '', controlLabel: '' },getControls( area, inroomid ));
		} else {
			//console.log( "handleChangeSub - don't clear knx" );
			setCurrentAnyAction({ ...currentAnyAction, 
				areaId: myarea.id, areaLabel: myarea.label,
				roomId: inroomid, roomLabel: getRoomForAreaRoomId(area, inroomid).label,
				},getControls( area, inroomid ));
		}
		

		//getControls( area, inroomid );
		
	};

	const handleChangeSub = (event) => {
		setValuesChangeSub ( event.target.value );
	}


	const createRadioButton = ( value ) => {
		return (
			<FormControlLabel key={value.id} value={value.id} control={<Radio size='small' />} 
				label={<Typography variant="body2" >{value.label}</Typography>} />
		)
	}

	return (
		<Grid minHeight={300} width={'100%'} container sx={{mt: 3}}>
			<Grid item xs={12} md={4} lg={4} >
				<FormControl>
					<Typography variant="body2" >Stockwerk:</Typography>

					<RadioGroup
						value={currentAnyAction.areaId}
						onChange={handleChange}
					>
						{ actionselection.map(( myval, index ) => {
							//console.log( `create sub: value: ${ myval.type } index: ${ index }` );
							if( hasValidElements( myval ) === true ) {
								return (
									createRadioButton( myval )
								)
							} else {
								//console.log( `create sub no valis elements: value: ${ myval.type } index: ${ index }` );
							}
							return "";
						})}

					</RadioGroup>
				</FormControl>				
			</Grid>
			<Grid item xs={12} md={4} lg={4} >
				<FormControl>
					<Typography variant="body2">Raum:</Typography>

					<RadioGroup
						value={currentAnyAction.roomId}
						onChange={handleChangeSub}
					>
						{ myvals.map(( myval, index ) => {
							//console.log( `create sub: value: ${ myval.type } index: ${ index }` );
							if( hasValidElements( myval ) === true ) {
								return (
									createRadioButton( myval )
								)
							} else {
								//console.log( `create sub no valis elements: value: ${ myval.type } index: ${ index }` );
							}
							return "";
						})}
					</RadioGroup>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={4} lg={4} >
				<FormControl>
					<Typography variant="body2">Objekt:</Typography>
					<ActionRoomList currentAnyAction={currentAnyAction} setCurrentAnyAction={setCurrentAnyAction} 
									mycontrols={mycontrols} control={control} setControl={setControl} 
									area={area} areaLabel={areaLabel} room={room} roomLabel={roomLabel}
									setValuesChangeSub={setValuesChangeSub} />
				</FormControl>
			</Grid>
		</Grid>

	);
}



