import { TIMER_ACTION_SELECTION } from '../constants/actionTypes';

const timerEvents = function ( timerEvents = [], action ) {
	switch ( action.type ) {
		case TIMER_ACTION_SELECTION:
			return action.payload;
		default:
			return timerEvents;
	}
};

export default timerEvents;
