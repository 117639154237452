import { HEATING_FETCH_ALL } from '../constants/actionTypes';

const heatings = function ( heatings = [], action ) {
	switch ( action.type ) {
		case HEATING_FETCH_ALL:
			return action.payload;
		default:
			return heatings;
	}
};

export default heatings;