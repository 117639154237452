import * as React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, CircularProgress, Grid, Typography } from '@mui/material';

import * as CONST from '../../../constants/general';

export default function ActionTypesTab( { currentAnyAction, setCurrentAnyAction, radioConf, setRadioConf } ) {

	let myvals = [];	

	//console.log(radioConf);

	if( !radioConf ) {
		fetch("/json/actionType.json")
			.then((res) => res.json())
			.then((data) => {
				setRadioConf( data );
				//console.log(radioConf);
		});
		return <CircularProgress />
	} 
	

	const getSubValues = ( value ) => {
		for( let i = 0; i < radioConf.length; i++ ) {
			if( radioConf[ i ].type === value ) {
				myvals = radioConf[ i ].values;
			}
		}
	}

	getSubValues( currentAnyAction.object );

	const handleChange = (event) => {
		getSubValues( event.target.value );

		if( event.target.value === CONST.BIND_PUSHOVER || 
			event.target.value === CONST.VARIABLE || 
			event.target.value === CONST.WORKFLOW || 
			event.target.value === CONST.ALERTAPP) {
			// set a fake knxid
			let tmpknxid = (event.target.value).substring(0,3) + "_" + Math.floor(Math.random() * 99999);
			//let tmpknxid = "";

			setCurrentAnyAction({ ...currentAnyAction, object: event.target.value, subType: myvals[0].type, knxid: tmpknxid });
		} else {
			setCurrentAnyAction({ ...currentAnyAction, object: event.target.value, subType: myvals[0].type });
		}

		


		/* Beispiel
		setTimerData({  ...timerData, frequency: { 
		...timerData.frequency, type: getTabName( newValue ) }})
		*/
	};

	const handleChangeSub = (event) => {
		setCurrentAnyAction({ ...currentAnyAction, subType: event.target.value });
	};

	const createRadioButton = ( value, label ) => {
		return (
			<FormControlLabel key={value} value={value} control={<Radio size='small' />} 
				label={<Typography variant="body2" >{label}</Typography>} />
		)
	}

	return (
		<Grid minHeight={300} width={'100%'} container sx={{mt: 3}}>
			<Grid item xs={12} md={4} lg={4} >
				<FormControl>
					<Typography variant="body2" >Art der Aktion:</Typography>

					<RadioGroup
						value={currentAnyAction.object}
						onChange={handleChange}
					>
						{ radioConf.map(( myval, index ) => {
							return (
								createRadioButton( myval.type, myval.label )
							)
						})}

					</RadioGroup>
				</FormControl>				
			</Grid>
			<Grid item xs={12} md={8} lg={8} >
				<FormControl>
					<Typography variant="body2">führe aus:</Typography>

					<RadioGroup
						value={currentAnyAction.subType}
						onChange={handleChangeSub}
					>
						{ myvals.map(( myval, index ) => {

							//console.log( `create sub: value: ${ myval.type } index: ${ index }` );
							return (
								createRadioButton( myval.type, myval.label )
							)
						})}
					</RadioGroup>
				</FormControl>
			</Grid>
		</Grid>

	);
}

