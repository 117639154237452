import React from "react";
import { useSelector } from 'react-redux';
import { Box, Typography, Grid, Chip, Card, CardMedia, CardContent, CircularProgress, Dialog, DialogTitle, Slider } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import RollerShadesClosedOutlinedIcon from '@mui/icons-material/RollerShadesClosedOutlined';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';

import { rollershutterUp, rollershutterDown, rollershutterStop, rollershutterToPos } from '../../../actions/knx.js'
import { STYLE_DESKTOP, STYLE_MOBILE, STYLE_NOPERCENT } from '../../../constants/general.js';

import './element.css';

import useStyles from './styles';

const Rollershutter = ({ knxid, writeSnack }) => {
	//console.log("Rollershutter: " + JSON.stringify(element));
	
	const classes = useStyles();
	
	const [open, setOpen] = React.useState( false );
	
	var element = useSelector((state) => state.elements.find(( elem ) => {
		//console.log ("useSelector: " + elem.knxid );
		return elem.knxid === knxid; }));
	
	const [selectedValue, setSelectedValue] = React.useState( Math.round( element.value ));
	
	if ( !element ){
		return <CircularProgress />
	}

	
	var myImage = "images/rollershutter.png";
	if ( element.image !== undefined && element.image !== "" ) {
		myImage = element.image;
	}

	var myValue = "n/a";

	if( element.value !== undefined ) {
		myValue = "" + Math.round( element.value ) + "%";
	}

	const rollershutterUpInner = async ( e, element ) => {
		let myret = await rollershutterUp( e, element );
		//console.log("Value fro myRet: " + myret);
		if( myret !== undefined ) {
			writeSnack( myret );
		}
	}

	const rollershutterStopInner = async ( e, element ) => {
		let myret = await rollershutterStop( e, element );
		//console.log("Value fro myRet: " + myret);
		if( myret !== undefined ) {
			writeSnack( myret );
		}
	}

	const rollershutterDownInner = async ( e, element ) => {
		let myret = await rollershutterDown( e, element );
		//console.log("Value fro myRet: " + myret);
		if( myret !== undefined ) {
			writeSnack( myret );
		}
	}

	//console.log("Rollershutter Value: " + myValue );
	const createChip = ( myValue, element, type ) => {
		if( element.visuStyle === STYLE_NOPERCENT ) {
			// no Chip returned
			return;
		} 
		
		if( type === STYLE_DESKTOP ) {
			return (
				<Grid item sx={{mt:-2}}>
					<Chip key={element.knxid + "_status"}
							label={ myValue } 
							color="primary"  
							variant="outlined" 
							size="small" 
							onClick={(e) => handleDialogOpen() }/>
					{
						createSelectDialog( element, type )
					}
				</Grid> )
		} else {
			return (
				<Grid item sx={{mt:-2}}>
					<Chip key={element.knxid + "_status"} 
							label={ myValue } 
							color="primary" 
							sx={{width: 55}} 
							variant="outlined" 
							size="small"
							onClick={(e) => handleDialogOpen() } />
					{
						createSelectDialog( element, type )
					}
				</Grid>	)

		}
	}

	const handleDialogOpen = () => {
		//console.log("handleDialogOpen");
		setOpen( true );
	};

	const handleDialogClose = () => {
		//console.log("handleDialogClose");
		setOpen( false );
	};
	
	const handleDialogValue = ( e, value ) => {
		setSelectedValue( value );
	};
	
	const handleDialogCommitValue = async ( e, value ) => {
		//console.log("handleDialogCommitValue: " + value );
		setOpen( false );

		let myret = await rollershutterToPos( e, element, value );
		//console.log("handleDialogCommitValue from myRet: " + myret);

		if( myret !== undefined ) {
			writeSnack( myret );
		}
	};

	const createSelectDialog = ( element, type ) => {
		//console.log( "createSelectDialog - element: " + JSON.stringify( element ));
		return (
			<Dialog onClose={ handleDialogClose } open={ open }
					fullWidth={true}
        			maxWidth={"sm"} >
				<Grid container >
					<Grid item xs={12}>
						<DialogTitle textAlign="center">Fahre zu Position</DialogTitle>
					</Grid>
					<Grid item sx={{ ml:4, mr:4, mb:2 }} xs={12}>
						<Slider key={element.knxid + "_slider"} 
							value={ selectedValue }
							valueLabelDisplay="auto"
							min={0} max={100} 
							// The slider uses a callback function therefore NO {} in the call and in the receiving function!
							onChangeCommitted={ handleDialogCommitValue }
							onChange={ handleDialogValue }
							/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}


	return (
		<Box>
			<Card className={classes.card} sx={{ display: { xs: 'none', sm: 'block' } }}>
				<CardMedia component="img" height="60" image={ myImage } />
				<div className={classes.overlay}>
					<Typography variant="h6">{ element.label }</Typography>
				</div>
				<div className={classes.overlay3}><RollerShadesClosedOutlinedIcon /></div>
				<CardContent sx={{ ml: -2, mr: -2, mt: 0, mb: -2}}>
					<Box >
						<Grid container spacing={2} alignItems="center" justifyContent="center">
							<Grid item sx={{mt:-2}}>
								<IconButton key={element.knxid + "_up"} className={classes.rollerbutton} size="large" color="primary" onClick={(e) => { rollershutterUpInner( e, element )}} >
									<ArrowCircleUpOutlinedIcon />
								</IconButton>
							</Grid>
							<Grid item sx={{mt:-2}}>
								<IconButton key={element.knxid + "_stop"} className={classes.rollerbutton} size="large" color="primary" onClick={(e) => { rollershutterStopInner( e, element )}} >
									<StopCircleOutlinedIcon />
								</IconButton>
							</Grid>
							<Grid item sx={{mt:-2}}>
								<IconButton key={element.knxid + "_down"} className={classes.rollerbutton} size="large" color="primary" onClick={(e) => { rollershutterDownInner( e, element )}} >
									<ArrowCircleDownOutlinedIcon />
								</IconButton>
							</Grid>

							{ 
								createChip( myValue, element, STYLE_DESKTOP )
							}
						</Grid>
					</Box>
				</CardContent>
			</Card >
			<Card className={classes.card} sx={{ display: { xs: 'block', sm: 'none' } }}>
				<Grid container>
					<Grid item xs={5}>
						<CardMedia component="img" height="60" image={ myImage } sx={{ ml: 0, mr: 0, mt: 0, mb: 0}} />
							<div className={classes.overlay}>
								<Typography variant="h6">{ element.label }</Typography>
							</div>
					</Grid>
					<Grid item xs={7}>
						<CardContent sx={{ ml: -3, mr: -2, mt: 0.5, mb: -2}}>
							<Box >
								<Grid container spacing={1} alignItems="center" justifyContent="center">
									<Grid item sx={{mt:-2, ml:-1}}>
										<IconButton key={element.knxid + "_up"} className={classes.rollerbutton} size="large" color="primary" onClick={(e) => { rollershutterUpInner( e, element )}} >
											<ArrowCircleUpOutlinedIcon />
										</IconButton>
									</Grid>
									<Grid item sx={{mt:-2, ml:-1}}>
										<IconButton key={element.knxid + "_stop"} className={classes.rollerbutton} color="primary" onClick={(e) => { rollershutterStopInner( e, element )}} >
											<StopCircleOutlinedIcon />
										</IconButton>
									</Grid>
									<Grid item sx={{mt:-2, ml:-1}}>
										<IconButton key={element.knxid + "_down"} className={classes.rollerbutton} color="primary" onClick={(e) => { rollershutterDownInner( e, element )}} >
											<ArrowCircleDownOutlinedIcon />
										</IconButton>
									</Grid>
									{ 
										createChip( myValue, element, STYLE_MOBILE )
									}
								</Grid>
							</Box>
						</CardContent>
					</Grid>
				</Grid>	
			</Card >
		</Box>
	);
}

export default Rollershutter;