import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';

import { HEATING_TOBE, HEATING_TOBESTD, HEATING_TOBEAWAY } from '../../constants/general';
import { temperatureUpOrDown } from '../../actions/knx.js';
import { updateHeating } from '../../actions/heating';
import { updateUserConfig, getUserConfigValue } from '../../actions/auth';
import * as CONST from "../../constants/general";

const dense = true;

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) { return -1; }
	if (b[orderBy] > a[orderBy]) { return 1; }
	return 0;
}

function getComparator(order, orderBy) {
	let myval;
	if( order === 'desc' ) {
		myval = (a, b) => descendingComparator(a, b, orderBy);
	} else { 
		myval = (a, b) => -descendingComparator(a, b, orderBy);
	}
	return myval;
}

const headCells = [
  { id: 'area', aligncenter: true, disablePadding: true, label: 'Bereich', },
  { id: 'room', aligncenter: true, disablePadding: true, label: 'Raum', },
  { id: 'current', aligncenter: true, disablePadding: true, label: 'Ist', },
  { id: 'toBe', aligncenter: true, disablePadding: true, label: 'Soll Aktuell', },
  { id: 'toBeStd', aligncenter: true, disablePadding: true, label: 'Soll Normal', },
  { id: 'toBeAway', aligncenter: true, disablePadding: true, label: 'Soll Urlaub', },
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } =
		props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};


	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.aligncenter ? 'center' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
					alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),}),
			}}
		>
			<Typography
				sx={{ flex: '1 1 100%' }}
				variant="h6"
				id="tableTitle"
				component="div"
			>
				Temperatur Einstellungen
			</Typography>
			
			<Tooltip title="Filter list">
				<IconButton>
				<FilterListIcon />
				</IconButton>
			</Tooltip>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
  	numSelected: PropTypes.number.isRequired,
};

export default function HeatingConfigTable({ currentHeatingElementId, setCurrentHeatingElementId, writeSnack }) {
	const userConfigs = useSelector((state) => state.userConfigs );
	let uc_rows = getUserConfigValue( userConfigs, CONST.UC_HEATING_TABLE_ITEMS_PER_PAGE, 10, true );
		
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('area');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState( uc_rows );

	const dispatch = useDispatch();

	const heatingElements = useSelector((state) => state.heatings );

	/*
	React.useEffect(() => {
		console.log( "UseEffect in table");
		//dispatch( getHeatingElements() );
		uc_rows = getUserConfigValue( userConfigs, CONST.UC_HEATING_TABLE_ITEMS_PER_PAGE, 10, true );
		console.log( "UseEffect setRowsPerPage in table: " + uc_rows );
		setRowsPerPage( uc_rows );
	}, [dispatch]);	
	*/

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		//console.log("handleSelectAllClick");
		/*
		if (event.target.checked) {
			const newSelecteds = timerEvents.map((n) => n.title );
			setSelected( newSelecteds );
			return;
		}
		setSelected([]);*/
	};

	const handleClick = ({ event, heatingElement, setCurrentHeatingElementId }) => {
		//console.log("handleClick: " + heatingElement.knxid );
		setCurrentHeatingElementId( heatingElement._id );
		/*
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
		*/
	};
	
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		let newrows = parseInt(event.target.value, 10);
		setRowsPerPage( newrows );
		setPage(0);
		dispatch( updateUserConfig( CONST.UC_HEATING_TABLE_ITEMS_PER_PAGE, newrows ));
	};

	//const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - heatingElements.length) : 0;

	const temperatureUp = async ( e, element, type ) => {
		//console.log( `temperatureUp: ${ element.knxid } type: ${ type }`);

		if( type === HEATING_TOBE ) {
			let myret = await temperatureUpOrDown( e, element, 0.5 );
			//console.log("temperatureUp - Value fro myRet: " + myret);
			if( myret !== undefined ) {
				writeSnack( myret );
			}
		} else if ( type === HEATING_TOBESTD ) {
			//console.log("temperatureUp - toBeStd: " + JSON.stringify( element, null, 2 ));
			element.toBeStd = element.toBeStd + 0.5;
			dispatch( updateHeating( element._id, element ));

		} else if ( type === HEATING_TOBEAWAY ) {
			//console.log("temperatureUp - toBeAway: " + JSON.stringify( element, null, 2 ));
			element.toBeAway = element.toBeAway + 0.5;
			dispatch( updateHeating( element._id, element ));

		} else {
			//console.log( `temperatureUp: - unknown type ${ element.knxid } type: ${ type }`);
		}
	}

	const temperatureDown = async ( e, element, type ) => {
		//console.log( `temperatureDown: ${ element.knxid } type: ${ type }`);

		if( type === HEATING_TOBE ) {
			let myret = await temperatureUpOrDown( e, element, -0.5 );
			//console.log("temperatureDown - Value fro myRet: " + myret);
			if( myret !== undefined ) {
				writeSnack( myret );
			}
		} else if ( type === HEATING_TOBESTD ) {
			//console.log("temperatureDown - toBeStd: " + JSON.stringify( element, null, 2 ));
			element.toBeStd = element.toBeStd - 0.5;
			//console.log("temperatureDown - toBeStd: " + JSON.stringify( element, null, 2 ));
			dispatch( updateHeating( element._id, element ));

		} else if ( type === HEATING_TOBEAWAY ) {
			//console.log("temperatureDown - toBeAway: " + JSON.stringify( element, null, 2 ));
			element.toBeAway = element.toBeAway - 0.5;
			//console.log("temperatureDown - toBeAway: " + JSON.stringify( element, null, 2 ));
			dispatch( updateHeating( element._id, element ));

		} else {
			//console.log( `temperatureDown: - unknown type ${ element.knxid } type: ${ type }`);
		}
	}
	
	const createTempCell = ( element, value, type ) => {
		return (
			<TableCell align="center">
				<Grid container padding={0} spacing={1} alignItems="center" justifyContent="center">
					<Grid item align="right" xs={4} sx={{mt:0, ml:0}}>
						<IconButton key={element.knxid + type + "_up"} 
									size="large" 
									color="primary" 
									onClick={(e) => { temperatureUp( e, element, type )}} >
							<ArrowCircleUpOutlinedIcon />
						</IconButton>
					</Grid>	
					<Grid item align="center" xs={4} sx={{mt:0, ml:0}}>
						<Typography key={element.knxid + type} variant="subtitle2" >{ value + " °C" }</Typography>
					</Grid>
					<Grid item align="left" xs={4} sx={{mt:0, ml:0}}>
						<IconButton key={element.knxid + type  + "_down"} 
									size="large" 
									color="primary" 
									onClick={(e) => { temperatureDown( e, element, type )}} >
							<ArrowCircleDownOutlinedIcon />
						</IconButton>
					</Grid>
				</Grid>
			</TableCell> )
	}

	// Temp changes:
	// <EnhancedTableToolbar numSelected={selected.length} />
	// <EnhancedTableHead
	//		numSelected={selected.length}
	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar numSelected={0} />
				<TableContainer>
					<Table
						sx={{ minWidth: 600 }}
						aria-labelledby="tableTitle"
						size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							numSelected={0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={ heatingElements.length }
						/>
						<TableBody>
							{/* if you don't need to support IE11, you can replace the `stableSort` call with:
								rows.slice().sort(getComparator(order, orderBy)) */
								
								/*
								stableSort( heatingElements, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((heatingElement, index) => {
								
								*/}
							{
								
								heatingElements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort(getComparator(order, orderBy))
									.map((heatingElement, index) => {
									
									//console.log( "MyHeatingElement: " + JSON.stringify ( heatingElement ));
									let isActive = false;
									if( heatingElement.active === true ) { isActive = true }

									//const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={ (event) => handleClick({ event, heatingElement, setCurrentHeatingElementId })}
											role="checkbox"
											aria-checked={isActive}
											tabIndex={-1}
											key={heatingElement.knxid}
											selected={isActive}
										>
																						
											<TableCell
												align="center"
												component="th"
												id={heatingElement.knxid + "area"}
												scope="row"
												padding="none"
											>
												{ heatingElement.area }
											</TableCell>
											<TableCell align="center">{ heatingElement.room }</TableCell>
											<TableCell align="center">{ Math.round(heatingElement.value * 100) / 100 + " °C" }</TableCell>
											{ createTempCell( heatingElement, heatingElement.value2, HEATING_TOBE ) }
											{ createTempCell( heatingElement, heatingElement.toBeStd, HEATING_TOBESTD ) }
											{ createTempCell( heatingElement, heatingElement.toBeAway, HEATING_TOBEAWAY ) }

										</TableRow>

										/*
										const headCells = [
											{ id: 'area', aligncenter: false, disablePadding: true, label: 'Bereich', },
											{ id: 'room', aligncenter: false, disablePadding: true, label: 'Raum', },
											{ id: 'current', aligncenter: false, disablePadding: true, label: 'Ist', },
											{ id: 'toBe', aligncenter: false, disablePadding: true, label: 'Soll', },
											{ id: 'toBeStd', aligncenter: false, disablePadding: true, label: 'Soll Normal', },
											{ id: 'toBeAway', aligncenter: false, disablePadding: true, label: 'Soll Urlaub', },
											];
											*/
									);
								})
							}
							{emptyRows > 0 && (
								<TableRow
								style={{
									height: (dense ? 33 : 53) * emptyRows,
								}}
								>
								<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={ heatingElements.length }
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
}
