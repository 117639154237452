import * as React from 'react';
import { Grid, Box, List, ListItemButton, ListItemIcon, ListItemText, CircularProgress, Typography } from '@mui/material';

import * as CONST from '../../../constants/general';
import { getDescLine1, getDescLine2 } from '../../../models/anyActionClient';

import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import RollerShadesClosedOutlinedIcon from '@mui/icons-material/RollerShadesClosedOutlined';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';


import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';


export default function ActionAssignedList( { timerData, setTimerData, 
											currentAnyAction, setCurrentAnyAction, 
											selectedIndex, setSelectedIndex,
											activeStep, setActiveStep,
											writeSnack } ) {

	const handleListItemClick = (event, knxid) => {
		setSelectedIndex( knxid );
		for( let i = 0; i < timerData.action.length; i++ ) {
			if( timerData.action[i].knxid === knxid ) {
				setCurrentAnyAction( timerData.action[i] ) ;
				setActiveStep(2);
			}
		}
	};

	//console.log( "ActionAssignedList- TimerData: " + JSON.stringify(timerData));

	if( !timerData ) {
		return <CircularProgress />
	}

	const createListItem = ( value ) => {
		//console.log( `createListItem: knxid: ${value.knxid}`)

		return (
			<ListItemButton
				key={ value.knxid }
				selected={ value.knxid === selectedIndex }
				onClick={(event) => handleListItemClick( event, value.knxid )}
				>
					<ListItemIcon>
						{
							value.object === CONST.LIGHT && (
								<LightbulbOutlinedIcon />
						)}
						{
							value.object === CONST.DIMMER && (
								<LightbulbOutlinedIcon />
						)}
						{
							value.object === CONST.ROLLERSHUTTER && (
								<RollerShadesClosedOutlinedIcon />
						)}
						{
							value.object === CONST.PLUG && (
								<ElectricalServicesIcon />
						)}
						{
							value.object === CONST.SCENE && (
								<MiscellaneousServicesOutlinedIcon />
						)}
						{
							value.object === CONST.BIND_PUSHOVER && (
								<CampaignOutlinedIcon />
						)}
						{
							value.object === CONST.VARIABLE && (
								<NumbersOutlinedIcon />
						)}
						{
							value.object === CONST.WORKFLOW && (
								<AccountTreeOutlinedIcon />
						)}
						{
							value.object === CONST.ALERTAPP && (
								<FeedbackOutlinedIcon />
						)}
					</ListItemIcon>
					<ListItemText primaryTypographyProps={{fontSize: '14px'}} primary={getDescLine1(value) + " " + getDescLine2(value)} />
				</ListItemButton>
		)
	}

	return (
		<Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt:6, ml: 2 }}>
			<Grid container>
				<Grid item xs={12}>
					<Typography sx={{fontSize: '14px' }} >Zugeordnete Aktionen:</Typography>
				</Grid>
				<Grid item xs={12}>
					<List component="nav" >
						{ timerData.action.map(( myval ) => {
							return (
								createListItem( myval )
							)
						})}
					</List>
					{ timerData.action.length === 0 && (
						<List component="nav" >
							<ListItemText primaryTypographyProps={{fontSize: '14px'}} primary={"- keine Aktion zugewiesen -"} />
						</List>
						)
					}
				</Grid>
			</Grid>
		</Box>
	);
}
