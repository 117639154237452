import React from 'react';
import { TextField, Grid, Checkbox, FormControlLabel  } from '@mui/material';

import moment from 'moment';


const EventTimerGeneralForm = ( { currentTimerId, setCurrentTimerId, timerData, setTimerData} ) => {

	//const classes = useStyles();
	
	const formatDateForDatefield = (inDate) => {
		if( inDate === undefined || inDate === "" ){
			return "";
		}
		
		let mom = new moment(inDate)
		return mom.format("YYYY-MM-DDTHH:mm");

	}

	const isActive = ({ timer }, key ) => {

		switch (key) {
			case "active":
				if( timerData.active === 1 )  {	return true; } 
				else { return false; }
			case "fireOnRestart":
				if( timerData.fireOnRestart === 1 )  {	return true; } 
				else { return false; }
			case "forceRecalcOnRestart":
				if( timerData.forceRecalcOnRestart === 1 )  {	return true; } 
				else { return false; }
			default:
				break;
		}
		return false;
	}

	const handleCheckbox = ( event ) => {
		
		let myval = 0;
		if( event.target.checked ) { myval = 1; } 

		switch( event.target.name ) {
			case "active":
				setTimerData({ ...timerData, active: myval })
				break;
			
			case "fireOnRestart":
				setTimerData({ ...timerData, fireOnRestart: myval })
				break;
		
			case "forceRecalcOnRestart":
				setTimerData({ ...timerData, forceRecalcOnRestart: myval })
				break;
			
			default:
				break;
		}
	}

	return (
		
			<Grid minHeight={200} container rowSpacing={1} sx={{mb:2}}>
				<Grid item xs={12} >
					<FormControlLabel label="Timer aktiv" labelPlacement="end" 
						control={
							<Checkbox key="active" name="active" color="primary" checked={isActive({ timerData }, 'active')}
								inputProps={{ 'aria-labelledby': "active", }}
								onClick={(event) => handleCheckbox( event )} /> } />
				</Grid >
				<Grid item xs={12}>
					<TextField name="title" variant="outlined" label="Titel" sx={{ width: '100%', maxWidth: '400px'}} value={timerData.title} onChange={(e) => setTimerData({ ...timerData, title: e.target.value })}/>
				</Grid>	
				<Grid item xs={12}>
					<TextField name="creator" variant="outlined" label="Ersteller" sx={{width: '100%', maxWidth: '400px'}} value={timerData.creator} onChange={(e) => setTimerData({ ...timerData, creator: e.target.value })}/>
				</Grid>	
				<Grid item xs={12}>
					<TextField id="periodStart" label="Zeitraum Anfang" type="datetime-local" sx={{ width: '100%', maxWidth: '400px'}} InputLabelProps={{ shrink: true, }}
						value={formatDateForDatefield(timerData.periodStart)}
						onChange={(e) => setTimerData({ ...timerData, periodStart: e.target.value })}/>
				</Grid>	
				<Grid item xs={12}>
					<TextField id="periodEnd" label="Zeitraum Ende"  type="datetime-local" sx={{ width: '100%', maxWidth: '400px' }} InputLabelProps={{ shrink: true, }}
						value={formatDateForDatefield(timerData.periodEnd)}
						onChange={(e) => setTimerData({ ...timerData, periodEnd: e.target.value })}/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel label="bei Neustart ausführen" labelPlacement="end" 
						control={<Checkbox key="fireOnRestart" name="fireOnRestart" color="primary"
									checked={isActive({timerData }, "fireOnRestart")} inputProps={{'aria-labelledby': "active",}}
									onClick={(event) => handleCheckbox( event )} />} />
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel label="bei Neustart berechnen" labelPlacement="end" 
						control={<Checkbox key="forceRecalcOnRestart" name="forceRecalcOnRestart" color="primary"
									checked={isActive({timerData }, "forceRecalcOnRestart")} inputProps={{'aria-labelledby': "active",}}
									onClick={(event) => handleCheckbox( event )} />} />
				</Grid>
				
			</Grid>
		
	);
}


export default EventTimerGeneralForm;

