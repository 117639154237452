//Posts
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const LIKE = 'LIKE';

//Elements
export const ELEMENT_FETCH = 'ELEMENT_FETCH';
export const ELEMENT_UPDATE = 'ELEMENT_UPDATE';

export const BREADCRUMB_FETCH = 'BREADCRUMB_FETCH';
export const MENU_FETCH = 'MENU_FETCH';

//TimerEvents
export const TIMER_CREATE = 'TIMER_CREATE';
export const TIMER_UPDATE = 'TIMER_UPDATE';
export const TIMER_DELETE = 'TIMER_DELETE';
export const TIMER_FETCH_ALL = 'TIMER_FETCH_ALL';
export const TIMER_ACTION_SELECTION = 'TA_SELECTION';
export const TIMER_ACTION_PUSHOVER = 'TA_PUSHOVER';
export const TIMER_ACTION_SCENE_ITEMS = 'TA_SCENES';
export const TIMER_ACTION_WORKFLOW = 'TA_WORKFLOW';

//Heating
export const HEATING_FETCH_ALL = 'HEATING_FETCH_ALL';
export const HEATING_UPDATE = 'HEATING_UPDATE';

//Alarm
export const ALARM_STATUS = 'ALARM_STATUS';
export const ALARM_FETCH_ALL = 'ALARM_FETCH_ALL';
export const ALARM_UPDATE = 'ALARM_UPDATE';

//Alerts
export const ALERT_CREATE = 'ALERT_CREATE';
export const ALERT_UPDATE = 'ALERT_UPDATE';
export const ALERT_DELETE = 'ALERT_DELETE';
export const ALERT_FETCH_ALL = 'ALERT_FETCH_ALL';
export const ALERT_CREATE_UPDATE = 'ALERT_CREATE_UPDATE';

//Auth
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const USERCONFIG_FETCH = 'USERCONFIG_FETCH';
export const USERCONFIG_UPDATE = 'USERCONFIG_UPDATE';
export const USERCONFIG_CREATE = 'USERCONFIG_CREATE';


