import { AUTH, USERCONFIG_FETCH, USERCONFIG_UPDATE, USERCONFIG_CREATE } from '../constants/actionTypes';
import * as api from '../api/index.js';
import * as CONST from '../constants/general.js';

import getBrowserFingerprint from 'get-browser-fingerprint';
import { store, socket } from '../index';


export const signin = (formData, navigate, snack) => async (dispatch) => {
	try {
		console.log("action - signin");
		//console.log("action - signin - form data: " + JSON.stringify(formData));
		const { data } = await api.signIn(formData);

		dispatch({ type: AUTH, data });
		navigate('/auth');

		setTimeout(() => {
			navigate('/');
			dispatch( getUserConfig() );
		}, 1000 )
	} catch ( error ) {
		if( error.response.request.status === 401 ||
		error.response.request.status === 404 ) {
			console.log("Error in type 401");
			console.log(error);
			snack( error.response.data.message, "warning" );
		} else {
			console.log("Other Error");
			console.log(error);
			snack( error.message, "warning" );
		}
	}
};

export const annonymousLogin = () => {
	console.log ("annonymousLogin");

	var myfingerprint = getBrowserFingerprint();
	myfingerprint = myfingerprint + Math.floor(Math.random() * 99999);
	console.log(myfingerprint);

	store.dispatch(annonymous( { fingerprint: myfingerprint }, null ));
}

const annonymous = ( fingerprint, navigate ) => async (dispatch) => {
	try {
		console.log("action - annonymous");
		const { data } = await api.annonymous(fingerprint);

		dispatch({ type: AUTH, data });

		if ( navigate !== null ) {
			navigate('/');
		}
	} catch (error) {
		console.log(error);
	}
};

export const signup = (formData, navigate) => async (dispatch) => {
	try {
		console.log("action - signup");
		//console.log("action - signup - form data: " + JSON.stringify(formData));
		const { data } = await api.signUp(formData);

		await dispatch({ type: AUTH, data });

		navigate('/');
	} catch (error) {
		console.log(error);
	}
};

export const getUserConfig = () => async (dispatch) => {
	if( localStorage.getItem('profile') ) {
		let profile = JSON.parse(localStorage.getItem('profile')).result;
		console.log( "getUserConfig: " + JSON.stringify( profile, null, 2 ));
		let myuser = profile._id;

		try {
			console.log("getUserConfig: " + myuser );
			const { data } = await api.getUserConfig( myuser );
			console.log("getUserConfig data: " + JSON.stringify( data ));
			dispatch({ type: USERCONFIG_FETCH, payload: data });
			socket.emit( CONST.SOCKET_USERID_SOCKETID, myuser );

		} catch (error) {
			console.log(error);
		}
	} else {
		console.log( "getUserConfig - no profile in local storage" );
	}
};

export const updateUserConfig = ( configName, value ) => async (dispatch) => {
	console.log( "updateUserConfig" );
	if( localStorage.getItem('profile') ) {
		let profile = JSON.parse(localStorage.getItem('profile')).result;
		console.log( "getUserConfig: " + JSON.stringify( profile, null, 2 ));
		let userid = profile._id;
		let mycfg = { "configName": configName, "value": value };

		try {
			const { status, data } = await api.updateUserConfig( userid, mycfg );

			if( status !== 200 ) {
				console.log( "Data: " + JSON.stringify(data, null, 2 ));
			} else {
				dispatch ({ type: USERCONFIG_UPDATE, payload: data }); 
			}
		} catch (error) {
			console.log(error);
		}
	} else {
		console.log( "getUserConfig - no profile in local storage" );
	}
} 

export const updateUserConfigInStore = ( userConfig ) => async (dispatch) => {
	try {
		dispatch ({ type: USERCONFIG_UPDATE, payload: userConfig });
	} catch (error) {
		console.log(error);
	}
} 

export const createUserConfigInStore = ( userConfig ) => async (dispatch) => {
	try {
		dispatch ({ type: USERCONFIG_CREATE, payload: userConfig });
	} catch (error) {
		console.log(error);
	}
} 

export const getUserConfigValue = ( userConfigs, configName, defVal, numeric ) => {
	let myret = defVal;

	for( let i = 0; i < userConfigs.length; i++ ) {
		if( userConfigs[i].configName === configName ){
			if( numeric === true && isNaN( userConfigs[i].value ) === true ){
				console.log( "Config value is not numeric: " + configName + " value: " +userConfigs[i].value );
			} else {
				myret = userConfigs[i].value;
			}
		}
	}
	return myret;
}