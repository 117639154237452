import { ALARM_STATUS } from '../constants/actionTypes';

const alarm = function ( alarm = {}, action ) {
	switch ( action.type ) {
		case ALARM_STATUS:
			return action.payload;
		default:
			return alarm;
	}
};

export default alarm;