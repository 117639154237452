import { TIMER_ACTION_PUSHOVER } from '../constants/actionTypes';

const timerPushoverCfg = function ( timerPushoverCfg = [], action ) {
	switch ( action.type ) {
		case TIMER_ACTION_PUSHOVER:
			return action.payload;
		default:
			return timerPushoverCfg;
	}
};

export default timerPushoverCfg;
