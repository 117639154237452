import axios from 'axios';

//const API = axios.create({ baseURL: 'http://192.168.0.22:5000' });
//const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_PATH });

const API = axios.create({ baseURL: process.env.REACT_APP_API });

API.interceptors.request.use(( req ) => {
	if(localStorage.getItem('profile')) {
		req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
	}

	return req;
});

export const fetchPosts = () => API.get('/posts');
export const createPost = ( newPost ) => API.post( '/posts', newPost );
export const updatePost = ( id, updatedPost ) => API.patch( `/posts/${id}`, updatedPost );
export const deletePost = ( id ) => API.delete( `/posts/${id}` );
export const likePost = ( id ) => API.patch( `/posts/${id}/likePost` );

export const fetchMenu = () => API.get(`/knx/menu`);
export const fetchElementsForPage = ( page ) => API.get(`/knx/${page}`);
export const fetchBreadcrumbsForPage = ( page ) => API.get(`/knx/breadcrumb/${page}`);
export const sendTelegram = ( telegram ) => API.post( `/knx`, telegram );
export const sendAnyAction = ( anyAction ) => API.post( `/anyAction`, anyAction );

export const fetchTimerEvents = () => API.get(`/timerEvent`);
export const createTimerEvent = ( newTimerEvent ) => API.post( `/timerEvent`, newTimerEvent );
export const updateTimerEvent = ( id, updatedTimerEvent ) => API.patch( `/timerEvent/${id}`, updatedTimerEvent );
export const deleteTimerEvent = ( id ) => API.delete( `/timerEvent/${id}` );
export const fetchTimerActionSelection = () => API.get(`/timerEvent/actionSelection`);
export const fetchTimerPushoverCfg = () => API.get(`/timerEvent/actionPushoverCfg`);
export const fetchTimerActionSceneItems = () => API.get(`/timerEvent/actionSceneItems`);
export const fetchTimerActionWorkflowNames = () => API.get(`/timerEvent/actionWorkflowNames`);

export const fetchHeatings = () => API.get(`/heatings`);
export const updateHeating = ( id, updatedHeating ) => API.patch( `/heatings/${id}`, updatedHeating );
export const applyHeatingToBeStd = () => API.get(`/heatings/applyHeatingToBeStd`);
export const applyHeatingToBeAway = () => API.get(`/heatings/applyHeatingToBeAway`);

export const signIn = ( formData ) => API.post( `/users/signin`, formData );
export const signUp = ( formData ) => API.post( `/users/signup`, formData );
export const annonymous = ( fingerprint ) => API.post( `/users/annonymous`, fingerprint );

export const getUserConfig = ( userid ) => API.get( `/userConfig/${userid}`);
export const updateUserConfig = ( userid, config ) => API.patch( `/userConfig/${userid}`, config );

export const changePIN = ( PINData ) => API.post( `/alarm/changePIN`, PINData );
export const changePINUser = ( PINData ) => API.post( `/alarm/changePINUser`, PINData );

export const activateAlarmPIN = ( PINData ) => API.post( `/alarm/activatePIN`, PINData );
export const activateAlarmUser = () => API.get( `/alarm/activateUser` );
export const deactivateAlarmPIN = ( PINData ) => API.post( `/alarm/deactivatePIN`, PINData );
export const deactivateAlarmUser = () => API.get( `/alarm/deactivateUser` );
export const cancelAlarmPIN = ( PINData ) => API.post( `/alarm/cancelPIN`, PINData );
export const cancelAlarmUser = () => API.get( `/alarm/cancelUser` );

export const fetchStatusAlarm = () => API.get( `/alarm/status` );
export const fetchAlarmElements = () => API.get( `/alarm/elements` );
export const updateAlarmElement = ( id, updatedAlarmElement ) => API.patch( `/alarm/element/${id}`, updatedAlarmElement );