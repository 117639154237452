import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import ActionTypesTab from './ActionTabs/actionType';
import ActionRoomsTab from './ActionTabs/actionRooms';
import ActionFinaliseTab from './ActionTabs/actionFinalise';

import AnyAction from '../../models/anyActionClient';

import * as CONST from '../../constants/general';

const steps = ['Aktion', 'Objekt', 'Konfigurieren'];


export default function EventActionSteps(  {timerData, setTimerData,
								currentAnyAction, setCurrentAnyAction, 
								selectedIndex, setSelectedIndex,
								activeStep, setActiveStep,
								writeSnack} ) {

	const [skipped, setSkipped] = React.useState(new Set());

	const [radioConf, setRadioConf] = React.useState();
	// eslint-disable-next-line							
	const [currentActionId, setCurrentActionId ] = React.useState(null);
	

	const isStepOptional = (step) => {
		return false;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const checkStepReadiness = ( curStep ) => {

		if( curStep === 0 ) {
			return true;

		} else if( curStep === 1 ) {
			if( currentAnyAction.object === CONST.LIGHT || 
				currentAnyAction.object === CONST.DIMMER || 
				currentAnyAction.object === CONST.ROLLERSHUTTER || 
				currentAnyAction.object === CONST.PLUG || 
				currentAnyAction.object === CONST.SCENE ) {
				
				if( currentAnyAction.knxid !== undefined && currentAnyAction.knxid !== "" ) {
					return true;
				} else {
					writeSnack( "Noch kein Objekt ausgewählt", CONST.ALERT_WARNING );
					return false;
				}
			}
			return true;

		} else if ( curStep === 2 ) {
			if( currentAnyAction.object === CONST.DIMMER || 
				currentAnyAction.object === CONST.LIGHT ) {
				if ( currentAnyAction.subType === CONST.ACTION_DIM && 
					( currentAnyAction.value === undefined || currentAnyAction.value === "" || isNaN( currentAnyAction.value ) ) ) {
					writeSnack( "Bitte Wert zum Dimmen zwischen 0 und 100 eintragen", CONST.ALERT_WARNING );
					return false;
				}
			} else if( currentAnyAction.object === CONST.ROLLERSHUTTER ) {
				if ( currentAnyAction.subType === CONST.ACTION_DRIVE_ROLLER_TO_POS && 
					( currentAnyAction.value === undefined || currentAnyAction.value === '' || isNaN( currentAnyAction.value ) ) ) {
					writeSnack( "Bitte Zielposition zwischen 0 und 100 eintragen", CONST.ALERT_WARNING );
					return false;
				}
			}  else if( currentAnyAction.object === CONST.SCENE ) {
				if ( currentAnyAction.value === undefined || currentAnyAction.value === '' || isNaN( currentAnyAction.value ) ) {
					writeSnack( "Bitte Scene auswählen",  CONST.ALERT_WARNING );
					return false;
				}
			} else if( currentAnyAction.object === CONST.BIND_PUSHOVER ) {
				if ( currentAnyAction.title === undefined || currentAnyAction.title === ""  ) {
					writeSnack( "Bitte Nachrichtentitel angeben",  CONST.ALERT_WARNING );
					return false;
				}
				if ( currentAnyAction.message === undefined || currentAnyAction.message === ""  ) {
					writeSnack( "Bitte Nachricht schreiben",  CONST.ALERT_WARNING );
					return false;
				}
				if ( ( currentAnyAction.recipients === undefined || currentAnyAction.recipients.length === 0 ) &&
					( currentAnyAction.devices === undefined || currentAnyAction.devices.length === 0 )) {
					writeSnack( "Bitte Empfänger oder Geräte auswählen",  CONST.ALERT_WARNING );
					return false;
				}
			} else if( currentAnyAction.object === CONST.VARIABLE ) {
				if ( currentAnyAction.variable === undefined || currentAnyAction.variable === "" ||
					 currentAnyAction.value === undefined ) {
					writeSnack( "Bitte Variablennamen und Wert angeben.",  CONST.ALERT_WARNING );
					return false;
				}
			} else if( currentAnyAction.object === CONST.WORKFLOW ) {
				if ( currentAnyAction.title === undefined || currentAnyAction.title === "" ) {
					writeSnack( "Bitte Workflownamen auswählen.",  CONST.ALERT_WARNING );
					return false;
				}
			} else if( currentAnyAction.object === CONST.ALERTAPP ) {
				if ( currentAnyAction.message === undefined || currentAnyAction.message === "" ) {
					writeSnack( "Bitte Nachricht eingeben.",  CONST.ALERT_WARNING );
					return false;
				}
			}
			return true;
		} else {
			console.error( "Unknown step: " + curStep );
			return false;
		}
	}

	const handleNext = () => {
		console.log( "handleNext active step: " + activeStep );
		//console.log( "handleNext currentAnyAction: " + currentAnyAction.object );

		if( checkStepReadiness( activeStep ) === false ) {
			return;
		}

		if( activeStep === 0 && 
			( currentAnyAction.object === CONST.BIND_PUSHOVER ||
				currentAnyAction.object === CONST.VARIABLE ||
				currentAnyAction.object === CONST.WORKFLOW || 
				currentAnyAction.object === CONST.ALERTAPP )) {
			
			setActiveStep((prevActiveStep) => prevActiveStep + 2);
			return;
		}

		let newSkipped = skipped;

		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		if( activeStep === 2 && 
			( currentAnyAction.object === CONST.BIND_PUSHOVER ||
				currentAnyAction.object === CONST.VARIABLE ||
				currentAnyAction.object === CONST.WORKFLOW || 
				currentAnyAction.object === CONST.ALERTAPP )) {
			
			setActiveStep((prevActiveStep) => prevActiveStep - 2);
			return;
		}

		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			// You probably want to guard against something like this,
			// it should never occur unless someone's actively trying to break something.
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleReset = () => {
		setSelectedIndex( undefined );
		setActiveStep(0);
	};

	const handleRemove = () => {
		//console.log( "handle Remove 1");

		let myactions = timerData.action.filter(( myAA ) => myAA.knxid !== selectedIndex );
		//console.log( "handle Remove 2 actions: " + JSON.stringify(myactions));

		setTimerData({  ...timerData, action: myactions });
		setCurrentAnyAction ( new AnyAction() );
		writeSnack( "Aktion wurde entfernt", CONST.ALERT_SUCCESS );
		setSelectedIndex( undefined );
		setActiveStep(0);
	};


	const handleReplace = () => {
		if( checkStepReadiness( activeStep ) === false ) {
			return;
		}

		let myactions = timerData.action.map((myAA) => myAA.knxid === selectedIndex ? currentAnyAction : myAA );
		setTimerData({  ...timerData, action: myactions });
		setCurrentAnyAction ( new AnyAction() );
		writeSnack( "Aktion wurde überschrieben", CONST.ALERT_SUCCESS );
		setSelectedIndex( undefined );
		setActiveStep(0);
	};


	const handleSave = () => {
		if( checkStepReadiness( activeStep ) === false ) {
			return;
		}

		if ( !currentActionId ) {

			let myactions = timerData.action;
			myactions.push( currentAnyAction );
			setTimerData({  ...timerData, action: myactions });

			setCurrentAnyAction ( new AnyAction() );
		}
		writeSnack( "Aktion wurde hinzugefügt", CONST.ALERT_SUCCESS );
		setActiveStep(0);
	};

	return (
		<Box minHeight={415} sx={{ width: '100%' }}>
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};
					if (isStepOptional(index)) {
						labelProps.optional = (
						<Typography variant="caption">Optional</Typography>
						);
					}
					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>

			{activeStep === steps.length ? (
				<React.Fragment>
					<Typography sx={{ mt: 2, mb: 1 }}>
						Alle Schritte erledigt - Aktion wurde hinzugefügt!
					</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Box sx={{ flex: '1 1 auto' }} />
						<Button onClick={handleReset}>Reset</Button>
					</Box>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Box >
						{ 
							activeStep === 0 && (
								<ActionTypesTab  
									currentAnyAction={currentAnyAction} setCurrentAnyAction={setCurrentAnyAction} 
									radioConf={radioConf} setRadioConf={setRadioConf} />
						)}
						{
							activeStep === 1 && (
								<ActionRoomsTab  
									currentAnyAction={currentAnyAction} setCurrentAnyAction={setCurrentAnyAction} />
						)}
						{
							activeStep === 2 && (
								<ActionFinaliseTab 
									currentAnyAction={currentAnyAction} setCurrentAnyAction={setCurrentAnyAction} 
									radioConf={radioConf} setRadioConf={setRadioConf} />
						)}

					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Button
							color="inherit"
							disabled={activeStep === 0}
							onClick={handleBack}
							sx={{ mr: 1 }}
							>
							Zurück
						</Button>
						<Box sx={{ flex: '1 1 auto' }} />
							{ isStepOptional(activeStep) && (
								<Button color="inherit" onClick={ handleSkip } sx={{ mr: 1 }}>
									Überspringen
								</Button>
							)}

							{ activeStep !== ( steps.length - 1 ) && (
								<Button color="inherit" onClick={ handleNext } sx={{ mr: 1 }}>
									Weiter
								</Button>
							)}

							{ activeStep === ( steps.length - 1 ) && (
								<Button color="inherit" onClick={ handleReset } sx={{ mr: 1 }}>
									Leeren
								</Button>
							)}
							{ activeStep === ( steps.length - 1 ) && selectedIndex !== undefined && selectedIndex !== null && (
								<Button color="inherit" onClick={ handleRemove } sx={{ mr: 1 }}>
									Entfernen
								</Button>
							)}
							{ activeStep === ( steps.length - 1 ) && selectedIndex !== undefined && selectedIndex !== null && (
								<Button color="inherit" onClick={ handleReplace }>
									Überschreiben
								</Button>
							)}
							{ activeStep === ( steps.length - 1 ) && !selectedIndex && (
								<Button color="inherit" onClick={ handleSave }>
									Hinzufügen
								</Button>
							)}

					</Box>
				</React.Fragment>
			)}
		</Box>
	);
}
