import React from "react";
import { useSelector } from 'react-redux';
import { Box, Typography, Switch, Grid, Card, CardMedia, CardContent, CircularProgress  } from '@mui/material';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';

import useStyles from './styles';
import { switchLight } from '../../../actions/knx';

const Plug = ({ knxid, writeSnack }) => {
	//console.log("Plug: " + JSON.stringify(element));
	const classes = useStyles();

	var element = useSelector((state) => state.elements.find(( elem ) => {
		//console.log ("useSelector: " + elem.knxid );
		return elem.knxid === knxid; }));
	if ( !element ){
		return <CircularProgress />
	}

	var myImage = "images/plug_dark.png";
	if ( element.image !== undefined && element.image !== "" ) {
		myImage = element.image;
	}

	const switchLightInner = async ( e, element ) => {
		let myret = await switchLight( e, element );
		//console.log("Value fro myRet: " + myret);
		if( myret !== undefined ) {
			writeSnack( myret );
		}
	}

	var isOn;
	if( element.value === 0 ) { isOn = false; } else { isOn = true;	}
		
	return (
		<Box>
			<Card className={classes.card} sx={{ display: { xs: 'none', sm: 'block' } }}>
				<CardMedia component="img" height="60" image={ myImage } />
					<div className={classes.overlay}>
						<Typography variant="h6">{ element.label }</Typography>
					</div>
					<div className={classes.overlay3}><ElectricalServicesIcon /></div>
					<CardContent sx={{ ml: -2, mr: -2, mt: 0, mb: -2}}>
					<Box sx={{ mr:2 }}>
						<Grid container spacing={2} alignItems="center" justifyContent="center">
							<Grid item sx={{mt:-0.75}}>
								<Switch key={element.knxid} 
										className={classes.dimmerSwitch} 
										checked={isOn} 
										onChange={(e) => { switchLightInner( e, element )}} />
							</Grid>
						</Grid>
					</Box>
				</CardContent >
			</Card > 

			<Card className={classes.card} sx={{ display: { xs: 'block', sm: 'none' }}}>
				<Grid container>
					<Grid item xs={5}>
						<CardMedia component="img" height="60" image={ myImage } />
							<div className={classes.overlay}>
								<Typography variant="h6">{ element.label }</Typography>
							</div>
							<div className={classes.overlay4}><ElectricalServicesIcon /></div>
					</Grid>
					<Grid item xs={7}>
						<CardContent sx={{ ml: -2, mr: -2, mt: 0, mb: -2}}>
							<Box sx={{ mr:2 }}>
								<Grid container spacing={2} alignItems="center" justifyContent="center">
									<Grid item sx={{mt:-0.75}}>
										<Switch key={element.knxid} 
												className={classes.dimmerSwitch} 
												checked={isOn} 
												onChange={(e) => { switchLightInner( e, element )}} />
									</Grid>
								</Grid>
							</Box>
						</CardContent >
					</Grid>
				</Grid>
			</Card > 
		</Box>
	);
}

export default Plug;

