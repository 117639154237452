import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate  } from 'react-router-dom';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { signin, signup } from '../../actions/auth';
import { AUTH } from '../../constants/actionTypes';
import useStyles from './styles';
import Input from './Input';

const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };

const SignUp = ({ writeSnack }) => {

	const [formData, setFormData] = useState(initialState);
	const [isSignup, setIsSignup] = useState(false);
	
	const dispatch = useDispatch();
	const navigate = useNavigate ();
	
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
	const handleShowPassword = () => setShowPassword(!showPassword);

	

	/*
	React.useEffect(() => {
		/* global google *//*
		GoogleLogin.accounts.id.initialize({
			client_id: "611355122459-10c8arqu3fdkee8kn37iso5hlge3ierm.apps.googleusercontent.com",
			callback: handleCallbackResponse
		})

		googleFailure.accounts.id.renderButton(
			document.getElementById("googleSignInDiv"),
			{ theme: "outline", size: "large" }
		);

	}, []);
	*/

	const switchMode = () => {
		setFormData(initialState);
		setIsSignup((prevIsSignup) => !prevIsSignup);
		setShowPassword(false);
	};

	
	const handleSubmit = (e) => {
		e.preventDefault();
		if (isSignup) {
			console.log("Form - signup");
			dispatch( signup( formData, navigate ));
		} else {
			console.log("Form - signin");
			dispatch( signin( formData, navigate, writeSnack ));
		}
	};
	

	const googleSuccess = async (res) => {
		console.log(res);
		const result = res.profileObj;
		const token = res.tokenId;

		try {
			dispatch({ type: AUTH, data: { result, token } });
			navigate('/');
		} catch (error) {
			console.log(error);
		}
	};
	
		const googleFailure = (error) => {
			console.log(error);
			console.log("Google Sign In was unsuccessful. Try again later");
			//alert('Google Sign In was unsuccessful. Try again later');
		}

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	}

	return (
		<Container component="main" maxWidth="xs">
			<Paper className={classes.paper} elevation={3}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">{ isSignup ? 'Registrieren' : 'Anmelden' }</Typography>
				<form className={classes.form} onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					{ isSignup && (
					<>
					<Input name="firstName" label="Vorname" handleChange={handleChange} autoFocus half />
					<Input name="lastName" label="Nachname" handleChange={handleChange} half />
					</>
					)}
					<Input name="email" label="Email Adresse" handleChange={handleChange} type="email" />
					<Input name="password" label="Passwort" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
					{ isSignup && <Input name="confirmPassword" label="Passwort wiederholen" handleChange={handleChange} type="password" /> }
					<Grid item xs={12}>
						<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
							{ isSignup ? 'Registrieren' : 'Anmelden' }
						</Button>
					</Grid>
					<Grid item xs={12}>
						<div id="googleSignInDiv"></div>
					</Grid>	
				</Grid>
				<Grid container justify="flex-end">
					<Grid item>
					<Button onClick={switchMode}>
						{ isSignup ? 'Schon registiert? Anmelden' : 'Ich habe keinen Zugang? Registrieren' }
					</Button>
					</Grid>
				</Grid>
				</form>
				
			</Paper>

		</Container>
	);
};

export default SignUp;
