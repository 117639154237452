import * as React from 'react';
import { ToggleButton, ToggleButtonGroup, Grid, TextField, FormControlLabel, Checkbox } from '@mui/material';


export default function EventWeekdays({ timerData, setTimerData }) {
  //const [weekdays, setWeekdays] = React.useState(() => []);

	const handleWeekdays = (event, newWeekdays) => {
		setTimerData({ ...timerData, frequency: { 
		...timerData.frequency, weekdays: newWeekdays }});

		//console.error ("handleWeekdays: " + JSON.stringify( timerData, null, 2 ) );
	};

	const handleCheckbox = ( event ) => {
		let myval = 0;
		if( event.target.checked ) { myval = 1; } 

		switch( event.target.name ) {
			case "repeats":
			setTimerData({ ...timerData, repeats: myval })
			break;

			default:
			break;
		}
	}

	const isActive = ({ timer }, key ) => {
		switch (key) {
			case "repeats":
				if( timerData.repeats === 1 )  { return true; } 
				else { return false; }

			default:
				break;
		}
		return false;
	}

	const handleFields = ( event ) => {
		switch (event.target.name) {
			case "Time":
				document.getElementById('Sunrise').value = '';
				document.getElementById('Sunset').value = '';
				timerData.frequency.sunrise = '';
				timerData.frequency.sunset = '';

				setTimerData({ ...timerData, frequency: { 
					...timerData.frequency, time: event.target.value }});

				break;
			case "Sunrise":
				document.getElementById('Time').value = '';
				document.getElementById('Sunset').value = '';
				timerData.frequency.time = '';
				timerData.frequency.sunset = '';

				setTimerData({ ...timerData, frequency: { 
					...timerData.frequency, sunrise: event.target.value }});
				break;
			case "Sunset":
				document.getElementById('Time').value = '';
				document.getElementById('Sunrise').value = '';
				timerData.frequency.time = '';
				timerData.frequency.sunrise = '';

				setTimerData({ ...timerData, frequency: { 
					...timerData.frequency, sunset: event.target.value }});
				break;
			default:
				break;
		}
	}

  	return (
		<Grid container rowSpacing={1} >
			<Grid item xs={12}>
				<ToggleButtonGroup
					color="primary"
					value={timerData.frequency.weekdays}
					onChange={handleWeekdays}
					aria-label="selecting weekdays"
					>
					<ToggleButton value="mon" aria-label="Mo" >Mo</ToggleButton>
					<ToggleButton value="tue" aria-label="Di" >Di</ToggleButton>
					<ToggleButton value="wed" aria-label="Mi" >Mi</ToggleButton>
					<ToggleButton value="thu" aria-label="Do" >Do</ToggleButton>
					<ToggleButton value="fri" aria-label="Fr" >Fr</ToggleButton>
					<ToggleButton value="sat" aria-label="Sa" >Sa</ToggleButton>
					<ToggleButton value="sun" aria-label="So" >So</ToggleButton>
				</ToggleButtonGroup>
			</Grid>
			<Grid item xs={12}>
				<TextField
					id="Time" name="Time" variant="outlined" label="Uhrzeit" type="time"
					value={timerData.frequency.time}
					sx={{width: '50%'}}
					onChange={(event) => handleFields( event )} 
					InputLabelProps={{ shrink: true, }}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					id="Sunrise" name="Sunrise" variant="outlined" label="Sonnenaufgang (+-m)" type="number"
					value={timerData.frequency.sunrise }
					sx={{width: '75%'}}
					onChange={(event) => handleFields( event )} 
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					id="Sunset" name="Sunset" variant="outlined" label="Sonnenuntergang (+-m)" type="number"
					value={timerData.frequency.sunset }
					sx={{width: '75%'}}
					onChange={(event) => handleFields( event )} 
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel label="Wiederholen" labelPlacement="end" 
					control={<Checkbox key="repeats" name="repeats" color="primary"
								checked={isActive({timerData }, "repeats")} inputProps={{'aria-labelledby': "repeats",}}
								onClick={(event) => handleCheckbox( event )} />} />
			</Grid>
		</Grid>

	
  );
}


