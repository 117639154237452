import { TIMER_ACTION_WORKFLOW } from '../constants/actionTypes';

const timerWorkflowNames = function ( timerWorkflowNames = [], action ) {
	switch ( action.type ) {
		case TIMER_ACTION_WORKFLOW:
			return action.payload;
		default:
			return timerWorkflowNames;
	}
};

export default timerWorkflowNames;
