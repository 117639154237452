import React from 'react';

//import ReactDOM from 'react-dom';

//import { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { io } from 'socket.io-client';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { logger } from "redux-logger";
import { reducers } from './reducers';

import App from './App';
import './style.css';


//export const store = createStore( reducers, compose( applyMiddleware( thunk, logger )));

const configureStore = () => {
    let store = null;
    if( process.env.REACT_APP_DEPLOY_ENV === 'LIVE' ) {
        // no redux logger in live
        store = createStore(
			reducers,
			compose( 
				applyMiddleware( thunk ))
		);
    } else {
        // add redux logger
        store = createStore(
			reducers,
			compose( 
				applyMiddleware( thunk, logger 	))
		);
    }

    return store;
}



export const store = configureStore();
export const socket = io( process.env.REACT_APP_IO );

socket.on("connect", () => {
	console.log(`Connected to server with socket id: ${socket.id}`);
})

const root = createRoot(document.getElementById('root'));

if( process.env.REACT_APP_DEPLOY_ENV === 'LIVE' ) {
	root.render(
		<div className="backLive fullscreen" >
			<Provider store={store}>
				<App />
			</Provider>
		</div> ); 
} else {
	root.render(
		<div className="backTest fullscreen" >
			<Provider store={store}>
				<App />
			</Provider>
		</div> ); 
}


