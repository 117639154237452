import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { CircularProgress, Grid } from '@mui/material';
import { getTimerEvents, getTimerActionSceneItems, getTimerActionSelection, 
	getTimerPushoverCfg, getTimerActionWorkflowNames } from '../../actions/timerEvents';


import TimerTable from './EventTimerTable';
import EventTimerForm from './EventTimerForm';


const EventTimerPage =  ({ writeSnack, setSnackOpen }) => {
	const dispatch = useDispatch();

	const [currentTimerId, setCurrentTimerId] = useState(null);

	useEffect(() => {
		dispatch( getTimerEvents() );
		dispatch( getTimerActionSceneItems() );
		dispatch( getTimerActionSelection() );
		dispatch( getTimerActionWorkflowNames() );
		dispatch( getTimerPushoverCfg() );

	}, [dispatch]);

	
	const timerEvents = useSelector(( state ) => state.timerEvents );
	//const classes = useStyles();
	
	if ( !timerEvents  ){
		return <CircularProgress />
	}
	return (
		
		<Grid container alignItems="stretch" spacing={3}>
			<Grid item xs={12}>
				<TimerTable currentTimerId={ currentTimerId } setCurrentTimerId={ setCurrentTimerId }/>
			</Grid>
			<Grid item xs={12}>
				<EventTimerForm  currentTimerId={ currentTimerId } setCurrentTimerId={ setCurrentTimerId } 
									writeSnack={ writeSnack } setSnackOpen={ setSnackOpen } />
			</Grid>
		</Grid>
	);
}

export default EventTimerPage;

