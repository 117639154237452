import React from 'react';
import { Container, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import MyEIBAlert from './MyEIBAlert';

//import useStyles from './styles';

const AlertsPane = () => {
	const alerts = useSelector((state) => state.alerts );

	return (
		<Container sx={{mt:1}}>
			<Box key="AlertsPane" sx={{ width: '100%' }}>
				{ alerts.map((myalert) => (
					<MyEIBAlert key={myalert.title} myalert={ myalert } />
				))}
			</Box>
		</Container>
	)
	
}

export default AlertsPane;
