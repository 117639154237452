import React, { useState, useEffect } from "react";
import { Box, Grid, Fab, Card, CardMedia, Typography, CardContent } from '@mui/material';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';

import useStyles from './styles';

const Numpad = ({ title, ...props }) => {
	//console.log("Number: " + JSON.stringify(element));
	const [passcode, setPasscode] = useState("");
	const classes = useStyles();
	
	useEffect(() => {
		//console.log ("useEffect - Numpad");
		if( passcode.length === 6 ) {
			props.handleEntry( passcode );
			setPasscode('');
		}
	}, [passcode]);
	
	var myImage = "images/alarm_dark.png";
	/*
	if ( element.image !== undefined && element.image !== "" ) {
		myImage = element.image;
	}
	*/
	var myKeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "<", "0", "C"];
	var myDigits = [0, 1, 2, 3, 4, 5];

	const handleClick = ( event ) => {
		//console.log("handle click: " + event.target.value );
		switch (event.target.value) {
			case "C":
				setPasscode( '' );
				break;
			case "<":
				if( passcode.length > 0 ) {
					setPasscode( passcode.substring(0, passcode.length - 1))
				}
				break;
			default:
				setPasscode( passcode + event.target.value );
				break;
		}
	}

	const createButton = ( val ) => {
		//console.log( `Create Button: val: ${val} `);
		let mycolor;
		if( val === "<" || val === "C" ) {
			mycolor = "secondary";
		} else {
			mycolor = "primary";
		}

		return (
		<Grid key={ "key_" + val } item xs={4} sx={{mt: -1}} alignItems="center" justifyContent="center">
			<Fab onClick={handleClick} variant="outlined" color={mycolor} value={val} >
				{val}
			</Fab>
		</Grid>
		)
	}

	const createDigit = ( val ) => {
		//console.log( `Create createDigit: val: ${val} `);
		if ( passcode.length > val ) {
			return ( 
				<Grid key={ "digit_" + val } item xs={1} sx={{mt:0}}>
					<RadioButtonCheckedOutlinedIcon /> 
				</Grid>
				);
		} else {
			return ( 
				<Grid key={ "digit_" + val } item xs={1} sx={{mt:0}}>
					<Brightness1OutlinedIcon /> 
				</Grid>
				);
		}
	}

	return (
		<Box>
			<Card className={classes.card} sx={{ maxWidth: 400 }}>
				<CardMedia component="img" height="60" image={ myImage } />
				<div className={classes.overlay}>
					<Typography variant="h4">{title}</Typography>
				</div>
				<CardContent sx={{ ml: 0, mr: 0, mt: 0, mb: 0 }}>
					<Grid container >
						<Grid item xs={2} />
						<Grid item xs={8}>
							<Grid container padding={1} spacing={4} sx={{ ml: -1.3, mr: 0, mt: -2, mb: 3 }}>
								{
									myDigits.map(( val ) => (
										createDigit( val )
									))}
							</Grid>
							<Grid container padding={1} spacing={4} >
								{ 
									myKeys.map(( val ) => (
										createButton( val  ) 
									))}
							</Grid>
						</Grid>
						<Grid item xs={2} />
					</Grid>
				</CardContent >
			</Card > 
		</Box>
	);
}


export default Numpad;